.text-shadow {
  text-shadow: 3px 3px 2px rgba(0, 0, 0, 1);
}

//FAQ layout

.title--underline {
  border-bottom: 1px solid #e9e9e9;
}
.title--center {
  margin-bottom: 30px;
  text-align: left;
}
.title--decoration-bottom-center {
  padding-bottom: 20px;
}
.title--decoration-bottom-center:after {
  content: ' ';
  display: block;
  background: $primary-color;
  position: absolute;
  height: 5px;
  width: 30px;
  bottom: -1px;
  left: 50%;
  -moz-transform: translate(-50%,0);
  -ms-transform: translate(-50%,0);
  -webkit-transform: translate(-50%,0);
  -o-transform: translate(-50%,0);
  transform: translate(-50%,0);
}
.title {
  margin-bottom: 30px;
  position: relative;
}
.section-title {
  margin-bottom: 0 !important;
  padding-top: 20px !important;
}


//Invisible
#invisible {
  display: none;
}

//Date and Time

@mixin tablet{
  @media(min-width:768px){
    @content;
  }
}
@mixin desktops{
  @media(min-width:992px){
    @content;
  }
}

.dateTd{
  width: auto;
  @include desktops{
    width: 10%;
  }
  .date{
    display: none;
    @include tablet{
      display: block;
    }
    color: #fff;
    background-color: #859E8C;
    width: 55px;
    height: 58px;
    border-radius: 3px;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    margin: auto ;
    span{
      display: block;
      font-size: 14px;
    }
  }
}


// Form Changes
//.form-control {
//  background-color: #f9f9f9;
//}

//Tab Active Change
.checkinPortal ul.nav-tabs.nav-pills .nav-item .nav-link.active {
  border-color: #265a88;
  background-color: #265a88;
  color: #ffffff;
}
.checkinPortal ul.nav-tabs.nav-pills .nav-item .nav-link:hover {
  color: #265a88;
}
.checkinPortal ul.nav-tabs .nav-item .nav-link.active {
  border-top-color:  #265a88;
  border-top-width: 2px;
  background-color: #ffffff;
}
.checkinPortal ul.nav-tabs.nav-pills .nav-item .nav-link.active:hover {
  color: #ffffff;
}

//US clickable Map Maps
/* map styling */
.createaclickablemap-container {
  position: relative;
  width: 100%;
  height: 0px;
  padding-bottom: 60%;
}
.createaclickablemap {
  border: none;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
ul.map li {
  float: left;
  margin: 0 25px 0 0px;
  width: 120px;
  border: 0px solid;
  height: 45px;
  padding-left: 6px;
} ul.map {
    list-style: square url(https://createaclickablemap.com/li-arrow.png) !important;
  }
ul.map li a{
  margin: 0;
  padding: 0;
  text-decoration:underline;
  font-family:Arial, Helvetica,
  sans-serif; font-size:12px;
  font-weight:normal;
  color:#4591b1;
}
ul.map li a:hover{
  text-decoration:none;
  color:#214b6e;
} .linkBack{
    display:block;
    width:130px;
    position: relative;
    bottom: 0px;
    left: 660px;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 10px;
    font-style:italic;
    color:#666666;
  }
.linkBack a {
  color:#666666;
}
.linkBack a:hover{
  color:#666666;
  text-decoration: none;
}
/*================================================
--> Element Heading
==================================================*/
[class*="heading-"] {
  position: relative;
}

.heading:before, .heading:after {
  content: "";
  position: absolute;
  left: auto;
  right: 0;
  width: 100%;
  height: 0;
  border-top-width: 1px;
  border-color: inherit;
}

.heading.heading-v1:before {
  top: 48%;
  border-top-style: solid;
}

.heading.heading-v1:after {
  top: 58%;
  border-top-style: solid;
}

.heading-v1-title {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  padding-right: 18px;
  background-color: inherit;
  z-index: 2;
}

.text-center .heading-v1-title {
  padding-right: 18px;
  padding-left: 18px;
}

.text-right .heading-v1-title {
  padding-right: 0;
  padding-left: 18px;
}

/*================================================
--> Clickable Map
==================================================*/

.createaclickablemap-container {
  position: relative;
  width: 100%;
  height: 0px;
  padding-bottom: 60%;
}
.createaclickablemap {
  border: none;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
/*================================================
--> Element Divider
==================================================*/
.divider-dotted {
  border-top-style: dotted;
}

.divider-dashed {
  border-top-style: dashed;
}

.divider-solid-dbl {
  height: 4px;
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.divider-dotted-dbl {
  height: 4px;
  border-top: 1px dotted;
  border-bottom: 1px dotted;
}

.divider-dashed-dbl {
  height: 4px;
  border-top: 1px dashed;
  border-bottom: 1px dashed;
}

.divider-gradient-gray {
  background-image: linear-gradient(to right, transparent, #ccc, transparent);
  height: 1px;
  border: none;
}

.w25 {
  width: 25% !important;
}

.w50 {
  width: 50% !important;
}

.w75 {
  width: 75% !important;
}

.divider {
  position: relative;
  border-top-width: 1px;
}

.border-solid {
  border-top-style: solid;
}

.divider-center {
  text-align: center;
}

.divider-center .divider-icon {
  left: auto;
  right: auto;
  margin-left: -20px;
}

.divider-right {
  text-align: right;
}

.divider-right .divider-icon {
  left: auto;
  margin-left: -40px;
}

.divider-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  top: -20px;
  display: inline-block;
  line-height: 40px;
  font-size: 20px;
  color: #f5f5f5;
  text-align: center;
}

.divider-gradient {
  height: 1px;
  border: none;
}