/* MODAL CUSTOMS STYLES */
.modal {
	.modal-lg {
		@include desktops {
		    max-width: 870px!important;
		}
	}
}
.modal.show {
	.modal-dialog {
	    @include transform(translate(0,15%));
		@include desktops {
	    	@include transform(translate(0,25%));
		}
	}
}
.modal-content {
	@include box-shadow(0px, 3px, 12px, rgba(0, 0, 0, 0.08));
	border: none;
	.modal-item {
		padding: 15px;
		@include desktops {
			padding: 30px;
		}
	}
}
button.close {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    font-size: 16px;
    -webkit-appearance: none;
    position: absolute;
    right: 15px;
    z-index: 55;
    &:hover, &.active, &:focus {
    	outline: none;
    	box-shadow: none;

    }
}
.card_img {
	position: relative;
	overflow: hidden;
}

// Lightbox custom style ==============================
.quick_view {
	color: $white-color!important;
	&:visited, &:hover {
		color: $white-color;
	}
}
.quick-view-container {
	background: rgba(10,10,10,0.85);
}

.quick-view-content {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	width: calc(100% - 40px);
	height: calc(100% - 40px);
	max-width: 980px;
	max-height: 400px;
}

.quick-view-carousel {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	background: #fff;
	@include tablet {
		top: 0;
		width: 57%;
	}
}
.fancybox-slide .fancybox-image-wrap {
	top: 10px;
	@include tablet {
		top: 0;
	}
}
.quick-view-carousel .fancybox-stage {
	bottom: 30px;
}

.quick-view-aside {
	position: absolute;
	top: 0px;
	right: 0;
	bottom: 50%;
	left: auto;
	width: 100%;
	padding: 0px 0 0px 0;
	background: #fff;
	color: #777;
	@include tablet {
		width: 43%;
		bottom: 0;
	}
	.project_specification {
		padding: 10px 30px;
		color: $white-color;
		position: relative;
		@include tablet {
			padding: 40px 30px;
		}
		h2 {
			margin-bottom: 10px;
			margin-top: 0px;
			font-size: 22px;
			@include tablet {
				margin-bottom: 20px;
			}
			@include desktops {
				margin-bottom: 30px;
				font-size: 30px;
			}
		}
		.row {
			margin-top: 10px;
			@include tablet {
				margin-top: 15px;
			}
		}
	}
}

.quick-view-aside::before, .quick-view-aside::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
}

.quick-view-aside::before {
	width: 8px;
	background: #f4f4f4;
}

.quick-view-aside::after {
	width: 0px;
	background: #e9e9e9;
	@include tablet {
		width: 1px;
	}
}

.quick-view-aside > div {
	height: 100%;
	overflow: auto;
	padding: 0 0px 0 0px;
	text-align: center;
	@include tablet {
		padding: 0 0px 0 10px;
	}
	.bg-image {
		height: 100%;
	}
}
.quick-view-close {
	position: absolute;
	top: 0px;
	right: 0;
	width: 44px;
	height: 44px;
	background: #F0F0F0;
	padding: 0;
	margin: 0;
	border: 0;
	cursor: pointer;
	font-family: Arial;
	font-size: 14px;
	color: #222;
	text-indent: -99999px;
	transition: all .2s;
}

.quick-view-close:hover {
	background: #e4e4e4;
}

.quick-view-close::before,
.quick-view-close::after {
	content: '';
	position: absolute;
	top: 12px;
	left: 22px;
	width: 1px;
	height: 18px;
	background-color: #222;
}

.quick-view-close:before {
	-webkit-transform: rotate(-45deg);
	   -moz-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		 -o-transform: rotate(-45deg);
			transform: rotate(-45deg);
}

.quick-view-close:after {
	-webkit-transform: rotate(45deg);
	   -moz-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		 -o-transform: rotate(45deg);
			transform: rotate(45deg);
}


.quick-view-bullets {
	list-style: none;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
	margin: 0;
	padding: 0;
	z-index: 99999;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

.quick-view-bullets li {
	display: inline-block;
	vertical-align: top;
}

.quick-view-bullets li a {
	display: block;
	height: 30px;
	width: 20px;
	position: relative;
}

.quick-view-bullets li a span {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	width: 10px;
	height: 10px;
	border-radius: 99px;
	text-indent: -99999px;
	overflow: hidden;
	background: #d4d2d2;

}

.quick-view-bullets li.active a span {
	background: $primary-color;
}

/*
	// End of examples
*/
