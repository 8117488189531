.element-animation{
  animation: animationFrames linear 0.8s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  -webkit-animation: animationFrames linear 0.8s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: animationFrames linear 0.8s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -o-animation: animationFrames linear 0.8s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -ms-animation: animationFrames linear 0.8s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
}

@keyframes animationFrames{
  0% {
    transform:  translate(0px,0px)  ;
  }
  15% {
    transform:  translate(0px,-25px)  ;
  }
  30% {
    transform:  translate(0px,0px)  ;
  }
  45% {
    transform:  translate(0px,-15px)  ;
  }
  60% {
    transform:  translate(0px,0px)  ;
  }
  75% {
    transform:  translate(0px,-5px)  ;
  }
  100% {
    transform:  translate(0px,0px)  ;
  }
}

@-moz-keyframes animationFrames{
  0% {
    -moz-transform:  translate(0px,0px)  ;
  }
  15% {
    -moz-transform:  translate(0px,-25px)  ;
  }
  30% {
    -moz-transform:  translate(0px,0px)  ;
  }
  45% {
    -moz-transform:  translate(0px,-15px)  ;
  }
  60% {
    -moz-transform:  translate(0px,0px)  ;
  }
  75% {
    -moz-transform:  translate(0px,-5px)  ;
  }
  100% {
    -moz-transform:  translate(0px,0px)  ;
  }
}

@-webkit-keyframes animationFrames {
  0% {
    -webkit-transform:  translate(0px,0px)  ;
  }
  15% {
    -webkit-transform:  translate(0px,-25px)  ;
  }
  30% {
    -webkit-transform:  translate(0px,0px)  ;
  }
  45% {
    -webkit-transform:  translate(0px,-15px)  ;
  }
  60% {
    -webkit-transform:  translate(0px,0px)  ;
  }
  75% {
    -webkit-transform:  translate(0px,-5px)  ;
  }
  100% {
    -webkit-transform:  translate(0px,0px)  ;
  }
}

@-o-keyframes animationFrames {
  0% {
    -o-transform:  translate(0px,0px)  ;
  }
  15% {
    -o-transform:  translate(0px,-25px)  ;
  }
  30% {
    -o-transform:  translate(0px,0px)  ;
  }
  45% {
    -o-transform:  translate(0px,-15px)  ;
  }
  60% {
    -o-transform:  translate(0px,0px)  ;
  }
  75% {
    -o-transform:  translate(0px,-5px)  ;
  }
  100% {
    -o-transform:  translate(0px,0px)  ;
  }
}

@-ms-keyframes animationFrames {
  0% {
    -ms-transform:  translate(0px,0px)  ;
  }
  15% {
    -ms-transform:  translate(0px,-25px)  ;
  }
  30% {
    -ms-transform:  translate(0px,0px)  ;
  }
  45% {
    -ms-transform:  translate(0px,-15px)  ;
  }
  60% {
    -ms-transform:  translate(0px,0px)  ;
  }
  75% {
    -ms-transform:  translate(0px,-5px)  ;
  }
  100% {
    -ms-transform:  translate(0px,0px)  ;
  }
}

//Blink
.blink_me {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% { opacity: 0; }
}