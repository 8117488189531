.map-canvas {
	height: 400px;
	width: 100%;
	#map {
		height: 100%;
		width: 100%;
	}
}
.mapArea {
    #map-canvas {
        height: 400px;
        width: 100%;
    }
}