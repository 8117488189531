/* ================================================
--> Elements Popover & Tooltip style
================================================== */
.popover {
	border-radius: 0px;
	border-color: rgb(229, 229, 229);
}
.popover.bs-tether-element-attached-left::before, 
.popover.popover-right::before, 
.popover.popover-left::before, 
.popover.popover-top::before, 
.popover.popover-bottom::before {
	border-right-color: rgb(229, 229, 229);
}
.popover-title {
	font-size: 15px;
	border-bottom: 1px solid rgb(229, 229, 229);
  padding: 5px;
}

.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff!important;
    position: relative;
    text-align: center;
    background-color: $navy!important;
    border-radius: 0px;
}
.tooltip-inner {
    &:after {
        position: absolute;
        content: '';
      top: 0;
      left: 50%;
      margin-left: -5px;
      border-bottom-color: #000000; /* black */
      border-width: 0 5px 5px;
    }
}
.display-single_element {
    button[data-toggle="tooltip"]  {
        margin-bottom: 15px;
        display: block;
        @include tablet {
            margin-bottom: 0px;
            display: inline-block;
        }
    }
}