/*====================================
ABOUT US PAGE STYLE
======================================*/
.page-about-us, .page-contactus {
	.bredcrumb {
		padding: 0px;
	}
}
.boxed-menu {
	.bredcrumb {
		h1, h2 {
			padding-top: 0px;
			@include tablet {
				padding-top: 45px;
			}
		}
	}
}
.about-details {
	padding-top: 80px;
	padding-bottom: 80px;
	@include desktops {
		padding-top: 100px;
		padding-bottom: 100px;
	}
	h1 {
		margin-bottom: 10px;
	}
	p {
		span {
			display: inline-block;
			padding-top: 30px;
			line-height: 2;
			padding-bottom: 25px;
			color: $title-color;
			font-size: 16px;
		}
	}
	.data-chart {
		margin-top: 50px;
	}
}

.contactus {
	padding-top: 80px;
	@include desktops {
		padding-top: 100px;
	}
}
.page-contactus {
	.sectionTitle {
		margin-bottom: 20px;
	}
	.contact_sidebar {
		.media-box {
			padding: 40px 30px;
			margin-bottom: 20px;
		}
		.media-icon {
			width: 30px;
			font-size: 30px;
			text-align: center;
			color: $primary-color;
		}
		.media-content {
			padding-left: 45px;
			h4 {
				margin-bottom: 10px;
			}
		}
	}
}
.contactus {
	margin-bottom: 80px;
	form {
		margin-bottom: 50px;
		input.form-control {
			@include placeholder {
				color: shade($white-color, 30%);
			}
		}
	}
}
#googleMap {
	height: 460px;
	width: 100%;
}
// ERROR PAGE =====================================
.page-error {
	.bredcrumb {
		padding: 0px;
	}
	.error {
		text-align: center;
		padding-top: 100px;
		padding-bottom: 100px;
		@include desktops {
			padding-top: 150px;
			padding-bottom: 150px;
		}
		form {
			position: relative;
			.btn-search {
				top: 9px;
				@include tablet {
					top: 16px;
				}
			}
		}
		h1 {
			color: $primary-color;
			font-size: 100px;
			font-weight: 600;
		}
		p {
			color: $title-color;
			font-size: 30px;
			padding: 50px 0;
		}
		input {
			margin-bottom: 50px;
		}
	}
}
// comingsoon PAGE =====================================
.page-comingsoon {
	padding: 100px 0;
	.form_subscribe {
		position: relative;
		i {
			color: $primary-color;
		}
		input {
			@include box-shadow(1.721px, 2.457px, 9px, rgba(44, 40, 86, 0.1));
			border: none;
			@include placeholder {
				color: $text-color;
			}
		}
	}
	.comingsoon {
		h1 {
			margin-top: 100px;
			margin-bottom: 15px;
		}
	}
	.count-down {
		padding-top: 60px;
		padding-bottom: 60px;
		.table-cell {
			position: relative;
			height: 150px;
		}
		.tab-val {
			width: 150px;
			font-size: 50px;
			height: 150px;
			line-height: 100px;
			background-color: $primary-color!important;
			border-color: $primary-color;
			color: $white-color;
		}
		.tab-metr {
			margin-top: -65px;
			font-size: 16px;
			color: $white-color!important;
		}
	}
	.form_subscribe {
		margin: 0 80px 90px;
	}
}
