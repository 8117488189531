/*====================================
PROJECTS PAGE STYLE
======================================*/
.page-projects, .page-project-details {
	.bredcrumb {
		padding: 0px;
	}
}
.project-details {
	padding-top: 80px;
	padding-bottom: 80px;
	@include desktops {
		padding-top: 100px;
		padding-bottom: 100px;
	}
	.bg-image {
		height: 100%;
		&:before {
			background-color: rgba(10,35,59,0.85);
		}
	}
	h2 {
		margin-top: 40px;
	}
	p {
		margin-top: 30px;
	}
	.col-md-4 > img {
		margin-top: 30px;
	}
}
.project_specification {
	padding: 40px 30px;
	color: $white-color;
	position: relative;
	h2 {
		margin-bottom: 30px;
		margin-top: 0px;
	}
	.row {
		margin-top: 15px;
	}
}
