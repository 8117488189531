/* ================================================
--> Elements Badge style
================================================== */
.display-single_element.element_badge {
	p {
		margin-bottom: 10px;
		font-size: 18px;
		margin-top: 0px;
	}
	 h1, h2, h3, h4, h5 {
    	margin: 0 0 15px;
	}
    h1 {
        display: none;
        @include tablet {
            display: block;
        }
    }
}
.badge {
    padding: 4px 6px;
    border-radius: 2px;
}
.badge-default {
    background-color: #323f41;
    color: $white-color!important;
}
.badge-primary {
    background-color: $primary-color;
    color: $white-color!important;
}
.badge-success {
    background-color: $success;
    color: $white-color!important;
}
.badge-info {
    background-color: $info;
    color: $white-color!important;
}
.badge-warning {
    background-color: $warning;
    color: $white-color!important;
}
.badge-danger {
    background-color: $danger;
    color: $white-color!important;
}