.team-card {	
	.card_img {
		overflow: hidden;	
		margin: 0 auto;	
		&.img-circle {
			width: 240px;
			height: 240px;
		}
	}
	a.overlay_icon {
		width: 45px;
		height: 45px;
		background-color: transparent;
		border: 2px solid $white-color;
		i.fa {
			font-size: 15px;
			line-height: 40px;
			color: $white-color;
		}
		&:hover {
			background-color: $white-color;
			border: 2px solid $white-color;
			i.fa {
				color: $primary-color;
			}
		}
	}
}
