/* ================================================
--> Elements Pricing table style
================================================== */
.circle {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-color: $primary-color;
	white-space: nowrap;
	position: absolute;
	padding: 0px;
	top: -40px;
	left: 50%;
	@include box-shadow(0px, 4px, 10px, rgba(134, 201, 212, 0.3));
	@include transform(translateX(-50%));
	color: $white-color;
}
.pricing {
	border: 1px solid $border-color;
	padding: 30px 0px;
	@include transition(box-shadow 0.3s ease);
	> h2 {
		line-height: 30px;
		margin-bottom: 10px;
	}
	> span  {
		color: $text-color;
		font-style: italic;
		display: inline-block;
		font-size: 14px;
	}
	.card-header {
		margin: 30px 0;
		border: none;
		padding: 30px 0;
		> span.price {
			color: $title-color;
			display: inline-block;
			font-size: 50px;
			line-height: 50px;
		}
		> span.currency {
			color: $title-color;
			font-size: 18px;
			display: inline-block;
		}
	}
	.list-group {
		padding-top: 20px;
		padding-bottom: 30px;
		.list-group-item {
			color: $title-color;
			i {
				color: $text-color;
			}
		}
	}
	.btn {
		font-weight: 700;
		color: $text-color;
		height: 50px;
		line-height: 50px;
		&:hover {
			background-color: $primary-color;
			color: $white-color;
			border-color: $primary-color;
		}
	}
	.card-block {
		position: relative;
	}
	.circle {
		position: relative;
		padding: 0 15px;
		height: 100px;
		width: 100px;
		background: transparent;
		left: 0px;
		top: 0;
		@include transform(translateX(0%));
		line-height: 100px;
		box-shadow: none;
		border: 1px solid $border-color;
		.price {
			font-size: 36px;
			font-weight: 700;
			color: $primary-color;
		}
		.tag {
			color: $title-color;
			font-size: 16px;
		}
	}
	&:hover {
		@include box-shadow(0px, 12px, 25px, rgba(0, 0, 0, 0.08));
		border: 0px solid transparent;
	}
	&.delux {
		.card-header {
			background-color: $primary-color;
			span.price, span.currency {
				color: $white-color;
			}
		}
	}
}
.style2 {
	padding: 40px;
	position: relative;
	h2 {
		margin: 25px 0 15px;
	}
	p {
		margin-bottom: 20px;
	}
	.btn {
		height: 40px;
		line-height: 40px;
		&:hover {
			color: $white-color!important;
		}
	}
}
// pricing image card ===================================
.pricing_image-card {
	@include transition(box-shadow 0.3s ease);
	.card_img {
		position: relative;
		.overlay {
			color: $white-color;
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0,0,0,0.4);
			display: flex;
		    flex-direction: column;
		    align-items: center;
		    justify-content: center;
			h2 {
				color: $white-color;
				margin-bottom: 10px;
			}
		}
	}
	.card-block {
		position: relative;
		padding: 80px 20px 30px;
		border: 1px solid $border-color;
		border-top: 0px;
		@include transition(border 0.3s ease);
		p {
			margin-bottom: 30px;
		}
	}
	.circle {
		top: -50px;
		width: 100px;
		height: 100px;
		text-align: center;
		line-height: 15px;
		background: $white-color;
		@include box-shadow(0px, 5px, 10px, rgba(0, 0, 0, 0.06));
		span.price {
		   color: $primary-color;
		   font-size: 34px;
		   display: inline-block;
		   font-weight: 700;
		   margin-top: 30px;
		}
		span.tag {
			color: $text-color;
			font-size: 13px;
			display: block;
			font-style: italic;
			line-height: 26px;
			text-transform: capitalize;
		}
	}
	.btn {
		font-weight: 700;
		color: $text-color;
		&:hover {
			background-color: $primary-color;
			color: $white-color!important;
			border-color: $primary-color;
		}
	}
	&:hover {
		@include box-shadow(0px, 12px, 25px, rgba(0, 0, 0, 0.08));
		.card-block {
			border: 0px solid transparent;
		}
	}
}

.display-single_element {
	.pricing, .pricing_image-card {
		margin-bottom: 30px;
		@include desktops {
			margin-bottom: 0px;
		}
	}
}
