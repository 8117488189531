///*====================================
//SERVICES PAGE STYLE
//======================================*/
//.page-services, .page-service-details {
//	.bredcrumb {
//		padding: 0px;
//	}
//}
//.services {
//	.home-testimonial {
//		.bg-image .card {
//			@include tablet {
//				padding: 25px 5px;
//			}
//			@include desktops {
//				padding: 80px 40px;
//			}
//			@include large-desktops {
//				padding: 100px 100px;
//			}
//		}
//	}
//}
//.service_single-area {
//	padding-top: 80px;
//	padding-bottom: 80px;
//	@include desktops {
//		padding-top: 100px;
//		padding-bottom: 100px;
//	}
//	.service_details-desc {
//		h2 {
//			margin-top: 40px;
//		}
//		p {
//			margin-top: 30px;
//		}
//		ul.styled_list {
//			margin-top: 30px;
//			i {
//				color: $primary-color;
//			}
//		}
//		h4 {
//			margin-top: 40px;
//		}
//	}
//}
//.sidebar {
//	.list-group {
//		margin-bottom: 40px;
//		@include tablet {
//			margin-bottom: 0;
//		}
//		.list-group-item {
//			margin-top: 10px;
//			&:first-child {
//				margin-top: 0px;
//			}
//			a {
//				font-size: 14px;
//				font-weight: 600;
//				position: relative;
//				color: $title-color;
//				padding-left: 20px;
//				&:before {
//					position: absolute;
//					content: '\f0da';
//					font-family: 'FontAwesome';
//					left: 0
//				}
//			}
//			&.active {
//				a {
//					color: $white-color;
//				}
//			}
//		}
//	}
//}
/*====================================
SERVICES PAGE STYLE
======================================*/
.page-services .bredcrumb, .page-service-details .bredcrumb {
	padding: 0;
}

.services .service-testimonial .promo-video {
	height: 100%;
	background-position: center;
	background-size: cover;
}

.services .service-testimonial .bg-image {
	height: auto;
}

@media (min-width: 768px) {
	.services .service-testimonial .bg-image .card {
		padding: 25px 5px;
	}
}

@media (min-width: 992px) {
	.services .service-testimonial .bg-image .card {
		padding: 15px 40px;
	}
}

@media (min-width: 1200px) {
	.services .service-testimonial .bg-image .card {
		padding: 100px 100px;
	}
}

.services2 {
	padding-top: 80px;
	padding-bottom: 40px;
}

@media (min-width: 992px) {
	.services2 {
		padding-top: 100px;
		padding-bottom: 60px;
	}
}

.services2.more-feature {
	padding-bottom: 80px;
}

@media (min-width: 992px) {
	.services2.more-feature {
		padding-bottom: 100px;
	}
}

.services2 .media-box {
	margin-bottom: 40px;
}

.services2 .media-box .media-icon {
	color: #4ac8ed;
}

.services2 .media-box h4 {
	margin-bottom: 10px;
}

.services2 .more-feature-left .more-features {
	position: relative;
}

.services2 .more-feature-left .more-features .feature-details {
	padding-left: 0px;
}

.services2 .more-feature-left .more-features .feature-details h3 {
	margin-bottom: 15px;
}

.services2 .more-feature-left .more-features .overlay-box {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	min-height: 100%;
	padding: 30px 30px;
	color: #848484;
	background:$white-color;
	border: 2px solid $primary-color;
	z-index: 1;
	opacity: 0;
}

.services2 .more-feature-left .more-features .overlay-box h4 {
	margin-bottom: 10px;
}

.services2 .more-feature-left .more-features:hover .overlay-box {
	opacity: 1;
	-webkit-animation: flipInY;
	animation: flipInY;
	-webkit-animation-duration: 1200ms;
	animation-duration: 1200ms;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

.pricing-area {
	padding-top: 80px;
	padding-bottom: 80px;
}

@media (min-width: 992px) {
	.pricing-area {
		padding-top: 100px;
		padding-bottom: 100px;
	}
}

.pricing-area .pricing {
	margin-top: 50px;
}

.pricing-area .pricing:hover .btn {
	background-color: $primary-color;
	border-color: $primary-color;
	color: $white-color;
}

.service_single-area {
	padding-top: 80px;
	padding-bottom: 80px;
}

@media (min-width: 992px) {
	.service_single-area {
		padding-top: 100px;
		padding-bottom: 100px;
	}
}

.service_single-area .service_details-desc h2 {
	margin-top: 40px;
}

.service_single-area .service_details-desc p {
	margin-top: 30px;
}

.service_single-area .service_details-desc ul.styled_list {
	margin-top: 30px;
}

.service_single-area .service_details-desc ul.styled_list i {
	color: $primary-color;
}

.service_single-area .service_details-desc h4 {
	margin-top: 40px;
}

.sidebar .list-group {
	margin-bottom: 40px;
}

@media (min-width: 768px) {
	.sidebar .list-group {
		margin-bottom: 0;
	}
}

.sidebar .list-group .list-group-item:first-child {
	margin-top: 0px;
}

.sidebar .list-group .list-group-item a {
	font-size: 14px;
	font-weight: 600;
	position: relative;
	color: $title-color;
	padding-left: 20px;
}

.sidebar .list-group .list-group-item a:before {
	position: absolute;
	content: '\f0da';
	font-family: 'FontAwesome';
	left: 0;
}

.sidebar .list-group .list-group-item.active a {
	color: $white-color;
}
