footer{
  padding-top: 80px;
  @include desktops {
	padding-top: 100px;
  }
}
/*======== Footer Classic version ========*/
.footer {
	.footer-text {
		a {
			.footer-logo {
				margin-bottom: 20px;
				@include tablet {
					margin-bottom: 30px;
				}
			}
		}
		p {
			color: $white-color;
            font-size: 14px;
            @include tablet {
                font-size: 13px;
                line-height: 24px;
            }
            @include desktops {
                font-size: 14px;
                line-height: 26px;
            }
		}
	}
	h3 {
		color: $white-color;
		margin-top: 30px;
		margin-bottom: 15px;
		@include tablet {
			margin-top: 0px;
		}
	}
	.social-icons {
		margin-top: 20px;
		margin-left: -10px;
		.btn-social-o {
			background-color: transparent!important;
			color: $white-color;
			font-size: 14px;
            @include tablet {
                width: 30px;
                height: 30px;
            }
            @include desktops {
                width: 30px;
                height: 30px;
            }
			&:hover {
				color: $primary-color;
			}
		}
	}

	.instagram {
		ul {
			padding: 0 10px;
			margin-top: 20px;
		}
		li {
			width: auto;
			overflow: hidden;
			padding: 10px 5px 0;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
}
.links {
	padding-left: 0;
	@include desktops {
		padding-left: 10px;
	}
	ul {
		margin-top: 25px;
	}
	li {
		a {
			display: block;
			position: relative;
			color: $white-color;
            font-size: 14px;
			margin-top: 20px;
			padding-left: 15px;
            @include tablet {
                font-size: 13px;
                margin-top: 15px;
            }
            @include desktops {
                font-size: 14px;
                margin-top: 20px;
            }
			&:before {
				position: absolute;
				content: "\f101";;
				font-family: 'FontAwesome';
				left: 0;
				@include transition(color 0.3s ease);
			}
			&:hover {
				color: $primary-color;
				opacity: 1!important;
				&:before {
					color: $primary-color;
				}
			}
		}
	}
}
.location {
	ul {
		margin-top: 25px;
		li {
			color: $white-color;
			margin-top: 15px;
			font-size: 14px;
			padding-left: 25px;
            @include tablet {
                font-size: 13px;
            }
            @include desktops {
                font-size: 14px;
            }
			&:first-child {
				padding-left: 0px;
			}
			a {
				color: $white-color;
				&:hover {
					color: $primary-color;
					opacity: 1 !important;
				}
			}
			i {
				display: inline-block;
				position: absolute;
				left: 15px;
				margin-top: 4px;
			}
		}
	}
}
.copyright {
	margin-top: 50px;
	hr {
		background-color: $white-color;
		opacity: 0.2;
        margin-top: 0px;
	}
	p{
		padding-bottom: 20px;
		color: $white-color;
		a {
			&:hover {
				color: $primary-color!important;
			}
		}
	}
}
.newsletter {
	padding-left: 0;
	@include desktops {
		padding-left: 15px;
	}
	p {
		margin-top: 22px;
		color: $white-color;
	}
	input {
		background-color: transparent;
		font-size: 14px;
		color: $title-color;
		margin-top: 30px;
		margin-bottom: 15px;

		&:hover, &:active, &:focus {
			background-color: transparent;
		}
	}
}
input.form-control, textarea.form-control, select.form-control {
	@include placeholder {
		color: $white-color;
		opacity: 1;
	}
}
/*======== Footer Corporate version ========*/

.footer-corporate {
	padding-top: 0;
	padding-bottom: 0;
	.footer-text {
		p {
			opacity: 0.7;
		}
	}
	.newsletter {
		padding-top: 80px;
		padding-bottom: 80px;
		@include tablet {
			padding-top: 100px;
			padding-bottom: 100px;
		}
	}
	.copyright {
		margin-top: 0;
		p {
			float: left;
			padding: 10px 0 30px;
		}
		hr {
			background-color: $dark-navy;
			opacity: 1;
			margin-top: 0;
		}
	}
	.social-icons {
		padding-top: 10px;
		margin-top: 0;
		margin-right: -10px;
		display: inline-block;
		float: right;
		margin-left: 0;
	}
}
.footer-consultant {
	.copyright {
		p {
			float: left;
			padding: 10px 0 30px;
		}
		hr {
			background-color: $dark-navy;
			opacity: 1;
			margin-top: 0;
		}
	}
	.social-icons {
		padding-top: 10px;
		margin-top: 0;
		margin-right: -10px;
		display: inline-block;
		float: right;
		margin-left: 0;
	}
	.location {
		ul {
			margin-top: 0;
		}
	}
}
