/* ====== index =====
1. COMMON
2. ELEMENTS
    2.1 SLIDER
    2.2 BUTTON
3. HEADER 
    3.1 TOP COLOR BAR
    3.2 TOP INFO BAR
    3.3 HEADER TRANSPARENT
    3.4 LIGHT HEADER
4. FOOTER
    4.1 FOOTER TOP
    4.2 COPY RIGHT
5. HOME
    5.1 BANNER SECTION
    5.2 FEATURE SECTION
    5.3 COUNTER UP SECTION
    5.4 PORTFOLIO SECTION
    5.5 TESTIMONIAL SECTION
    5.6 SERVICES SECTION
    5.7 FORM SECTION
    5.8 TEAM SECTION
    5.9 BRAND LOGO SECTION
    5.10 HOME BLOG SECTION
    5.11 EXPERT SECTION
6. ABOUT US
7. SERVICES
8. SERVICE DETAILS
9. PROJECTS
10. PROJECT DETAILS
11. TEAM
12. MEMBER DETAILS
13. BLOG GRID 2 COLUMN
    13.1 BLOG GRID 3 COLUMN
    13.2 BLOG CLASSIC 
    13.3 BLOG SINGLE POST
14. CONTACT US
17
8. BUSINESS
    8.1 ORDER SECTION
    8.2 CHECK OUT
9. PRODUCTS
    9.1 RELATED PRODUCT
    9.2 SINGLE SERVICE
    9.3 SINGLE PRODUCT
    9.4 PRODUCT
10. PROFILE



===================== */
@import 'partials/mixins';
@import 'partials/variables';
@import 'partials/media-query';
@import 'partials/animations';
@import 'reset';
//@import 'date-picker';
@import 'common';
@import 'elements/elements';
@import 'home';
@import 'footer';
@import 'about-us';
@import 'service';
@import 'projects';
@import 'team';
@import 'blog';
@import 'body-layout';
@import 'insta-gallery';
@import 'modified';







