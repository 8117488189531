.blockquote {
	position: relative;
	padding: 28px;
	margin-bottom: 20px;
	border: 1px solid $border-color;
	border-left: 3px solid $primary-color;
	font-size: 15px;
	line-height: 28px;
	font-style: italic;
	&.text-right {
		border-right: 3px solid $primary-color;
		border-left: 1px solid $border-color;
	}
	&.quote2 {
		border-left: 1px solid $border-color;
	}
}
.blockquote-bubble {
	position: relative;
	background-color: $white-color;
	.blockquote-bubble-content {
		&:after {
			top: 100%;
			border: 1px solid $border-color;
			content: " ";
			height: 20px;
			width: 20px;
			position: absolute;
			pointer-events: none;
			background:  $white-color;
			left: 30px;
			margin-top: -9px;
			transform: rotate(45deg);
			border-left: 0px;
			border-top: 0px;
		}
	}
	&.text-right {
		.blockquote-bubble-content {
			&:after {
				right: 30px;
				left: auto;
			}
		}
	}
}
.blockquote-footer {
	margin-bottom: 20px;
	position: relative;
	display: flex;
	img {
		width: 60px;
		height: 60px;
		background: $sand;
		float: left;
	}
	.info {
		display: inline-block;
		padding-left: 10px;
		float: left;
		margin-top: 8px;
	}
	.name {
		font-size: 15px;
		color: $title-color;
		display: inline-block;
	}
	.designation {
		font-size: 15px;
		color: $text-color;
		display: inline-block;
	}
	&:before {
	    content: "\2014 \00A0";
	    visibility: hidden;
	}
	&.text-right {
		flex-direction: row-reverse;
		img {
			float: right;
		}
		.info {
			float: right;
			padding-left: 0px;
			padding-right: 10px;
		}
	}
	&.footer2 {
		margin-top: 20px;
		margin-left: 40px;
	}
}
.arrow-down {
    border-left: 20px solid transparent;
    border-right: 0 solid transparent;
    border-top: 20px solid $border-color;
    bottom: -20px;
    height: 0;
    left: 25px;
    position: absolute;
    width: 0;
}
// TESTIMONIALS WITH BACKGROUDND IMAGE ====================
.testimonial_holder {
	padding: 50px 20px;
	.bg-image {
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(31,40,46,0.9);
		}
	}
}
.bg-image {
	position: relative;
	background-size: cover;
	background-position: center;
	.quote2 {
		border-left: 1px solid $border-color;
		border-color: $white-color;
	}
	.blockquote-speech {
		color: $title-color;
	}
	.blockquote-footer {
		.name {
			font-size: 15px;
			color: $primary-color;
			display: inline-block;
		}
		.designation {
			font-size: 15px;
			color: $white-color;
			font-style: italic;
			opacity: 0.7;
			display: inline-block;
		}
	}
	.arrow-down {
		border-left: 20px solid transparent;
		border-right: 0 solid transparent;
		border-top: 20px solid $white-color;
		bottom: -20px;
		height: 0;
		left: 25px;
		position: absolute;
		width: 0;
	}
}
