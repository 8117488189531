// Instagram Gallery

.insta-gallery {
  margin: 10px auto;
}

.insta-gallery > .insta-gallery__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  li {
    list-style: none;
    border: 1px #efefef solid;
    margin: 10px;
  }
}