/* ================================================
--> Elements Bredcrumb style
================================================== */
.bredcrumb {
	opacity: 1;
	position: relative;
	padding: 50px 25px;
	@include desktops {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	ul {
		position: relative;
		li, a {
		    display: inline-block;
		    font-size: 13px;
		    color: #ffffff;
		    padding: 7px 7px;
		    @include tablet {
		    	font-size: 15px;
		    }
		}
		li:after {
		    position: absolute;
		    content: '\f101';
		    font-family: FontAwesome;
		    top: 14px;
		}
		li:last-child:after {
		    display: none;
		}
		li:first-child {
			padding-left: 0px;
		    a {
		    	padding-left: 0px;
		    }
		}
		&.pager {
			text-align: left;
			@include tablet {
				text-align: right;
			}
		}
	}
	h2 {
		color: $white-color;
		font-size: 28px;
		line-height: 45px;
		@include tablet {
			font-size: 40px;
		}
	}
	p {
		color: $white-color;
		line-height: 1.5;
		@include desktops {
			line-height: 1;
		}
	}
	p.devider.devider_narrow {
	    max-width: 10%;
	    margin-bottom: 0;
	    margin-top: 15px;
	    margin-left: 0;
	    margin-right: auto;
	}
}
.display-single_element {
	.bredcrumb {
		h2 {
			padding-top: 0px!important;
		}
	}
}
