/* ====== index =====
1. COMMON
2. ELEMENTS
    2.1 SLIDER
    2.2 BUTTON
3. HEADER 
    3.1 TOP COLOR BAR
    3.2 TOP INFO BAR
    3.3 HEADER TRANSPARENT
    3.4 LIGHT HEADER
4. FOOTER
    4.1 FOOTER TOP
    4.2 COPY RIGHT
5. HOME
    5.1 BANNER SECTION
    5.2 FEATURE SECTION
    5.3 COUNTER UP SECTION
    5.4 PORTFOLIO SECTION
    5.5 TESTIMONIAL SECTION
    5.6 SERVICES SECTION
    5.7 FORM SECTION
    5.8 TEAM SECTION
    5.9 BRAND LOGO SECTION
    5.10 HOME BLOG SECTION
    5.11 EXPERT SECTION
6. ABOUT US
7. SERVICES
8. SERVICE DETAILS
9. PROJECTS
10. PROJECT DETAILS
11. TEAM
12. MEMBER DETAILS
13. BLOG GRID 2 COLUMN
    13.1 BLOG GRID 3 COLUMN
    13.2 BLOG CLASSIC 
    13.3 BLOG SINGLE POST
14. CONTACT US
17
8. BUSINESS
    8.1 ORDER SECTION
    8.2 CHECK OUT
9. PRODUCTS
    9.1 RELATED PRODUCT
    9.2 SINGLE SERVICE
    9.3 SINGLE PRODUCT
    9.4 PRODUCT
10. PROFILE



===================== */
/*=== MIXINS ===*/
/*=== VARIABLES ===*/
/*============ TEXT COLOR VARIATION =============*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700|Open+Sans:400,400i,600");
.text-primary {
  color: #277874 !important; }

.text-dark {
  color: #222222 !important; }

/* Background Colors */
.bg-white {
  background-color: #ffffff; }

.bg-dark {
  background-color: #013A68; }

/*=== MEDIA QUERY ===*/
.element-animation {
  animation: animationFrames linear 0.8s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  -webkit-animation: animationFrames linear 0.8s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: animationFrames linear 0.8s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -o-animation: animationFrames linear 0.8s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -ms-animation: animationFrames linear 0.8s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%; }

@keyframes animationFrames {
  0% {
    transform: translate(0px, 0px); }
  15% {
    transform: translate(0px, -25px); }
  30% {
    transform: translate(0px, 0px); }
  45% {
    transform: translate(0px, -15px); }
  60% {
    transform: translate(0px, 0px); }
  75% {
    transform: translate(0px, -5px); }
  100% {
    transform: translate(0px, 0px); } }

@-moz-keyframes animationFrames {
  0% {
    -moz-transform: translate(0px, 0px); }
  15% {
    -moz-transform: translate(0px, -25px); }
  30% {
    -moz-transform: translate(0px, 0px); }
  45% {
    -moz-transform: translate(0px, -15px); }
  60% {
    -moz-transform: translate(0px, 0px); }
  75% {
    -moz-transform: translate(0px, -5px); }
  100% {
    -moz-transform: translate(0px, 0px); } }

@-webkit-keyframes animationFrames {
  0% {
    -webkit-transform: translate(0px, 0px); }
  15% {
    -webkit-transform: translate(0px, -25px); }
  30% {
    -webkit-transform: translate(0px, 0px); }
  45% {
    -webkit-transform: translate(0px, -15px); }
  60% {
    -webkit-transform: translate(0px, 0px); }
  75% {
    -webkit-transform: translate(0px, -5px); }
  100% {
    -webkit-transform: translate(0px, 0px); } }

@-o-keyframes animationFrames {
  0% {
    -o-transform: translate(0px, 0px); }
  15% {
    -o-transform: translate(0px, -25px); }
  30% {
    -o-transform: translate(0px, 0px); }
  45% {
    -o-transform: translate(0px, -15px); }
  60% {
    -o-transform: translate(0px, 0px); }
  75% {
    -o-transform: translate(0px, -5px); }
  100% {
    -o-transform: translate(0px, 0px); } }

@-ms-keyframes animationFrames {
  0% {
    -ms-transform: translate(0px, 0px); }
  15% {
    -ms-transform: translate(0px, -25px); }
  30% {
    -ms-transform: translate(0px, 0px); }
  45% {
    -ms-transform: translate(0px, -15px); }
  60% {
    -ms-transform: translate(0px, 0px); }
  75% {
    -ms-transform: translate(0px, -5px); }
  100% {
    -ms-transform: translate(0px, 0px); } }

.blink_me {
  animation: blinker 3s linear infinite; }

@keyframes blinker {
  50% {
    opacity: 0; } }

* {
  outline: 0;
  margin: 0;
  padding: 0; }

html, * {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #848484;
  font-weight: 400; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style-type: none; }

p {
  color: #848484;
  font-weight: 400;
  margin-bottom: 0; }
  @media (min-width: 320px) {
    p {
      font-size: 15px;
      line-height: 28px; } }
  @media (min-width: 768px) {
    p {
      font-size: 15px;
      line-height: 26px; } }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  font-weight: 400;
  color: #222222; }

h1 {
  font-size: 40px;
  line-height: 1.1; }

h2 {
  font-size: 30px;
  line-height: 1.1; }

h3 {
  font-size: 25px;
  line-height: 1.1; }

h4 {
  font-size: 20px;
  line-height: 1.1; }

h5 {
  font-size: 18px;
  line-height: 1.1; }

h6 {
  font-size: 16px;
  line-height: 1.1; }

/*=== 1.COMMON ===*/
/* Font faces */
.container {
  position: relative;
  width: 100%; }
  @media (min-width: 480px) {
    .container {
      width: 450px; } }
  @media (min-width: 768px) {
    .container {
      width: 750px; } }
  @media (min-width: 992px) {
    .container {
      width: 970px; } }
  @media (min-width: 1200px) {
    .container {
      width: 1140px; } }

a {
  color: #848484;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  a:hover, a:focus {
    outline: 0;
    color: #277874;
    text-decoration: none; }

.main-wrapper {
  overflow-x: hidden; }

@media (min-width: 768px) {
  .boxed .main-nav {
    box-shadow: none; } }

.boxed .main-wrapper {
  position: relative;
  background-color: #ffffff;
  margin: 0 auto;
  overflow-x: hidden; }
  @media (min-width: 480px) {
    .boxed .main-wrapper {
      max-width: 520px; } }
  @media (min-width: 768px) {
    .boxed .main-wrapper {
      padding: 0px 0 0;
      max-width: 728px; } }
  @media (min-width: 992px) {
    .boxed .main-wrapper {
      padding: 0px 0 0;
      max-width: 970px; } }
  @media (min-width: 1200px) {
    .boxed .main-wrapper {
      padding: 0px 0 0;
      max-width: 1260px; } }

.boxed .home_transparent-wrapper.main-wrapper {
  padding-top: 0px; }

@media (min-width: 480px) {
  .boxed .navbar-sticky {
    max-width: 520px;
    left: 50%;
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); } }

@media (min-width: 768px) {
  .boxed .navbar-sticky {
    padding: 25px 0 0;
    max-width: 728px; } }

@media (min-width: 992px) {
  .boxed .navbar-sticky {
    padding: 80px 0 0;
    max-width: 970px; } }

@media (min-width: 1200px) {
  .boxed .navbar-sticky {
    padding: 80px 0 0;
    max-width: 1260px; } }

.list-inline li {
  display: inline-block; }

.position_unset > .offset-md-1.col-md-10 {
  position: unset; }
  .position_unset > .offset-md-1.col-md-10 > .row {
    position: unset; }

.visible {
  display: block !important; }

.hidden {
  display: none; }

.form-control:focus {
  box-shadow: none; }

.btn-cta {
  color: #222222 !important;
  background-color: #ffffff; }

.section {
  padding-top: 80px; }
  @media (min-width: 992px) {
    .section {
      padding-top: 100px; } }

.sectionTitle {
  position: relative; }
  .sectionTitle h2 {
    line-height: 1;
    padding-bottom: 8px;
    color: #222222;
    margin: 0; }
    @media (min-width: 320px) {
      .sectionTitle h2 {
        font-size: 30px;
        letter-spacing: 0px; } }
    @media (min-width: 768px) {
      .sectionTitle h2 {
        font-size: 34px;
        letter-spacing: 0px; } }
  .sectionTitle p {
    opacity: 0.7; }

.img-full {
  width: 100%; }

.img-circle {
  position: relative;
  border-radius: 50%; }

.bg-image-holder, .bg-image {
  background-size: cover !important;
  background-position: 50% 50% !important;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  background: #f5f5f5;
  position: relative; }

.bg-image:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(10, 35, 59, 0.85); }

.bg-gradient-navy {
  background-image: -moz-linear-gradient(0deg, #0a233b 0%, #1a3550 50%, #0a233b 100%);
  background-image: -webkit-linear-gradient(0deg, #0a233b 0%, #1a3550 50%, #0a233b 100%);
  background-image: -ms-linear-gradient(0deg, #0a233b 0%, #1a3550 50%, #0a233b 100%);
  background-image: linear-gradient(0deg, #0a233b 0%, #1a3550 50%, #0a233b 100%); }

::selection {
  background-color: #277874;
  color: #ffffff; }

.bg-sand {
  background-color: #f5f5f5; }

.bg-primary {
  background-color: #277874 !important; }

.bg-navy {
  background-color: #013A68 !important; }

.bg-dark-navy {
  background-color: #004387 !important; }

.no-bg {
  background-color: transparent; }

.bordered {
  border: 1px solid #e5e5e5; }

.mb30 {
  margin-bottom: 30px; }

.no-padding {
  padding: 0 !important; }

.pv100 {
  padding-top: 100px;
  padding-bottom: 100px; }

.pt30 {
  padding-top: 30px; }

.pb30 {
  padding-bottom: 30px; }

.pb50 {
  padding-bottom: 50px; }

.pv30 {
  padding-top: 30px;
  padding-bottom: 30px; }

.pt100 {
  padding-top: 100px; }

.pb100 {
  padding-bottom: 100px; }

.bold {
  font-weight: 700 !important; }

.medium {
  font-weight: 500 !important; }

.table-inverse {
  background-color: #013A68; }

.sidebar {
  padding-left: 15px;
  margin-top: 40px; }
  @media (min-width: 768px) {
    .sidebar {
      padding-left: 30px;
      margin-top: 0px; } }
  @media (min-width: 992px) {
    .sidebar {
      padding-left: 40px;
      margin-top: 0px; } }
  @media (min-width: 768px) {
    .sidebar.sidebar-left {
      padding-left: 15px; } }
  @media (min-width: 992px) {
    .sidebar.sidebar-left {
      padding-left: 15px; } }

/*============== Hover Overlays ==============*/
.hover-overlay {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .hover-overlay a.overlay_icon {
    width: 55px;
    height: 55px;
    background: #fff;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    margin: 3px;
    text-align: center; }
    .hover-overlay a.overlay_icon i.fa {
      font-size: 20px;
      line-height: 50px;
      color: #848484; }

.hover-overlay.effect-transformY {
  position: absolute;
  content: '';
  background-color: rgba(39, 120, 116, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -moz-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -ms-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease; }

.hover-overlay.effect-scale {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0; }
  .hover-overlay.effect-scale:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.4s;
    transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.4s;
    background: rgba(10, 35, 59, 0.5); }

.card:hover .hover-overlay.effect-transformY, .card_img:hover .hover-overlay.effect-transformY {
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0); }

.card:hover .hover-overlay.effect-scale, .card_img:hover .hover-overlay.effect-scale {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1; }
  .card:hover .hover-overlay.effect-scale:before, .card_img:hover .hover-overlay.effect-scale:before {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); }

.img_overlay-container {
  position: relative;
  padding: 0 30px; }
  .img_overlay-container .img_overlay-title {
    color: #ffffff;
    margin-bottom: 10px; }
  .img_overlay-container .img_overlay-desc {
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 20px; }
  .img_overlay-container a.icon {
    width: 38px;
    height: 38px;
    font-size: 15px;
    line-height: 36px;
    background-color: transparent;
    text-align: center;
    display: inline-block;
    color: #ffffff;
    border: 1px solid #ffffff;
    margin-right: 5px; }
    .img_overlay-container a.icon:hover {
      background-color: #277874;
      border-color: #277874; }

.highlighter {
  background: #282C34;
  padding: 0px 10px;
  border-radius: 4px;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace !important;
  display: inline-block; }

.bredcrumb .bg-image {
  padding: 60px 0 60px; }
  .bredcrumb .bg-image:before {
    background-color: rgba(0, 0, 0, 0.65); }
  .bredcrumb .bg-image h1 {
    color: #ffffff;
    position: relative;
    font-weight: 700;
    margin-bottom: 0px; }

.bredcrumb .pager.middle {
  text-align: center;
  background-color: #f5f5f8; }
  .bredcrumb .pager.middle li {
    padding: 20px 5px;
    position: relative;
    text-transform: capitalize;
    color: #222222;
    font-weight: 600; }
    .bredcrumb .pager.middle li:after {
      content: "\f105";
      right: -8px;
      top: 20px; }
  .bredcrumb .pager.middle a {
    color: #277874; }

/* fade in up */
@-webkit-keyframes customFadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
    -o-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

@-moz-keyframes customFadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
    -o-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

@-o-keyframes customFadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
    -o-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

@keyframes customFadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
    -o-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

.customFadeInUp {
  -webkit-animation-name: customFadeInUp;
  animation-name: customFadeInUp;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s; }

@-webkit-keyframes customFadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-25px);
    -o-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

@-moz-keyframes customFadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-25px);
    -o-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

@-o-keyframes customFadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-25px);
    -o-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

@keyframes customFadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-25px);
    -o-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

.customFadeInDown {
  -webkit-animation-name: customFadeInDown;
  animation-name: customFadeInDown;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s; }

@-webkit-keyframes featureFadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

@-moz-keyframes featureFadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

@-o-keyframes featureFadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

@keyframes featureFadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

.featureFadeInDown {
  -webkit-animation-name: featureFadeInDown;
  animation-name: featureFadeInDown;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s; }

@-webkit-keyframes fadeInScale {
  0% {
    opacity: 0;
    -moz-transform: scale3d(0.89, 0.89, 0.89);
    -o-transform: scale3d(0.89, 0.89, 0.89);
    -ms-transform: scale3d(0.89, 0.89, 0.89);
    -webkit-transform: scale3d(0.89, 0.89, 0.89);
    transform: scale3d(0.89, 0.89, 0.89); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

@-moz-keyframes fadeInScale {
  0% {
    opacity: 0;
    -moz-transform: scale3d(0.89, 0.89, 0.89);
    -o-transform: scale3d(0.89, 0.89, 0.89);
    -ms-transform: scale3d(0.89, 0.89, 0.89);
    -webkit-transform: scale3d(0.89, 0.89, 0.89);
    transform: scale3d(0.89, 0.89, 0.89); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

@-o-keyframes fadeInScale {
  0% {
    opacity: 0;
    -moz-transform: scale3d(0.89, 0.89, 0.89);
    -o-transform: scale3d(0.89, 0.89, 0.89);
    -ms-transform: scale3d(0.89, 0.89, 0.89);
    -webkit-transform: scale3d(0.89, 0.89, 0.89);
    transform: scale3d(0.89, 0.89, 0.89); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInScale {
  0% {
    opacity: 0;
    -moz-transform: scale3d(0.89, 0.89, 0.89);
    -o-transform: scale3d(0.89, 0.89, 0.89);
    -ms-transform: scale3d(0.89, 0.89, 0.89);
    -webkit-transform: scale3d(0.89, 0.89, 0.89);
    transform: scale3d(0.89, 0.89, 0.89); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

.fadeInScale {
  -webkit-animation-name: fadeInScale;
  animation-name: fadeInScale;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s; }

@-webkit-keyframes fadeInScaleDown {
  0% {
    opacity: 0;
    -moz-transform: scale3d(1.1, 1.1, 1.1);
    -o-transform: scale3d(1.1, 1.1, 1.1);
    -ms-transform: scale3d(1.1, 1.1, 1.1);
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

@-moz-keyframes fadeInScaleDown {
  0% {
    opacity: 0;
    -moz-transform: scale3d(1.1, 1.1, 1.1);
    -o-transform: scale3d(1.1, 1.1, 1.1);
    -ms-transform: scale3d(1.1, 1.1, 1.1);
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

@-o-keyframes fadeInScaleDown {
  0% {
    opacity: 0;
    -moz-transform: scale3d(1.1, 1.1, 1.1);
    -o-transform: scale3d(1.1, 1.1, 1.1);
    -ms-transform: scale3d(1.1, 1.1, 1.1);
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInScaleDown {
  0% {
    opacity: 0;
    -moz-transform: scale3d(1.1, 1.1, 1.1);
    -o-transform: scale3d(1.1, 1.1, 1.1);
    -ms-transform: scale3d(1.1, 1.1, 1.1);
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  100% {
    opacity: 1;
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none; } }

.fadeInScaleDown {
  -webkit-animation-name: fadeInScaleDown;
  animation-name: fadeInScaleDown;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -webkit-animation-duration: 0.8s;
  -moz-animation-duration: 0.8s;
  -o-animation-duration: 0.8s;
  -ms-animation-duration: 0.8s;
  animation-duration: 0.8s; }

/* ELEMENTS */
/*================INDEX================

=======================================*/
/* ================================================
--> Elements Right Sidebar style
================================================== */
.btn-search {
  background: transparent;
  border: none;
  position: absolute;
  top: 16px;
  right: 15px;
  color: #848484;
  cursor: pointer; }

.element-sidebar .form_search {
  position: relative; }
  .element-sidebar .form_search .form-control {
    background-color: transparent;
    margin-bottom: 20px;
    border: 1px solid #e5e5e5; }

.element-sidebar h4 {
  display: block;
  margin-bottom: 25px;
  color: #222222; }

.element-sidenav {
  display: block;
  margin-bottom: 80px; }
  .element-sidenav .nav-item {
    height: 42px;
    background: #f7f7f7;
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: #e5e5e5;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .element-sidenav .nav-item > a.nav-link {
      font-size: 16px;
      padding: 0 20px;
      display: block;
      width: 100%;
      font-weight: 700;
      color: #222222; }
      .element-sidenav .nav-item > a.nav-link .fa {
        padding-right: 10px;
        font-size: 14px; }
      .element-sidenav .nav-item > a.nav-link:hover {
        color: #277874; }
    .element-sidenav .nav-item.active {
      background-color: #ffffff;
      border-left: 0px; }
      .element-sidenav .nav-item.active > a.nav-link {
        color: #277874; }
    .element-sidenav .nav-item:last-child {
      border-bottom: 1px solid #e5e5e5; }

/* ================================================
--> Element Single block style
================================================== */
.element_single .card.single-block {
  margin-bottom: 30px;
  border: none;
  border-radius: 0px;
  background-color: #f5f5f5; }
  .element_single .card.single-block .card-header {
    padding: 20px;
    border-bottom: none;
    border-radius: 0px;
    background-color: #f5f5f5;
    position: relative; }
    @media (min-width: 992px) {
      .element_single .card.single-block .card-header {
        padding: 20px 25px; } }
    .element_single .card.single-block .card-header h3 {
      font-family: 'Lato', sans-serif;
      font-size: 20px;
      max-width: 60%; }
      @media (min-width: 768px) {
        .element_single .card.single-block .card-header h3 {
          font-size: 25px;
          max-width: 100%; } }
      .element_single .card.single-block .card-header h3 a {
        color: #848484;
        background: #ffffff;
        padding: 10px 10px;
        font-size: 14px;
        border-radius: 4px;
        right: 20px;
        position: absolute;
        top: 50%;
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%); }
        @media (min-width: 768px) {
          .element_single .card.single-block .card-header h3 a {
            float: right;
            right: 0px;
            position: relative;
            -moz-transform: translateY(0%);
            -o-transform: translateY(0%);
            -ms-transform: translateY(0%);
            -webkit-transform: translateY(0%);
            transform: translateY(0%); } }
  .element_single .card.single-block .card-block {
    padding: 0; }
    .element_single .card.single-block .card-block .code_container {
      margin-right: 0;
      margin-left: 0;
      border-top: 1px solid rgba(2, 2, 2, 0.09);
      padding: 10px 20px; }
      .element_single .card.single-block .card-block .code_container h5 {
        font-family: 'Lato', sans-serif;
        color: #848484;
        font-size: 18px;
        line-height: 60px;
        text-transform: unset; }
      .element_single .card.single-block .card-block .code_container p {
        color: #222222; }
      .element_single .card.single-block .card-block .code_container ul {
        padding: 10px 15px 20px; }
        .element_single .card.single-block .card-block .code_container ul li {
          color: #222222;
          padding: 5px 15px;
          font-size: 15px;
          line-height: 26px; }
          .element_single .card.single-block .card-block .code_container ul li:before {
            content: "\f10c";
            /* FontAwesome Unicode */
            font-family: FontAwesome;
            display: inline-block;
            font-size: 14px;
            color: #848484;
            margin-left: -24px;
            /* same as padding-left set on li */
            width: 24px;
            /* same as padding-left set on li */ }
      .element_single .card.single-block .card-block .code_container .s {
        color: #e6db74; }
      .element_single .card.single-block .card-block .code_container .nt {
        color: #f92672; }
      .element_single .card.single-block .card-block .code_container .na {
        color: #a6e22e; }
  .element_single .card.single-block pre {
    margin: 10px 0px;
    background-color: #282C34;
    color: #ABB2BF; }
    .element_single .card.single-block pre code {
      color: #ABB2BF;
      font-size: 14px; }

.element_single .display-single_element {
  margin-bottom: 80px; }
  .element_single .display-single_element > p {
    font-size: 15px;
    margin-bottom: 15px; }

.element_single .dropdown-menu {
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  background-color: #ffffff;
  margin-top: 0px;
  padding: 5px 20px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s ease-in-out;
  transition: transform 0.3s, opacity 0.3s ease-in-out, -webkit-transform 0.3s; }
  @media (min-width: 768px) {
    .element_single .dropdown-menu {
      display: none; } }

@media (min-width: 768px) {
  .element_single .show .dropdown-menu {
    display: block; } }

.element_single .progress {
  margin: 10px 0; }

.element_single .bd-example {
  padding: 1.5rem;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  border-width: .2rem; }

.element_single .scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: .5rem;
  overflow: auto; }

#cards_element footer {
  padding: 20px; }

/* ================================================
--> Section divider / hr style
================================================== */
hr.hr_narrow {
  width: 7%;
  height: 2px;
  height: 20px 0; }

.hr_color, .hr_color hr {
  color: #ffffff;
  background: #ffffff; }

hr.hr-inline {
  display: inline-block; }

/* ================================================
--> Elements footer style
================================================== */
.footer.element-footer {
  padding: 50px 0; }

/*================================================
--> Element Buttons
==================================================*/
#buttons .display-single_element .btn {
  margin: 0px 5px 10px 0; }

.btn {
  border: none;
  border-radius: 0px;
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
  line-height: 40px;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  @media (min-width: 992px) {
    .btn {
      font-size: 14px; } }
  .btn.focus, .btn:focus, .btn.active, .btn:active, .btn:hover {
    box-shadow: none; }
  .btn.btn-rounded {
    border-radius: 40px; }
  .btn i {
    font-size: inherit; }

.btn-primary {
  background-color: #265a88;
  border: 1px solid #265a88; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary.active, .btn-primary:active {
    background-color: #265a88 !important;
    border: 1px solid #265a88 !important; }

.btn-secondary {
  background-color: #f5f5f5;
  color: #222222;
  border: 1px solid #dedede; }
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary.active, .btn-secondary:active {
    background-color: #f5f5f5;
    border: 1px solid #dedede; }

.btn-success {
  background-color: #004387;
  border: 1px solid #004387; }
  .btn-success:hover, .btn-success:focus, .btn-success.active, .btn-success:active {
    background-color: #004387;
    border: 1px solid #004387; }

.btn-info {
  background-color: #439fdb;
  border: 1px solid #439fdb; }
  .btn-info:hover, .btn-info:focus, .btn-info.active, .btn-info:active {
    background-color: #439fdb;
    border: 1px solid #439fdb; }

.btn-warning {
  background-color: #eabf38;
  border: 1px solid #eabf38; }
  .btn-warning:hover, .btn-warning:focus, .btn-warning.active, .btn-warning:active {
    background-color: #eabf38;
    border: 1px solid #eabf38; }

.btn-danger {
  background-color: #c12e2a;
  border: 1px solid #c12e2a; }
  .btn-danger:hover, .btn-danger:focus, .btn-danger.active, .btn-danger:active {
    background-color: #c12e2a;
    border: 1px solid #c12e2a; }

.btn-inverse {
  background-color: #323f41;
  border: 1px solid #323f41; }
  .btn-inverse:hover, .btn-inverse:focus, .btn-inverse.active, .btn-inverse:active {
    background-color: #323f41;
    border: 1px solid #323f41; }

.btn-primary-outlined {
  background-color: transparent;
  color: #265a88;
  border: 1px solid #265a88; }
  .btn-primary-outlined:hover, .btn-primary-outlined:focus, .btn-primary-outlined.active, .btn-primary-outlined:active {
    background-color: #265a88;
    color: #ffffff; }

.btn-secondary-outlined {
  background-color: transparent;
  color: #222222;
  border: 1px solid #dedede; }
  .btn-secondary-outlined:hover, .btn-secondary-outlined:focus, .btn-secondary-outlined.active, .btn-secondary-outlined:active {
    background-color: #f5f5f5;
    color: #222222; }

.btn-success-outlined {
  background-color: transparent;
  color: #004387;
  border: 1px solid #004387; }
  .btn-success-outlined:hover, .btn-success-outlined:focus, .btn-success-outlined.active, .btn-success-outlined:active {
    background-color: #004387;
    color: #ffffff; }

.btn-info-outlined {
  background-color: transparent;
  color: #439fdb;
  border: 1px solid #439fdb; }
  .btn-info-outlined:hover, .btn-info-outlined:focus, .btn-info-outlined.active, .btn-info-outlined:active {
    background-color: #439fdb;
    color: #ffffff; }

.btn-warning-outlined {
  background-color: transparent;
  color: #eabf38;
  border: 1px solid #eabf38; }
  .btn-warning-outlined:hover, .btn-warning-outlined:focus, .btn-warning-outlined.active, .btn-warning-outlined:active {
    background-color: #eabf38;
    color: #ffffff; }

.btn-danger-outlined {
  background-color: transparent;
  color: #c12e2a;
  border: 1px solid #c12e2a; }
  .btn-danger-outlined:hover, .btn-danger-outlined:focus, .btn-danger-outlined.active, .btn-danger-outlined:active {
    background-color: #c12e2a;
    color: #ffffff; }

.btn-inverse-outlined {
  background-color: transparent;
  color: #323f41;
  border: 1px solid #323f41; }
  .btn-inverse-outlined:hover, .btn-inverse-outlined:focus, .btn-inverse-outlined.active, .btn-inverse-outlined:active {
    background-color: #323f41;
    color: #ffffff; }

/*================================================
--> Button sizes
==================================================*/
.btn.btn-sm {
  padding: 0px 10px;
  font-size: 12px;
  height: 30px;
  line-height: 30px; }

.btn.btn-mid {
  height: 40px;
  line-height: 40px;
  padding: 0 20px; }

.btn.btn-lg {
  height: 50px;
  line-height: 50px;
  padding: 0 35px; }

.btn.btn-default {
  height: 50px;
  line-height: 50px;
  padding: 0 30px; }

.btn.btn-icon i {
  margin-right: 5px; }

/*================================================
--> Social Buttons
==================================================*/
.btn-facebook {
  background-color: #3b5999; }

.btn-twitter {
  background-color: #55acee; }

.btn-social {
  font-size: 16px;
  width: 35px;
  height: 35px;
  text-align: center;
  padding: 0;
  border-radius: 0;
  margin: 0 auto;
  line-height: 32px;
  display: inline-block; }
  .btn-social i {
    margin-right: 0px; }
  .btn-social.btn-cicle {
    border-radius: 50%; }
  .btn-social.rounded {
    border-radius: 4px; }
  .btn-social.titled {
    text-transform: capitalize;
    width: auto;
    height: 35px;
    border-radius: 0px;
    padding: 0 20px;
    line-height: 35px; }
    .btn-social.titled i {
      margin-right: 5px; }
  .btn-social.facebook {
    background-color: #3b5999; }
  .btn-social.twitter {
    background-color: #55acee; }
  .btn-social.pinterest {
    background-color: #bd081c; }
  .btn-social.skype {
    background-color: #00aff0; }
  .btn-social.linkedin {
    background-color: #0077b5; }
  .btn-social.google-plus {
    background-color: #dd4b39; }
  .btn-social.youtube {
    background-color: #cc181e; }
  .btn-social.btn-social-o:hover {
    background-color: transparent;
    box-shadow: none; }
    .btn-social.btn-social-o:hover.facebook {
      color: #3b5999; }
    .btn-social.btn-social-o:hover.twitter {
      color: #55acee; }
    .btn-social.btn-social-o:hover.pinterest {
      color: #bd081c; }
    .btn-social.btn-social-o:hover.skype {
      color: #00aff0; }
    .btn-social.btn-social-o:hover.linkedin {
      color: #0077b5; }
    .btn-social.btn-social-o:hover.google-plus {
      color: #dd4b39; }
    .btn-social.btn-social-o:hover.youtube {
      color: #cc181e; }
  .btn-social.outlined {
    background-color: #ffffff; }
    .btn-social.outlined.facebook {
      color: #3b5999;
      border: 1px solid;
      border-color: #3b5999; }
      .btn-social.outlined.facebook:hover {
        background-color: #3b5999;
        color: #ffffff; }
    .btn-social.outlined.twitter {
      color: #55acee;
      border: 1px solid;
      border-color: #55acee; }
      .btn-social.outlined.twitter:hover {
        background-color: #55acee;
        color: #ffffff; }
    .btn-social.outlined.pinterest {
      color: #bd081c;
      border: 1px solid;
      border-color: #bd081c; }
      .btn-social.outlined.pinterest:hover {
        background-color: #bd081c;
        color: #ffffff; }
    .btn-social.outlined.skype {
      color: #00aff0;
      border: 1px solid;
      border-color: #00aff0; }
      .btn-social.outlined.skype:hover {
        background-color: #00aff0;
        color: #ffffff; }
    .btn-social.outlined.linkedin {
      color: #0077b5;
      border: 1px solid;
      border-color: #0077b5; }
      .btn-social.outlined.linkedin:hover {
        background-color: #0077b5;
        color: #ffffff; }
    .btn-social.outlined.google-plus {
      color: #dd4b39;
      border: 1px solid;
      border-color: #dd4b39; }
      .btn-social.outlined.google-plus:hover {
        background-color: #dd4b39;
        color: #ffffff; }
    .btn-social.outlined.youtube {
      color: #cc181e;
      border: 1px solid;
      border-color: #cc181e; }
      .btn-social.outlined.youtube:hover {
        background-color: #cc181e;
        color: #ffffff; }

.socialLink a {
  height: 45px;
  width: 45px;
  position: relative;
  display: inline-block;
  font-size: 16px;
  background: transparent;
  color: #848484;
  line-height: 44px;
  border-radius: 50%;
  border: 1px solid rgba(132, 132, 132, 0.5); }
  .socialLink a:focus, .socialLink a:hover, .socialLink a.active, .socialLink a:active {
    background-color: #265a88;
    border: 1px solid #265a88;
    color: #ffffff;
    opacity: 1; }
  @media (min-width: 768px) {
    .socialLink a {
      height: 30px;
      width: 30px;
      font-size: 14px;
      line-height: 30px; } }
  @media (min-width: 992px) {
    .socialLink a {
      height: 45px;
      width: 45px;
      font-size: 16px;
      line-height: 44px; } }

.footer-dark .socialLink a {
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.7);
  opacity: 0.7; }
  .footer-dark .socialLink a:focus, .footer-dark .socialLink a:hover, .footer-dark .socialLink a.active, .footer-dark .socialLink a:active {
    background-color: #265a88;
    border: 1px solid #265a88;
    color: #ffffff;
    opacity: 1; }

/*================================================
--> Element button_group
==================================================*/
#button-group .btn-group {
  margin-bottom: 10px; }

#button-group .btn-social {
  margin-bottom: 10px;
  margin-right: 5px; }

.btn-group {
  margin-right: 10px; }
  .btn-group .btn-secondary:before {
    content: '';
    position: absolute;
    right: 0;
    height: 100%; }
  .btn-group .btn-primary:before {
    content: '';
    position: absolute;
    right: 0;
    height: 100%;
    border-right: 1px solid #6fbecb; }
  .btn-group .btn-success:before {
    content: '';
    position: absolute;
    right: 0;
    height: 100%;
    border-right: 1px solid #41b226; }
  .btn-group .btn-info:before {
    content: '';
    position: absolute;
    right: 0;
    height: 100%;
    border-right: 1px solid #3b90c8; }
  .btn-group .btn-warning:before {
    content: '';
    position: absolute;
    right: 0;
    height: 100%;
    border-right: 1px solid #d4ad31; }
  .btn-group .btn-danger:before {
    content: '';
    position: absolute;
    right: 0;
    height: 100%;
    border-right: 1px solid #df5354; }
  .btn-group .btn-inverse:before {
    content: '';
    position: absolute;
    right: 0;
    height: 100%;
    border-right: 1px solid #222222; }
  .btn-group .btn.btn-secondary:before,
  .btn-group .btn:last-child:before {
    border-right: 0px solid rgba(20, 20, 20, 0.2); }

/* ================================================
--> Elements Bredcrumb style
================================================== */
.bredcrumb {
  opacity: 1;
  position: relative;
  padding: 50px 25px; }
  @media (min-width: 992px) {
    .bredcrumb {
      padding-top: 50px;
      padding-bottom: 50px; } }
  .bredcrumb ul {
    position: relative; }
    .bredcrumb ul li, .bredcrumb ul a {
      display: inline-block;
      font-size: 13px;
      color: #ffffff;
      padding: 7px 7px; }
      @media (min-width: 768px) {
        .bredcrumb ul li, .bredcrumb ul a {
          font-size: 15px; } }
    .bredcrumb ul li:after {
      position: absolute;
      content: '\f101';
      font-family: FontAwesome;
      top: 14px; }
    .bredcrumb ul li:last-child:after {
      display: none; }
    .bredcrumb ul li:first-child {
      padding-left: 0px; }
      .bredcrumb ul li:first-child a {
        padding-left: 0px; }
    .bredcrumb ul.pager {
      text-align: left; }
      @media (min-width: 768px) {
        .bredcrumb ul.pager {
          text-align: right; } }
  .bredcrumb h2 {
    color: #ffffff;
    font-size: 28px;
    line-height: 45px; }
    @media (min-width: 768px) {
      .bredcrumb h2 {
        font-size: 40px; } }
  .bredcrumb p {
    color: #ffffff;
    line-height: 1.5; }
    @media (min-width: 992px) {
      .bredcrumb p {
        line-height: 1; } }
  .bredcrumb p.devider.devider_narrow {
    max-width: 10%;
    margin-bottom: 0;
    margin-top: 15px;
    margin-left: 0;
    margin-right: auto; }

.display-single_element .bredcrumb h2 {
  padding-top: 0px !important; }

/*================================================
--> Element Dropdown
==================================================*/
.dropdown.btn-group .btn-primary {
  color: #ffffff; }
  .dropdown.btn-group .btn-primary:before {
    border: none; }

.dropdown.btn-group .btn-success {
  color: #ffffff; }
  .dropdown.btn-group .btn-success:before {
    border: none; }

.dropdown.btn-group .btn-danger {
  color: #ffffff; }
  .dropdown.btn-group .btn-danger:before {
    border: none; }

.dropdown.btn-group .btn-warning {
  color: #ffffff; }
  .dropdown.btn-group .btn-warning:before {
    border: none; }

.dropdown.btn-group .btn-info {
  color: #ffffff; }
  .dropdown.btn-group .btn-info:before {
    border: none; }

.dropdown-toggle {
  color: #222222; }
  .dropdown-toggle::after {
    display: inline-block;
    width: auto;
    height: auto;
    font-family: "FontAwesome";
    margin-left: 5px;
    vertical-align: middle;
    content: "\f107";
    border: 0px;
    color: inherit; }
  .dropdown-toggle.btn-primary {
    color: #ffffff; }
  .dropdown-toggle.focus, .dropdown-toggle:focus, .dropdown-toggle.active, .dropdown-toggle:active, .dropdown-toggle.active, .dropdown-toggle:hover {
    box-shadow: none; }

.dropdown.show .dropdown-toggle {
  box-shadow: none; }
  .dropdown.show .dropdown-toggle::after {
    content: "\f106"; }

.dropdown.show .btn-primary.dropdown-toggle {
  background-color: #265a88 !important;
  border: 1px solid #265a88 !important; }
  .dropdown.show .btn-primary.dropdown-toggle.focus, .dropdown.show .btn-primary.dropdown-toggle:focus, .dropdown.show .btn-primary.dropdown-toggle.active, .dropdown.show .btn-primary.dropdown-toggle:active, .dropdown.show .btn-primary.dropdown-toggle.active, .dropdown.show .btn-primary.dropdown-toggle:hover {
    background-color: #265a88 !important;
    border: 1px solid #265a88 !important; }

.dropdown.show .dropdown-megamenu {
  width: 100%; }
  .dropdown.show .dropdown-megamenu span.title {
    padding: 15px 0 5px;
    display: inline-block;
    font-weight: 700; }

.dropdown-menu {
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
  border: 0px; }
  .dropdown-menu .dropdown-item {
    border-bottom: 1px solid #e5e5e5;
    color: #848484; }
    .dropdown-menu .dropdown-item:hover {
      background-color: transparent;
      color: #222222; }
    .dropdown-menu .dropdown-item:last-child {
      border-bottom: 0px; }

.dropdown.btn-group .btn {
  margin-top: 10px; }

/* ================================================
--> Elements Alert style
================================================== */
.anim_item {
  position: relative;
  text-align: center;
  border: 1px solid #e5e5e5;
  padding: 60px 20px 30px;
  margin-top: 50px;
  margin-bottom: 50px; }
  .anim_item .icon {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 24px;
    border: 1px solid #e5e5e5;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%); }
  .anim_item h5 {
    margin-bottom: 10px; }

/* ================================================
--> Background Element style
================================================== */
.content-wrapper {
  position: relative;
  padding: 80px 50px; }
  .content-wrapper h1 {
    padding-bottom: 30px; }
  .content-wrapper p {
    padding-bottom: 30px; }

.bg-image {
  position: relative; }
  .bg-image.shadow:before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(50, 63, 65, 0.9); }

.bg-custom h1, .bg-custom p {
  color: #ffffff;
  padding-bottom: 30px; }

.bg-custom .btn {
  font-weight: 700; }

.bg-video {
  position: relative;
  overflow: hidden; }
  .bg-video video {
    display: block; }
    @media (min-width: 992px) {
      .bg-video video {
        width: 100%; } }
  .bg-video .overlay {
    position: absolute;
    z-index: 55;
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    top: 0;
    text-align: center;
    padding: 25px 50px; }
    @media (min-width: 768px) {
      .bg-video .overlay {
        padding: 80px 50px; } }

.overlay {
  position: relative; }

.pattern-01 {
  background-image: url("../img/patterns/pattern1.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center; }

/* ================================================
--> Elements Badge style
================================================== */
.display-single_element.element_badge p {
  margin-bottom: 10px;
  font-size: 18px;
  margin-top: 0px; }

.display-single_element.element_badge h1, .display-single_element.element_badge h2, .display-single_element.element_badge h3, .display-single_element.element_badge h4, .display-single_element.element_badge h5 {
  margin: 0 0 15px; }

.display-single_element.element_badge h1 {
  display: none; }
  @media (min-width: 768px) {
    .display-single_element.element_badge h1 {
      display: block; } }

.badge {
  padding: 4px 6px;
  border-radius: 2px; }

.badge-default {
  background-color: #323f41;
  color: #ffffff !important; }

.badge-primary {
  background-color: #265a88;
  color: #ffffff !important; }

.badge-success {
  background-color: #004387;
  color: #ffffff !important; }

.badge-info {
  background-color: #439fdb;
  color: #ffffff !important; }

.badge-warning {
  background-color: #eabf38;
  color: #ffffff !important; }

.badge-danger {
  background-color: #c12e2a;
  color: #ffffff !important; }

/* ================================================
--> Elements Gallery style
================================================== */
.hover-overlay h4 {
  color: #ffffff; }
  .hover-overlay h4:hover {
    color: #ffffff; }

.hover-overlay.overlay-flex {
  display: flex;
  flex-direction: column; }

i.overlay-icon {
  width: 70px;
  height: 70px;
  display: block;
  text-align: center;
  background-color: #ffffff;
  line-height: 70px;
  font-size: 18px;
  color: #265a88;
  border-radius: 50%;
  margin-bottom: 15px; }

/* ================================================
--> Elements list_group style
================================================== */
.list-group-item {
  border-radius: 0px !important;
  font-size: 15px;
  display: inline-block;
  padding: 15px 20px;
  color: #848484; }
  .list-group-item.active {
    background-color: #265a88;
    border-color: #265a88; }
    .list-group-item.active > a {
      color: #ffffff; }
  .list-group-item a {
    display: block;
    width: 100%; }
    .list-group-item a:hover .badge {
      color: #265a88; }
  .list-group-item i {
    font-size: 14px;
    margin-right: 8px; }
  .list-group-item .badge {
    color: #848484;
    float: right;
    font-size: 15px;
    font-weight: 400;
    -webkit-transition: color 0.3s ease;
    -moz-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease; }
    .list-group-item .badge.badge-default {
      color: #ffffff; }

.no-border .list-group-item {
  border: none;
  padding: 0px 0 20px; }

.list-group-item-primary {
  background-color: rgba(255, 255, 255, 0.75); }
  .list-group-item-primary a {
    color: #265a88; }

.list-group-item-success {
  background-color: rgba(255, 255, 255, 0.75); }
  .list-group-item-success a {
    color: #004387; }

.list-group-item-info {
  background-color: rgba(255, 255, 255, 0.75); }
  .list-group-item-info a {
    color: #439fdb; }

.list-group-item-warning {
  background-color: rgba(255, 255, 255, 0.75); }
  .list-group-item-warning a {
    color: #eabf38; }

.list-group-item-danger {
  background-color: rgba(255, 255, 255, 0.75); }
  .list-group-item-danger a {
    color: #c12e2a; }

/* ================================================
--> Elements image style
================================================== */
.image_container {
  width: 100%;
  overflow: hidden; }
  .image_container.image_circle {
    border-radius: 50%; }
    .image_container.image_circle img {
      max-width: unset; }
  .image_container img {
    max-width: 100%; }
    .image_container img.image_rounded {
      border-radius: 4px; }
    .image_container img.image_bordered {
      padding: 5px;
      border: 1px solid #e5e5e5; }

#image.element_single .display-single_element > p {
  margin-bottom: 0px; }

#image.element_single .display-single_element .image_container {
  margin-top: 30px; }

.image_container_right {
  float: right; }
  .image_container_right.image_circle {
    border-radius: 50%; }
    .image_container_right.image_circle img {
      max-width: unset; }
  .image_container_right img {
    max-width: 100%; }
    .image_container_right img.image_rounded {
      border-radius: 4px; }
    .image_container_right img.image_bordered {
      padding: 5px;
      border: 1px solid #e5e5e5; }

/* ================================================
--> Elements input_group style
================================================== */
.input-group .form-check-label {
  margin-bottom: 0px; }

.input-group .input-group-addon input[type=checkbox], .input-group .input-group-addon input[type=radio] {
  margin-top: 6px; }

.input-group .form-check {
  margin-bottom: 0px; }
  .input-group .form-check .checkbox-custom + .checkbox-custom-label:before, .input-group .form-check .radio-custom + .radio-custom-label:before {
    margin-right: 0px; }

label {
  font-size: 15px; }

.input-group-addon {
  border-radius: 0px;
  background: #f5f5f5;
  padding-left: 17px;
  padding-right: 17px;
  border: 1px solid #e5e5e5;
  font-size: 16px;
  color: #848484; }

input.form-control {
  font-size: 15px;
  color: #848484; }

.cr-addon .input-group {
  margin-bottom: 30px; }

/*==================================
card
====================================*/
#cards_element .card {
  margin-bottom: 30px; }
  #cards_element .card .display-single_element p {
    margin-bottom: -10px; }

.card {
  border: 0px;
  border-radius: 0px; }
  .card .card_img {
    position: relative;
    width: 100%;
    overflow: hidden; }
  .card .card-img {
    border-radius: 0px; }
  .card .card-title {
    margin: 0px;
    padding: 15px 0 10px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .card .card-title:hover {
      color: #265a88; }
  .card .card-block {
    padding: 0; }
    .card .card-block p.card-text {
      padding: 15px 0px; }
    .card .card-block > span {
      padding: 0 5px; }
  .card.img-mid .card_img {
    padding: 20px 0 20px; }
  .card.img-mid .card-title {
    padding-top: 0px; }
  .card.img-mid .card-block {
    padding: 0; }
    .card.img-mid .card-block p.card-text {
      padding: 0px 0px 15px; }
  .card.img-bottom .card_img {
    padding-top: 25px; }
  .card.img-bottom .card-title {
    padding-top: 0px !important; }
  .card .card-img-overlay p.card-text {
    color: #ffffff; }
  .card.card-inverse:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(20, 20, 20, 0.7); }
  .card.card-style2 .card-block, .card.card-style3 .card-block {
    padding: 15px 0px 30px;
    position: relative;
    display: inline-block; }
    .card.card-style2 .card-block:before, .card.card-style3 .card-block:before {
      position: absolute;
      content: '';
      width: 50px;
      bottom: 0;
      height: 3px;
      background-color: #ccc; }
  .card.card-style2:hover .card-block:before, .card.card-style3:hover .card-block:before {
    background-color: #265a88; }
  .card.card-style3 .card-block {
    margin: -40px auto 0;
    background: #ffffff;
    border: 1px solid transparent;
    padding: 30px;
    padding-bottom: 50px;
    width: 85%;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .card.card-style3 .card-block:before {
      bottom: 35px;
      height: 2px;
      background-color: #e5e5e5; }
  .card.card-style3:hover .card-block {
    border: 1px solid #e5e5e5; }
  .card ul.list-inline li {
    margin-right: 10px; }
    .card ul.list-inline li:last-child {
      margin-right: 0px; }
    .card ul.list-inline li i {
      margin-right: 5px; }

.date-holder {
  position: absolute;
  top: 15px;
  font-size: 14px;
  color: #fff;
  width: 65px;
  height: 65px;
  text-align: center;
  padding-top: 13px;
  left: 15px; }
  .date-holder span {
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    display: block; }
  .date-holder::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-top: 0px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 15px solid #265a88;
    bottom: -20px;
    left: 0; }

/* ================================================
--> Elements collapse style
================================================== */
.collapse-card.card {
  margin: 10px 0; }

.collapse-card .card-header {
  padding: 0px;
  border-radius: 0px;
  border: none; }
  .collapse-card .card-header h5 {
    position: relative; }
    .collapse-card .card-header h5 a:after {
      top: 50%;
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%); }
  .collapse-card .card-header a {
    display: block;
    padding: 20px;
    line-height: 24px;
    background-color: #265a88;
    color: #ffffff;
    border: 1px solid #265a88; }
  .collapse-card .card-header a[data-toggle=collapse]:after {
    font-family: FontAwesome;
    font-style: normal;
    speak: none;
    display: inline-block;
    content: '\f106';
    float: right;
    line-height: 1;
    width: 22px;
    font-size: 20px;
    text-align: center;
    position: absolute;
    right: 15px; }
  .collapse-card .card-header a.collapsed {
    line-height: 18px;
    color: #222222;
    background-color: #f5f5f5;
    border: 1px solid #e5e5e5; }
  .collapse-card .card-header a[data-toggle=collapse].collapsed:after {
    font-family: FontAwesome;
    font-style: normal;
    speak: none;
    display: inline-block;
    content: '\f107'; }

.collapse-card .card-block {
  border: 1px solid #e5e5e5;
  border-top: 0px;
  padding: 30px 20px;
  font-size: 15px;
  line-height: 26px; }

.icon-left .card-header {
  padding: 0px;
  border-radius: 0px;
  border: none; }
  .icon-left .card-header a {
    display: block;
    padding: 20px;
    line-height: 24px;
    padding-left: 50px; }
  .icon-left .card-header.header-inverse a {
    background-color: #ffffff;
    color: #265a88;
    border: 1px solid #265a88; }
  .icon-left .card-header a[data-toggle=collapse]:after {
    font-family: FontAwesome;
    font-style: normal;
    speak: none;
    display: inline-block;
    content: '\f106';
    float: left;
    line-height: 1;
    width: 22px;
    margin-right: 10px;
    font-size: 20px;
    text-align: center;
    position: absolute;
    left: 15px; }
  .icon-left .card-header a.collapsed {
    line-height: 18px;
    color: #222222 !important;
    background-color: #f5f5f5;
    border: 1px solid #e5e5e5 !important; }
  .icon-left .card-header a[data-toggle=collapse].collapsed:after {
    font-family: FontAwesome;
    font-style: normal;
    speak: none;
    display: inline-block;
    content: '\f107'; }

/* ================================================
--> Elements countdown style
================================================== */
.count-down {
  position: relative; }
  .count-down h2 {
    font-size: 40px;
    margin-bottom: 30px; }
  .count-down .simple_timer {
    width: 100%;
    margin: 0 auto;
    color: #222222; }
  .count-down .table-cell {
    margin: 15px 15px 0 0;
    display: inline-block !important;
    text-align: center; }
    .count-down .table-cell .tab-val {
      width: 100px;
      height: 100px;
      line-height: 100px;
      font-size: 40px;
      display: inline-block !important;
      background: #f8f8f8;
      border: 1px solid #e5e5e5;
      font-weight: 700; }
    .count-down .table-cell .tab-metr {
      display: none; }
  .count-down.titled .table-cell {
    position: relative;
    height: 130px; }
    .count-down.titled .table-cell .tab-val {
      width: 130px;
      height: 130px;
      background-color: transparent; }
    .count-down.titled .table-cell .tab-metr {
      display: block !important;
      margin-top: -50px;
      line-height: 22px;
      font-size: 16px;
      text-transform: capitalize;
      color: #222222; }
  .count-down.circled .tab-val {
    border-radius: 50%; }
  .count-down.colored-bg {
    padding: 80px 50px 80px; }
    .count-down.colored-bg .tab-val {
      width: 130px;
      height: 130px;
      background-color: #ffffff !important;
      border: none; }
  .count-down.bg-image {
    position: relative;
    padding: 80px 50px 80px; }
    .count-down.bg-image .tab-val {
      width: 130px;
      height: 130px;
      background-color: #ffffff !important;
      border: none; }
    .count-down.bg-image.shadow:before {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(53, 63, 65, 0.9); }

.fun-text {
  position: relative;
  display: block;
  margin-top: 15px; }
  @media (min-width: 768px) {
    .fun-text {
      display: inline-block;
      margin-right: 0px; } }
  .fun-text:last-child {
    margin-right: 0px; }
  @media (min-width: 768px) {
    .fun-text {
      margin-right: 80px; } }
  .fun-text span {
    font-size: 50px;
    color: #222222;
    font-style: italic;
    font-weight: 700;
    padding: 20px 0;
    display: inline-block; }
  .fun-text p {
    font-size: 16px;
    line-height: 22px;
    color: #222222; }

.bg-primary .fun-text span, .bg-primary .fun-text p {
  color: #ffffff; }

/* ================================================
--> Elements carousel style
================================================== */
.carousel-item {
  height: 300px; }
  @media (min-width: 768px) {
    .carousel-item {
      height: 350px; } }
  @media (min-width: 992px) {
    .carousel-item {
      height: 400px; } }
  .carousel-item:hover .carousel-control {
    opacity: 0;
    visibility: hidden; }

.carousel-item img {
  width: 100%;
  height: 100%; }

.carousel-caption {
  position: absolute;
  right: unset;
  bottom: auto;
  left: 5%;
  z-index: 10;
  padding: 0px;
  text-align: left;
  top: 50%;
  transform: translateY(-50%); }
  .carousel-caption.text-center {
    width: 100%;
    top: 50%;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .carousel-caption h1 {
    font-size: 25px;
    font-weight: 700;
    font-style: italic;
    color: #ffffff;
    line-height: 35px; }
    @media (min-width: 768px) {
      .carousel-caption h1 {
        font-size: 35px;
        line-height: 55px; } }
    @media (min-width: 992px) {
      .carousel-caption h1 {
        font-size: 40px;
        line-height: 55px; } }
  .carousel-caption p {
    font-size: 14px;
    color: #ffffff;
    padding: 20px 0 25px;
    line-height: 24px; }
    @media (min-width: 768px) {
      .carousel-caption p {
        font-size: 16px;
        line-height: 26px;
        padding: 25px 0 30px; } }
    @media (min-width: 992px) {
      .carousel-caption p {
        padding: 35px 0 40px; } }
  .carousel-caption .btn {
    height: 35px;
    line-height: 35px; }

.carousel-indicators li {
  max-width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  background: transparent;
  position: relative; }
  .carousel-indicators li.active {
    background-color: #265a88;
    border: 2px solid #265a88; }
    .carousel-indicators li.active:before {
      position: absolute;
      top: 4px;
      left: 4px;
      display: inline-block;
      content: "";
      background: white;
      width: 8px;
      height: 8px;
      border-radius: 50%; }

.carousel-indicators.right_indicators {
  right: 0;
  bottom: auto;
  left: auto;
  padding-left: 0;
  margin-right: 5%;
  margin-left: 0;
  list-style: none;
  flex-direction: column;
  top: 50%;
  transform: translateY(-50%); }
  .carousel-indicators.right_indicators li {
    width: 20px;
    height: 20px;
    margin: 3px 0; }

.carousel-control {
  opacity: 0;
  visibility: hidden; }
  .carousel-control span.icon-prev, .carousel-control span.icon-next {
    position: absolute;
    top: 50%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    width: 45px;
    height: 45px;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.302);
    text-align: center;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .carousel-control span.icon-prev .fa, .carousel-control span.icon-next .fa {
      font-size: 30px;
      color: #ffffff;
      line-height: 45px; }
    .carousel-control span.icon-prev:hover, .carousel-control span.icon-prev:focus, .carousel-control span.icon-next:hover, .carousel-control span.icon-next:focus {
      background: #265a88;
      border-color: #265a88; }
  .carousel-control .icon-prev {
    left: 15px; }
  .carousel-control .icon-next {
    right: 15px; }

.carousel:hover .carousel-control {
  opacity: 1;
  visibility: visible; }

.testimonial-carousel {
  position: relative;
  padding-bottom: 50px; }
  .testimonial-carousel .carousel-indicators {
    bottom: 0px; }
    .testimonial-carousel .carousel-indicators li {
      border-color: #e5e5e5; }
      .testimonial-carousel .carousel-indicators li.active {
        background-color: #265a88;
        border: 2px solid #265a88; }
  .testimonial-carousel p {
    margin: 30px 5px; }
  .testimonial-carousel h5 {
    margin-bottom: 10px; }
  .testimonial-carousel .carousel-item {
    height: auto; }

.client-img {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden; }

/*==============================================
Carousel with Slick Styles 
===============================================*/
button.slick-arrow {
  border: 2px solid #ffffff;
  width: 50px;
  height: 50px;
  z-index: 55; }
  @media (min-width: 768px) {
    button.slick-arrow {
      width: 40px;
      height: 40px; } }
  @media (min-width: 1200px) {
    button.slick-arrow {
      width: 50px;
      height: 50px; } }

.slick-prev:before {
  content: '\f060'; }

.slick-next:before {
  content: '\f061'; }

.slick-prev:before, .slick-next:before {
  font-family: 'FontAwesome';
  font-size: 14px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media (min-width: 768px) {
  .slick-prev {
    left: 0px; } }

@media (min-width: 768px) {
  .slick-next {
    right: 0px; } }

.arrow-shade button.slick-arrow {
  opacity: 0;
  visibility: hidden;
  border: 1px solid #ffffff;
  width: 50px;
  height: 50px;
  z-index: 55;
  background: rgba(255, 255, 255, 0.302);
  text-align: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .arrow-shade button.slick-arrow .fa {
    font-size: 30px;
    color: #ffffff;
    line-height: 45px; }
  .arrow-shade button.slick-arrow:hover, .arrow-shade button.slick-arrow:focus {
    background: #265a88;
    border-color: #265a88; }
  @media (min-width: 768px) {
    .arrow-shade button.slick-arrow.slick-prev {
      left: 25px; } }
  @media (min-width: 768px) {
    .arrow-shade button.slick-arrow.slick-next {
      right: 25px; } }

.arrow-shade:hover button.slick-arrow, .arrow-shade:focus button.slick-arrow {
  opacity: 1;
  visibility: visible; }

.brand_carousel {
  padding: 35px 0; }
  .brand_carousel .slick-initialized .slick-slide {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100px;
    justify-content: center; }
  .brand_carousel .brand_img {
    text-align: center; }
    @media (min-width: 768px) {
      .brand_carousel .brand_img {
        overflow: hidden; } }
    .brand_carousel .brand_img img {
      display: inline-block;
      max-width: 100%; }
  @media (min-width: 768px) {
    .brand_carousel .carousel_row .slick-prev {
      left: 25px; } }
  @media (min-width: 768px) {
    .brand_carousel .carousel_row .slick-next {
      right: 25px; } }
  @media (min-width: 768px) {
    .brand_carousel .slick-slider {
      padding: 0 50px; } }
  @media (min-width: 992px) {
    .brand_carousel .slick-slider {
      padding: 0 100px; } }

.image_carousel .element_row {
  margin: 0 -15px; }
  .image_carousel .element_row .image_single {
    padding: 0 15px; }

.product-carousel .element_row {
  margin: 0 -15px; }
  .product-carousel .element_row .card {
    padding: 0 15px;
    margin-bottom: 30px; }

.product-carousel .slick-dots {
  bottom: -30px; }
  .product-carousel .slick-dots li button {
    border: 2px solid #e5e5e5; }

.slick-dots {
  bottom: 35px; }
  .slick-dots li button {
    border: 2px solid #ffffff;
    opacity: 0.7;
    background: transparent;
    border-radius: 50%; }
    .slick-dots li button:before {
      color: transparent; }
  .slick-dots li.slick-active button {
    background-color: #265a88;
    border: none;
    opacity: 1; }
    .slick-dots li.slick-active button:before {
      opacity: 1;
      font-size: 8px;
      line-height: 22px;
      color: #ffffff; }

/* ================================================
--> Elements form style
================================================== */
.form-group {
  margin-bottom: 30px; }

select.form-control:not([size]):not([multiple]) {
  height: unset; }

input.form-control, textarea.form-control, select.form-control {
  border-radius: 0px;
  font-size: 14px;
  padding: 13px 20px;
  line-height: 24px;
  border: 1px solid #e5e5e5; }
  input.form-control::-webkit-input-placeholder, textarea.form-control::-webkit-input-placeholder, select.form-control::-webkit-input-placeholder {
    color: #848484; }
  input.form-control:-moz-placeholder, textarea.form-control:-moz-placeholder, select.form-control:-moz-placeholder {
    color: #848484; }
  input.form-control::-moz-placeholder, textarea.form-control::-moz-placeholder, select.form-control::-moz-placeholder {
    color: #848484; }
  input.form-control:-ms-input-placeholder, textarea.form-control:-ms-input-placeholder, select.form-control:-ms-input-placeholder {
    color: #848484; }
  input.form-control.focus, input.form-control:focus, input.form-control.active, input.form-control:active, textarea.form-control.focus, textarea.form-control:focus, textarea.form-control.active, textarea.form-control:active, select.form-control.focus, select.form-control:focus, select.form-control.active, select.form-control:active {
    border-color: #265a88; }

.text_input .form-group {
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .text_input .form-group {
      margin-bottom: 20px; } }
  .text_input .form-group label {
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    color: #222222;
    margin-top: 8px;
    text-align: left; }
    @media (min-width: 768px) {
      .text_input .form-group label {
        text-align: right; } }
    @media (min-width: 992px) {
      .text_input .form-group label {
        font-size: 16px; } }

.form-group .helpText {
  position: absolute;
  top: 18px;
  right: 25px; }

.selectOptions {
  margin-bottom: 0px; }
  .selectOptions .sbHolder {
    background-color: transparent;
    border: none;
    margin: 0;
    border-radius: 0;
    border: 1px solid #e5e5e5; }
  .selectOptions .sbHolder, .selectOptions .sbSelector {
    width: 100% !important;
    height: 50px !important;
    line-height: 50px; }
  .selectOptions .sbOptions {
    width: 100% !important;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    right: 0 !important;
    border-radius: 0;
    z-index: 100; }
    .selectOptions .sbOptions a {
      color: #848484;
      font-size: 13px;
      padding: 13px 17px;
      text-transform: uppercase; }
      .selectOptions .sbOptions a:hover {
        color: #265a88;
        background-color: transparent; }
    .selectOptions .sbOptions .sbToggle:hover, .selectOptions .sbOptions .sbToggle:focus, .selectOptions .sbOptions .sbToggle:active {
      color: inherit; }
  .selectOptions .sbHolder > a.sbToggle:after {
    position: absolute;
    content: '\f078';
    font-family: 'FontAwesome';
    top: 0px;
    right: 10px;
    font-size: 10px; }
  .selectOptions .sbHolder > a.sbToggle.sbToggleOpen:after {
    content: '\f077'; }

.form-check-input {
  margin-left: 4px;
  margin-top: 6px;
  padding: 5px;
  opacity: 0; }

.form-check-label {
  padding-left: 0px;
  margin-bottom: 25px;
  font-size: 15px;
  cursor: default;
  color: #222222; }

.checkbox-custom + .checkbox-custom-label:before, .radio-custom + .radio-custom-label:before {
  content: '';
  background: #ffffff;
  border: 1px solid #dedede;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  padding: 0;
  margin-right: 10px;
  text-align: center;
  line-height: 20px; }

.checkbox-custom:checked + .checkbox-custom-label:before {
  content: "\f00c";
  font-family: 'FontAwesome';
  color: #ffffff;
  background: #265a88;
  border: 0px solid #265a88; }

.radio-custom + .radio-custom-label:before {
  border-radius: 50%; }

.radio-custom:checked + .radio-custom-label:before {
  content: "\f111";
  font-size: 9px;
  font-family: 'FontAwesome';
  color: #ffffff;
  background: #265a88;
  border: 0px solid #265a88; }

span.or {
  font-size: 15px;
  padding: 15px 0;
  display: block;
  color: #222222; }

.checkbox-custom:checked + .checkbox-custom-label.default-checked:before,
.radio-custom:checked + .radio-custom-label.default-checked:before {
  background-color: #e5e5e5 !important;
  color: #848484 !important; }

.checkbox-custom:checked + .checkbox-custom-label.disable-checked:before,
.radio-custom:checked + .radio-custom-label.disable-checked:before {
  background: #f2f2f2 !important;
  color: rgba(255, 255, 255, 0.5) !important; }

h3.form_title {
  display: block;
  font-size: 25px;
  padding: 25px 30px;
  text-transform: capitalize;
  background: #f5f5f5; }

form.register {
  border: 1px solid #e5e5e5;
  border-top: 0px;
  padding: 25px 30px; }
  form.register .form-check label.checkbox-custom-label {
    color: #222222;
    margin-bottom: 0px; }
    form.register .form-check label.checkbox-custom-label:before {
      width: 16px;
      height: 16px;
      line-height: 16px; }
  form.register .form-check .checkbox-custom:checked + .checkbox-custom-label:before {
    font-size: 12px; }
  form.register .btn-link {
    color: #265a88;
    text-decoration: none;
    font-size: 15px;
    display: inline-block; }
  form.register span.or {
    position: relative;
    font-weight: 700; }
    form.register span.or:before {
      content: '';
      position: absolute;
      left: 0;
      width: 47%;
      background: #e5e5e5;
      height: 1px;
      top: 50%; }
    form.register span.or:after {
      content: '';
      position: absolute;
      right: 0;
      width: 47%;
      background: #e5e5e5;
      height: 1px;
      top: 50%; }
  form.register .btn {
    font-weight: 700;
    margin-bottom: 20px; }
    form.register .btn.btn-facebook, form.register .btn.btn-twitter {
      margin-top: 20px;
      margin-bottom: 10px;
      text-transform: unset; }

p.form-footer {
  margin: 0;
  text-align: center;
  padding: 20px 0;
  border: 1px solid #e5e5e5;
  border-top: 0px;
  font-size: 15px; }

.input-icon {
  position: relative; }
  .input-icon .input-group-addon {
    background-color: transparent;
    border: 0px solid transparent;
    font-size: 16px;
    color: #848484;
    position: absolute;
    top: 0;
    padding: 0px;
    z-index: 55;
    margin: 17px 0px;
    left: 15px; }
  .input-icon input {
    padding-left: 40px; }
  .input-icon.col-md-12 .input-group-addon {
    left: 27px; }

.form-inline {
  justify-content: space-between; }
  .form-inline .input-group {
    width: 38%;
    position: relative; }
  .form-inline .uname, .form-inline .email, .form-inline .password {
    position: relative; }

/* ================================================
--> Elements Progressbar style
================================================== */
.progress {
  border-radius: 0px; }

.progress.bordered {
  height: 20px;
  align-items: center;
  background: transparent;
  padding: 0 5px;
  line-height: 10px;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 0px; }
  .progress.bordered .progress-bar {
    background-color: #265a88;
    border-radius: 0px;
    height: 10px; }

.progressText {
  font-size: 15px; }

.progress-bar {
  background-color: #265a88;
  text-align: left;
  border-radius: 0px; }

.tooltip {
  position: relative;
  float: right; }

.tooltip > .tooltip-inner {
  background-color: transparent;
  padding: 8px 15px;
  color: #848484;
  font-size: 15px; }
  .tooltip > .tooltip-inner:before {
    display: none; }

.progress-bar-primary {
  background-color: #265a88; }

.progress-bar-success {
  background-color: #004387; }

.progress-bar-info {
  background-color: #439fdb; }

.progress-bar-warning {
  background-color: #eabf38; }

.progress-bar-danger {
  background-color: #c12e2a; }

.circle1 {
  margin-top: 30px;
  position: relative; }
  .circle1 strong {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    font-size: 30px;
    margin-top: -20px;
    color: #222222; }
  .circle1 span.text {
    display: block;
    color: #222222;
    font-size: 16px;
    margin-top: 15px;
    text-transform: uppercase; }

/*=== 1.BANNER SLIDER ===*/
.boxed .main-slider {
  overflow: hidden; }
  @media (min-width: 768px) {
    .boxed .main-slider {
      margin-top: 15px; } }
  @media (min-width: 992px) {
    .boxed .main-slider {
      margin-top: 15px; } }
  @media (min-width: 1200px) {
    .boxed .main-slider {
      margin-top: 80px; } }

.main-slider.container {
  padding: 0px; }
  @media (min-width: 768px) {
    .main-slider.container {
      padding-left: 15px;
      padding-right: 15px; } }

.main-slider .slick-slider {
  margin-bottom: 0px;
  padding: 0; }

.main-slider .slide {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  @media (min-width: 320px) {
    .main-slider .slide {
      height: 250px; } }
  @media (min-width: 768px) {
    .main-slider .slide {
      height: 400px; } }
  @media (min-width: 992px) {
    .main-slider .slide {
      height: 600px; } }
  @media (min-width: 320px) {
    .main-slider .slide.slide1, .main-slider .slide.slide2, .main-slider .slide.slide4 {
      background-position: right center; } }
  @media (min-width: 992px) {
    .main-slider .slide.slide1, .main-slider .slide.slide2, .main-slider .slide.slide4 {
      background-position: center; } }
  @media (min-width: 320px) {
    .main-slider .slide.slide3 {
      background-position: 20% center; } }
  @media (min-width: 992px) {
    .main-slider .slide.slide3 {
      background-position: center; } }

@media (min-width: 992px) {
  .version-3 .main-slider .slide {
    height: 690px; } }

.main-slider .slide h1,
.main-slider .slide h2,
.main-slider .slide h3,
.main-slider .slide h4,
.main-slider .slide h5,
.main-slider .slide h6,
.main-slider .slide p,
.main-slider .slide .h1,
.main-slider .slide .h2,
.main-slider .slide .h3,
.main-slider .slide .h4,
.main-slider .slide .h5,
.main-slider .slide .h6 {
  display: block;
  margin-bottom: 0; }

@media (min-width: 768px) {
  .main-slider .common-inner {
    margin-left: 30px; } }

.main-slider .common-inner .h1 {
  font-family: 'Playfair Display', serif;
  color: #ffffff;
  font-style: italic;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0; }
  @media (min-width: 320px) {
    .main-slider .common-inner .h1 {
      font-size: 30px;
      line-height: 34px;
      margin: 0; } }
  @media (min-width: 768px) {
    .main-slider .common-inner .h1 {
      font-size: 50px;
      line-height: 50px;
      margin: 0 0 10px; } }
  @media (min-width: 992px) {
    .main-slider .common-inner .h1 {
      font-size: 50px;
      line-height: 55px;
      margin: 0 0 10px; } }

.main-slider .common-inner .h4 {
  color: #ffffff;
  margin-bottom: 10px;
  margin-top: 0;
  font-size: 13px;
  margin-bottom: 0; }
  @media (min-width: 768px) {
    .main-slider .common-inner .h4 {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 10px; } }

.main-slider .common-inner a.btn {
  text-align: center;
  background-color: #265a88;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
  padding: 0 25px; }
  @media (min-width: 768px) {
    .main-slider .common-inner a.btn {
      font-size: 14px;
      height: 50px;
      line-height: 50px;
      padding: 0 30px; } }

@media (min-width: 320px) {
  .main-slider .common-inner .img-responsive {
    margin-bottom: 5px;
    width: 65px; } }

@media (min-width: 768px) {
  .main-slider .common-inner .img-responsive {
    width: 95px;
    margin-bottom: 30px; } }

@keyframes drop {
  0% {
    opacity: 0;
    transform: scale(1.2); }
  100% {
    opacity: 1;
    transform: scale(1); } }

.drop {
  -webkit-animation-name: drop;
  animation-name: drop;
  -webkit-transform-origin: center;
  transform-origin: center; }

/*============================================
 Event Carousel with Slick Styles 
 =============================================*/
.event_carousel {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%; }
  .event_carousel:before {
    position: absolute;
    content: '';
    left: 0px;
    right: 0px;
    top: 0;
    bottom: 0;
    background: rgba(50, 63, 65, 0.9); }
  .event_carousel .event_details {
    position: relative;
    padding: 30px; }
    @media (min-width: 768px) {
      .event_carousel .event_details {
        padding: 30px 20px; } }
    .event_carousel .event_details span {
      color: #ffffff;
      font-size: 17px;
      padding-bottom: 20px; }
    .event_carousel .event_details p {
      padding-top: 20px;
      color: #ffffff; }

/* ================================================
--> Elements Nav style
================================================== */
ul.nav .nav-item .nav-link {
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase; }

ul.nav .nav-item.active .nav-link {
  color: #265a88; }

ul.nav-tabs .nav-item .nav-link {
  border-radius: 0px;
  padding: 10px 25px;
  background-color: #f5f5f5;
  margin-right: 0px;
  border: 1px solid #e5e5e5;
  border-bottom: 0px; }
  ul.nav-tabs .nav-item .nav-link.active {
    border-top-color: #265a88;
    border-top-width: 2px;
    background-color: #ffffff; }
  @media (min-width: 768px) {
    ul.nav-tabs .nav-item .nav-link {
      margin-right: 5px; } }

ul.nav-tabs .nav-item:last-child .nav-link {
  margin-right: 0px; }

ul.nav-tabs.no-bg .nav-item .nav-link {
  background-color: transparent;
  border: 1px solid transparent; }
  ul.nav-tabs.no-bg .nav-item .nav-link.active {
    border-color: #265a88;
    background-color: #265a88;
    color: #ffffff; }

ul.nav-tabs.nav-pills .nav-item .nav-link.active {
  border-color: #265a88;
  background-color: #265a88;
  color: #ffffff; }

/* ================================================
--> Elements Navbar style
================================================== */
header {
  background-color: #ffffff; }

/*============= TOPBAR STYLE ============*/
.topbar-content {
  position: relative;
  display: inline-block;
  margin-right: 10px; }
  @media (min-width: 992px) {
    .topbar-content {
      margin-right: 30px; } }

.topbar {
  display: none; }
  @media (min-width: 992px) {
    .topbar {
      display: block; } }
  .topbar i {
    color: #265a88;
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 17px; }
  .topbar p, .topbar a {
    color: #ffffff;
    display: inline-block;
    padding-left: 20px;
    font-size: 14px;
    line-height: 1;
    margin-top: 17px; }
  .topbar a.btn {
    margin-top: 0px; }

.boxed-menu .topbar {
  padding: 20px 0; }
  .boxed-menu .topbar i {
    top: 0;
    display: inline-block;
    position: relative;
    width: 45px;
    border: 1px solid #e5e5e5;
    height: 45px;
    margin-right: 10px;
    line-height: 45px;
    text-align: center;
    border-radius: 50%; }
  .boxed-menu .topbar p, .boxed-menu .topbar a {
    display: inline-block;
    color: #848484;
    padding-left: 0px; }
  .boxed-menu .topbar .btn {
    color: #ffffff;
    padding: 0 30px; }

/*============= HEADER STYLE ============*/
@media (min-width: 480px) {
  .boxed header {
    max-width: 520px;
    margin: 0 auto; } }

@media (min-width: 768px) {
  .boxed header {
    background-color: transparent;
    max-width: 768px;
    margin: 0 auto; } }

@media (min-width: 992px) {
  .boxed header {
    background-color: transparent;
    max-width: 970px;
    margin: 0 auto; } }

@media (min-width: 1200px) {
  .boxed header {
    background-color: transparent;
    max-width: 100%;
    margin: 0 auto; } }

@media (min-width: 1200px) {
  .boxed header.header-transparent {
    background-color: transparent;
    max-width: 1260px;
    margin: 0 auto; } }

.main-nav {
  z-index: 5;
  position: unset; }
  .main-nav.navbar-sticky {
    -webkit-box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08); }
  @media (min-width: 768px) {
    .main-nav {
      position: relative;
      padding: 0 !important; } }
  .main-nav .navbar-brand {
    padding-top: 10px;
    margin: 0px;
    padding-bottom: 10px; }
    .main-nav .navbar-brand img {
      max-width: 150px; }
    @media (min-width: 768px) {
      .main-nav .navbar-brand {
        margin-top: 0; }
        .main-nav .navbar-brand img {
          width: auto;
          max-width: 150px; } }
    @media (min-width: 992px) {
      .main-nav .navbar-brand {
        width: 230px;
        overflow: visible; }
        .main-nav .navbar-brand img {
          width: auto;
          max-width: 100%; } }
  .main-nav .navbar-toggler {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    border-radius: 0px;
    position: absolute;
    right: 0px;
    top: 5px;
    transition: all 0.5s ease-in-out;
    margin-top: 0px;
    z-index: 5550; }
    .main-nav .navbar-toggler .burger-menu {
      width: 40px;
      height: 40px;
      display: inline-block;
      font-size: 24px;
      line-height: 40px;
      color: #ffffff;
      background: #265a88;
      text-align: center; }
    .main-nav .navbar-toggler.clicked {
      position: fixed;
      top: 13px;
      right: 270px; }
    .main-nav .navbar-toggler.active, .main-nav .navbar-toggler:focus {
      outline: none; }
  .main-nav .navbar-collapse {
    overflow-y: auto;
    position: fixed;
    top: 0;
    padding: 0;
    min-width: 260px;
    border-top: none;
    margin-top: 0px;
    display: block;
    position: fixed;
    z-index: 5551;
    right: -100%;
    height: 400px;
    transition: all 0.5s ease-in-out; }
    @media (min-width: 768px) {
      .main-nav .navbar-collapse {
        position: relative;
        overflow-y: unset;
        right: 0;
        height: auto; } }
  .main-nav .dropdown-toggle::after {
    visibility: visible;
    position: absolute;
    right: 0; }
    @media (min-width: 768px) {
      .main-nav .dropdown-toggle::after {
        visibility: visible; } }
  .main-nav .navbar-collapse.show {
    overflow-y: auto;
    right: 0; }
  .main-nav .navbar-nav {
    position: relative;
    z-index: 5551;
    background: #ffffff;
    padding: 30px 15px; }
    .main-nav .navbar-nav:after {
      content: "";
      display: table;
      clear: both; }
    @media (min-width: 768px) {
      .main-nav .navbar-nav {
        padding: 0;
        position: static;
        background: transparent; } }
  .main-nav .nav-item a {
    text-transform: capitalize;
    font-family: 'Montserrat', sans-serif; }
  .main-nav .nav-item a.nav-link {
    color: #222222;
    padding: 0px;
    font-size: 14px;
    position: relative;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    margin: 10px 15px; }
    @media (min-width: 768px) {
      .main-nav .nav-item a.nav-link {
        padding: 20px 0px;
        margin: 0px 10px;
        font-size: 12px; }
        .main-nav .nav-item a.nav-link:before {
          content: '';
          overflow: hidden;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          display: block;
          margin: 0 auto;
          width: 0;
          height: 2px;
          background-color: #ffffff;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -ms-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out; } }
    @media (min-width: 768px) and (min-width: 768px) {
      .main-nav .nav-item a.nav-link:before {
        bottom: 30px;
        border-bottom: 2px solid #222222; } }
    @media (min-width: 768px) {
        .main-nav .nav-item a.nav-link:after {
          content: ''; } }
    @media (min-width: 992px) {
      .main-nav .nav-item a.nav-link {
        padding: 40px 0px;
        margin: 0px 15px;
        font-size: 14px; } }
    @media (min-width: 1200px) {
      .main-nav .nav-item a.nav-link {
        padding: 40px 0px;
        margin: 0px 18px;
        font-size: 14px; } }
    .main-nav .nav-item a.nav-link:hover, .main-nav .nav-item a.nav-link:focus {
      color: #222222; }
      @media (min-width: 768px) {
        .main-nav .nav-item a.nav-link:hover, .main-nav .nav-item a.nav-link:focus {
          color: #222222; } }
      .main-nav .nav-item a.nav-link:hover:before, .main-nav .nav-item a.nav-link:focus:before {
        width: 100%;
        opacity: 0.8; }
      @media (min-width: 768px) {
        .main-nav .nav-item a.nav-link:hover:before, .main-nav .nav-item a.nav-link:hover:after, .main-nav .nav-item a.nav-link:focus:before, .main-nav .nav-item a.nav-link:focus:after {
          opacity: 0.8; } }
  .main-nav .nav-item.active a.nav-link:before {
    opacity: 1;
    width: 100%; }
  .main-nav .nav-item.active a.nav-link:after {
    opacity: 1; }

.show > a.nav-link {
  color: #265a88; }
  .show > a.nav-link:hover, .show > a.nav-link:focus {
    color: #265a88; }

.show > .dropdown-menu {
  visibility: visible;
  -moz-transform: translateY(0%);
  -o-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  opacity: 1;
  z-index: 1;
  transition-delay: 0s, 0s, 0.3s; }

.dropdown-menu {
  top: 100%;
  min-width: 230px;
  margin-top: 0px;
  width: auto;
  padding: 0px 20px 0px;
  border-radius: 0px;
  box-shadow: none; }
  .dropdown-menu a {
    padding: 5px 0;
    font-size: 14px;
    display: block;
    color: #848484; }
    @media (min-width: 768px) {
      .dropdown-menu a {
        padding: 15px 0;
        color: #545454; }
        .dropdown-menu a:hover {
          color: #222222; } }
  @media (min-width: 768px) {
    .dropdown-menu {
      -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
      -moz-transform: translateY(-30px);
      -o-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px);
      background-color: #ffffff;
      margin-top: 0px;
      padding: 20px;
      display: block;
      opacity: 0;
      z-index: -1;
      visibility: hidden;
      -webkit-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
      -moz-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
      -ms-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
      -o-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
      transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s; } }
  @media (min-width: 992px) {
    .dropdown-menu {
      margin-top: 0px;
      padding: 0px 20px 0px; } }
  .dropdown-menu li {
    padding: 0; }
    @media (min-width: 768px) {
      .dropdown-menu li {
        border-bottom: 1px solid #e5e5e5; }
        .dropdown-menu li:last-child {
          border-bottom: 0px; } }
  .dropdown-menu li.dropdown .dd_second {
    border: none;
    margin-top: 0px;
    margin-left: 0px;
    box-shadow: none;
    border-left: 0px solid transparent;
    border-top: 0px solid transparent;
    background: transparent; }
    @media (min-width: 768px) {
      .dropdown-menu li.dropdown .dd_second {
        top: 0 !important;
        left: -100% !important;
        margin-top: -23px;
        box-shadow: none;
        border-right: 0px solid transparent;
        border-top: 3px solid transparent;
        margin-left: -50px;
        background: #ffffff; } }
    @media (min-width: 992px) {
      .dropdown-menu li.dropdown .dd_second {
        top: 0 !important;
        left: -100% !important;
        margin-top: -23px;
        box-shadow: none;
        border-right: 0px solid transparent;
        border-top: 3px solid transparent;
        margin-left: -70px;
        background: #ffffff; } }
    @media (min-width: 1200px) {
      .dropdown-menu li.dropdown .dd_second {
        top: 0 !important;
        left: 100% !important;
        margin-top: -23px;
        margin-left: -5px;
        box-shadow: none;
        border-left: 10px solid transparent;
        border-top: 3px solid transparent;
        background: #ffffff; } }
  .dropdown-menu li.dropdown.show > .dropdown-menu {
    -webkit-transition: transform 0.3s, opacity 0.3s ease-in-out;
    -moz-transition: transform 0.3s, opacity 0.3s ease-in-out;
    -ms-transition: transform 0.3s, opacity 0.3s ease-in-out;
    -o-transition: transform 0.3s, opacity 0.3s ease-in-out;
    transition: transform 0.3s, opacity 0.3s ease-in-out;
    opacity: 1;
    display: block; }

@media (min-width: 768px) {
  .megaDropMenu {
    position: static; }
    .megaDropMenu .dropdown-menu {
      width: 100%;
      top: 100%;
      padding: 0 0px; }
      .megaDropMenu .dropdown-menu > li {
        float: left;
        padding: 0 20px;
        border-bottom: 0px; }
      .megaDropMenu .dropdown-menu ul li {
        border-bottom: 1px solid #e5e5e5; }
        .megaDropMenu .dropdown-menu ul li:last-child {
          border-bottom: none; } }

.navbar-sticky {
  position: fixed;
  top: 0;
  z-index: 1099;
  width: 100%; }

.static .navbar-sticky {
  display: none; }

.main-nav .nav-item .btn-search {
  top: 0;
  right: 0; }
  .main-nav .nav-item .btn-search:hover:before, .main-nav .nav-item .btn-search.active:before, .main-nav .nav-item .btn-search:focus:before {
    border: 0px !important;
    opacity: 0 !important; }
  .main-nav .nav-item .btn-search:hover:after, .main-nav .nav-item .btn-search.active:after, .main-nav .nav-item .btn-search:focus:after {
    border: 0px !important;
    opacity: 0 !important; }
  .main-nav .nav-item .btn-search.nav-link {
    display: none; }
    @media (min-width: 768px) {
      .main-nav .nav-item .btn-search.nav-link {
        display: block; }
        .main-nav .nav-item .btn-search.nav-link:before {
          display: none; } }

form.search_form {
  display: none;
  margin: 15px;
  position: relative;
  right: 0px;
  background: #ffffff;
  top: 100%;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
  z-index: 10;
  padding: 10px 15px; }
  @media (min-width: 768px) {
    form.search_form {
      width: 300px;
      display: none;
      position: absolute;
      margin-top: -20px;
      padding: 15px; } }
  form.search_form input {
    display: inline-block;
    padding: 8px 2px;
    font-size: 14px;
    border: none;
    max-width: 50%;
    color: #848484; }
    @media (min-width: 768px) {
      form.search_form input {
        max-width: 100%; } }
  form.search_form .btn-small {
    font-size: 12px;
    float: right; }

.nav-cart {
  position: absolute;
  right: 45px;
  top: 0px;
  margin-top: 0px; }
  @media (min-width: 768px) {
    .nav-cart {
      position: relative;
      margin-right: 0px;
      margin-top: 0px;
      margin-left: 15px;
      right: 0px; } }
  .nav-cart .btn-cart {
    width: 40px;
    height: 40px;
    display: inline-block;
    font-size: 18px;
    line-height: 40px;
    color: #fff;
    background: #86c9d4;
    text-align: center;
    border-radius: 50%; }
  .nav-cart .cart_item-box {
    position: absolute;
    right: 0px;
    background: #fff;
    width: 300px;
    top: 55px;
    display: none;
    -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08);
    z-index: 10;
    padding: 20px; }
    @media (min-width: 768px) {
      .nav-cart .cart_item-box {
        top: 50px; } }
    .nav-cart .cart_item-box .btn {
      font-weight: 700; }
    .nav-cart .cart_item-box .media-head {
      max-width: 70px;
      max-height: 70px;
      overflow: hidden;
      border: 1px solid #e5e5e5; }
    .nav-cart .cart_item-box li {
      border-bottom: 1px solid #e5e5e5;
      padding: 15px 0; }
      .nav-cart .cart_item-box li:nth-child(3), .nav-cart .cart_item-box li:last-child {
        border-bottom: 0px; }
      .nav-cart .cart_item-box li:last-child {
        text-align: center; }
    .nav-cart .cart_item-box .media-body {
      padding: 0px 15px;
      position: relative; }
      .nav-cart .cart_item-box .media-body h5 {
        font-size: 16px;
        max-width: 120px;
        line-height: 22px; }
      .nav-cart .cart_item-box .media-body span {
        font-weight: 700;
        font-size: 16px;
        line-height: 26px; }
      .nav-cart .cart_item-box .media-body .btn-close {
        position: absolute;
        right: 0;
        top: 5px;
        opacity: 0.5; }

.nav-cart, .btn-logo {
  display: table;
  table-layout: fixed; }

.icon-toggle {
  display: table-cell;
  transition: all 0.3s ease 0s;
  vertical-align: middle; }
  .icon-toggle.active > i {
    opacity: 0;
    transform: translateY(-20px) scale(0.7); }
  .icon-toggle.active::after {
    opacity: 1;
    transform: translateY(0px) scale(1); }
  .icon-toggle > i {
    display: inline-block;
    font-style: normal;
    opacity: 1;
    position: relative;
    transform: translateY(0px) scale(1);
    transition: transform 0.3s ease 0s, opacity 0.15s ease 0s; }

.icon-toggle::after {
  content: "\f00d";
  display: block;
  font-family: 'FontAwesome';
  margin-top: -40px;
  opacity: 0;
  transform: translateY(20px) scale(0.7);
  transition: transform 0.3s ease 0s, opacity 0.15s ease 0s; }

.boxed-menu header {
  height: auto !important; }

@media (min-width: 768px) {
  .boxed-menu .navbar-brand {
    margin-left: 15px; }
  .boxed-menu .main-nav {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -45px; }
    .boxed-menu .main-nav a.nav-link {
      color: #ffffff;
      font-family: 'Montserrat', sans-serif;
      padding: 35px 0px;
      margin: 0px 10px;
      font-size: 12px;
      font-weight: 500; }
      .boxed-menu .main-nav a.nav-link:before {
        border-bottom: 2px solid #ffffff; }
      .boxed-menu .main-nav a.nav-link:hover, .boxed-menu .main-nav a.nav-link:focus {
        color: #ffffff; }
    .boxed-menu .main-nav.navbar-sticky {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto; }
      .boxed-menu .main-nav.navbar-sticky a.nav-link {
        color: #ffffff; } }

@media (min-width: 992px) {
  .boxed-menu .main-nav {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto; }
    .boxed-menu .main-nav a.nav-link {
      margin: 0px 15px;
      font-size: 12px; } }

@media (min-width: 1200px) {
  .boxed-menu .main-nav {
    max-width: 1110px;
    margin-left: auto;
    margin-right: auto; }
    .boxed-menu .main-nav a.nav-link {
      margin: 0px 20px;
      font-size: 14px; } }

@media (min-width: 480px) {
  .boxed.boxed-menu .navbar-sticky {
    max-width: 520px;
    margin: 0 auto; } }

@media (min-width: 768px) {
  .boxed.boxed-menu .navbar-sticky {
    background-color: transparent;
    max-width: 728px;
    margin: 0 auto; } }

@media (min-width: 992px) {
  .boxed.boxed-menu .navbar-sticky {
    background-color: transparent;
    max-width: 970px;
    margin: 0 auto; } }

@media (min-width: 1200px) {
  .boxed.boxed-menu .navbar-sticky {
    background-color: transparent;
    max-width: 1260px;
    margin: 0 auto; } }

@media (min-width: 768px) {
  .boxed.boxed-menu .navbar-sticky {
    left: 50%;
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); } }

.mobile-sticky-header-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .5s;
  transition: all .5s;
  background-color: rgba(17, 17, 17, 0.4); }
  .mobile-sticky-header-overlay.active {
    opacity: 1;
    visibility: visible;
    z-index: 5550; }

.display-single_element .bg-dark .main-nav .nav-item a.nav-link {
  color: #ffffff; }

.display-single_element .bg-dark .main-nav a.nav-link:before {
  border-color: #ffffff; }

.display-single_element .main-nav a.nav-link:before {
  border-color: #222222; }

.display-single_element .main-nav a.nav-link:after {
  border-color: transparent; }

.display-single_element .main-nav {
  box-shadow: none; }
  .display-single_element .main-nav .nav-item a.nav-link {
    color: #222222; }
  .display-single_element .main-nav .navbar-collapse {
    margin-top: 20px;
    height: 200px; }
    @media (min-width: 768px) {
      .display-single_element .main-nav .navbar-collapse {
        margin-top: 0px;
        height: auto; } }
    .display-single_element .main-nav .navbar-collapse .navbar-nav {
      background-color: #ffffff; }
      @media (min-width: 768px) {
        .display-single_element .main-nav .navbar-collapse .navbar-nav {
          background-color: transparent; } }
  .display-single_element .main-nav .dropdown-menu a {
    color: #222222; }
  .display-single_element .main-nav .navbar-toggler, .display-single_element .main-nav .nav-cart {
    top: 20px; }
    @media (min-width: 768px) {
      .display-single_element .main-nav .navbar-toggler, .display-single_element .main-nav .nav-cart {
        top: 0; } }
  .display-single_element .main-nav .navbar-brand {
    margin-left: -15px; }
    @media (min-width: 768px) {
      .display-single_element .main-nav .navbar-brand {
        margin-left: unset; } }

.logo-center .main-nav .nav-item a.nav-link {
  color: #222222; }

@media (min-width: 768px) {
  .logo-center {
    background-image: url("../img/slider/slv1.jpg");
    background-position: bottom; }
    .logo-center .navbar-brand {
      position: absolute;
      width: 100%;
      left: 0;
      text-align: center;
      margin: auto;
      margin-left: -30px; } }
    @media (min-width: 768px) and (min-width: 768px) {
      .logo-center .navbar-brand {
        width: 150px;
        left: 50%;
        -moz-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%); } }

/* MODAL CUSTOMS STYLES */
@media (min-width: 992px) {
  .modal .modal-lg {
    max-width: 870px !important; } }

.modal.show .modal-dialog {
  -moz-transform: translate(0, 15%);
  -o-transform: translate(0, 15%);
  -ms-transform: translate(0, 15%);
  -webkit-transform: translate(0, 15%);
  transform: translate(0, 15%); }
  @media (min-width: 992px) {
    .modal.show .modal-dialog {
      -moz-transform: translate(0, 25%);
      -o-transform: translate(0, 25%);
      -ms-transform: translate(0, 25%);
      -webkit-transform: translate(0, 25%);
      transform: translate(0, 25%); } }

.modal-content {
  -webkit-box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
  border: none; }
  .modal-content .modal-item {
    padding: 15px; }
    @media (min-width: 992px) {
      .modal-content .modal-item {
        padding: 30px; } }

button.close {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  font-size: 16px;
  -webkit-appearance: none;
  position: absolute;
  right: 15px;
  z-index: 55; }
  button.close:hover, button.close.active, button.close:focus {
    outline: none;
    box-shadow: none; }

.card_img {
  position: relative;
  overflow: hidden; }

.quick_view {
  color: #ffffff !important; }
  .quick_view:visited, .quick_view:hover {
    color: #ffffff; }

.quick-view-container {
  background: rgba(10, 10, 10, 0.85); }

.quick-view-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  max-width: 980px;
  max-height: 400px; }

.quick-view-carousel {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: #fff; }
  @media (min-width: 768px) {
    .quick-view-carousel {
      top: 0;
      width: 57%; } }

.fancybox-slide .fancybox-image-wrap {
  top: 10px; }
  @media (min-width: 768px) {
    .fancybox-slide .fancybox-image-wrap {
      top: 0; } }

.quick-view-carousel .fancybox-stage {
  bottom: 30px; }

.quick-view-aside {
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 50%;
  left: auto;
  width: 100%;
  padding: 0px 0 0px 0;
  background: #fff;
  color: #777; }
  @media (min-width: 768px) {
    .quick-view-aside {
      width: 43%;
      bottom: 0; } }
  .quick-view-aside .project_specification {
    padding: 10px 30px;
    color: #ffffff;
    position: relative; }
    @media (min-width: 768px) {
      .quick-view-aside .project_specification {
        padding: 40px 30px; } }
    .quick-view-aside .project_specification h2 {
      margin-bottom: 10px;
      margin-top: 0px;
      font-size: 22px; }
      @media (min-width: 768px) {
        .quick-view-aside .project_specification h2 {
          margin-bottom: 20px; } }
      @media (min-width: 992px) {
        .quick-view-aside .project_specification h2 {
          margin-bottom: 30px;
          font-size: 30px; } }
    .quick-view-aside .project_specification .row {
      margin-top: 10px; }
      @media (min-width: 768px) {
        .quick-view-aside .project_specification .row {
          margin-top: 15px; } }

.quick-view-aside::before, .quick-view-aside::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0; }

.quick-view-aside::before {
  width: 8px;
  background: #f4f4f4; }

.quick-view-aside::after {
  width: 0px;
  background: #e9e9e9; }
  @media (min-width: 768px) {
    .quick-view-aside::after {
      width: 1px; } }

.quick-view-aside > div {
  height: 100%;
  overflow: auto;
  padding: 0 0px 0 0px;
  text-align: center; }
  @media (min-width: 768px) {
    .quick-view-aside > div {
      padding: 0 0px 0 10px; } }
  .quick-view-aside > div .bg-image {
    height: 100%; }

.quick-view-close {
  position: absolute;
  top: 0px;
  right: 0;
  width: 44px;
  height: 44px;
  background: #F0F0F0;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  font-family: Arial;
  font-size: 14px;
  color: #222;
  text-indent: -99999px;
  transition: all .2s; }

.quick-view-close:hover {
  background: #e4e4e4; }

.quick-view-close::before,
.quick-view-close::after {
  content: '';
  position: absolute;
  top: 12px;
  left: 22px;
  width: 1px;
  height: 18px;
  background-color: #222; }

.quick-view-close:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.quick-view-close:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

.quick-view-bullets {
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  z-index: 99999;
  -webkit-tap-highlight-color: transparent; }

.quick-view-bullets li {
  display: inline-block;
  vertical-align: top; }

.quick-view-bullets li a {
  display: block;
  height: 30px;
  width: 20px;
  position: relative; }

.quick-view-bullets li a span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 99px;
  text-indent: -99999px;
  overflow: hidden;
  background: #d4d2d2; }

.quick-view-bullets li.active a span {
  background: #265a88; }

/*
	// End of examples
*/
/* ================================================
--> Elements pagination style
================================================== */
.page-item a.page-link {
  border-radius: 0px !important;
  margin-right: 10px;
  color: #222222;
  font-size: 17px;
  height: 45px;
  width: 45px;
  line-height: 26px;
  overflow: hidden;
  text-align: center;
  border: 1px solid #dadada; }

.page-item.active a.page-link, .page-item:hover a.page-link {
  background-color: #265a88;
  border: 1px solid #265a88;
  color: #ffffff; }

.page-item:last-child a.page-link {
  margin-right: 0px; }

/* ================================================
--> Elements pagination style
================================================== */
.page-item a.page-link {
  border-radius: 0px !important;
  margin-right: 10px;
  color: #222222;
  font-size: 17px;
  height: 45px;
  width: 45px;
  line-height: 26px;
  overflow: hidden;
  text-align: center;
  border: 1px solid #dadada; }

.page-item.active a.page-link, .page-item:hover a.page-link {
  background-color: #265a88;
  border: 1px solid #265a88;
  color: #ffffff; }

.page-item:last-child a.page-link {
  margin-right: 0px; }

/* ================================================
--> Elements Pricing table style
================================================== */
.circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #265a88;
  white-space: nowrap;
  position: absolute;
  padding: 0px;
  top: -40px;
  left: 50%;
  -webkit-box-shadow: 0px 4px 10px rgba(134, 201, 212, 0.3);
  -moz-box-shadow: 0px 4px 10px rgba(134, 201, 212, 0.3);
  box-shadow: 0px 4px 10px rgba(134, 201, 212, 0.3);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #ffffff; }

.pricing {
  border: 1px solid #e5e5e5;
  padding: 30px 0px;
  -webkit-transition: box-shadow 0.3s ease;
  -moz-transition: box-shadow 0.3s ease;
  -ms-transition: box-shadow 0.3s ease;
  -o-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease; }
  .pricing > h2 {
    line-height: 30px;
    margin-bottom: 10px; }
  .pricing > span {
    color: #848484;
    font-style: italic;
    display: inline-block;
    font-size: 14px; }
  .pricing .card-header {
    margin: 30px 0;
    border: none;
    padding: 30px 0; }
    .pricing .card-header > span.price {
      color: #222222;
      display: inline-block;
      font-size: 50px;
      line-height: 50px; }
    .pricing .card-header > span.currency {
      color: #222222;
      font-size: 18px;
      display: inline-block; }
  .pricing .list-group {
    padding-top: 20px;
    padding-bottom: 30px; }
    .pricing .list-group .list-group-item {
      color: #222222; }
      .pricing .list-group .list-group-item i {
        color: #848484; }
  .pricing .btn {
    font-weight: 700;
    color: #848484;
    height: 50px;
    line-height: 50px; }
    .pricing .btn:hover {
      background-color: #265a88;
      color: #ffffff;
      border-color: #265a88; }
  .pricing .card-block {
    position: relative; }
  .pricing .circle {
    position: relative;
    padding: 0 15px;
    height: 100px;
    width: 100px;
    background: transparent;
    left: 0px;
    top: 0;
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    line-height: 100px;
    box-shadow: none;
    border: 1px solid #e5e5e5; }
    .pricing .circle .price {
      font-size: 36px;
      font-weight: 700;
      color: #265a88; }
    .pricing .circle .tag {
      color: #222222;
      font-size: 16px; }
  .pricing:hover {
    -webkit-box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.08);
    border: 0px solid transparent; }
  .pricing.delux .card-header {
    background-color: #265a88; }
    .pricing.delux .card-header span.price, .pricing.delux .card-header span.currency {
      color: #ffffff; }

.style2 {
  padding: 40px;
  position: relative; }
  .style2 h2 {
    margin: 25px 0 15px; }
  .style2 p {
    margin-bottom: 20px; }
  .style2 .btn {
    height: 40px;
    line-height: 40px; }
    .style2 .btn:hover {
      color: #ffffff !important; }

.pricing_image-card {
  -webkit-transition: box-shadow 0.3s ease;
  -moz-transition: box-shadow 0.3s ease;
  -ms-transition: box-shadow 0.3s ease;
  -o-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease; }
  .pricing_image-card .card_img {
    position: relative; }
    .pricing_image-card .card_img .overlay {
      color: #ffffff;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.4);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .pricing_image-card .card_img .overlay h2 {
        color: #ffffff;
        margin-bottom: 10px; }
  .pricing_image-card .card-block {
    position: relative;
    padding: 80px 20px 30px;
    border: 1px solid #e5e5e5;
    border-top: 0px;
    -webkit-transition: border 0.3s ease;
    -moz-transition: border 0.3s ease;
    -ms-transition: border 0.3s ease;
    -o-transition: border 0.3s ease;
    transition: border 0.3s ease; }
    .pricing_image-card .card-block p {
      margin-bottom: 30px; }
  .pricing_image-card .circle {
    top: -50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 15px;
    background: #ffffff;
    -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06); }
    .pricing_image-card .circle span.price {
      color: #265a88;
      font-size: 34px;
      display: inline-block;
      font-weight: 700;
      margin-top: 30px; }
    .pricing_image-card .circle span.tag {
      color: #848484;
      font-size: 13px;
      display: block;
      font-style: italic;
      line-height: 26px;
      text-transform: capitalize; }
  .pricing_image-card .btn {
    font-weight: 700;
    color: #848484; }
    .pricing_image-card .btn:hover {
      background-color: #265a88;
      color: #ffffff !important;
      border-color: #265a88; }
  .pricing_image-card:hover {
    -webkit-box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.08); }
    .pricing_image-card:hover .card-block {
      border: 0px solid transparent; }

.display-single_element .pricing, .display-single_element .pricing_image-card {
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .display-single_element .pricing, .display-single_element .pricing_image-card {
      margin-bottom: 0px; } }

/* ================================================
--> Elements Popover & Tooltip style
================================================== */
.popover {
  border-radius: 0px;
  border-color: #e5e5e5; }

.popover.bs-tether-element-attached-left::before,
.popover.popover-right::before,
.popover.popover-left::before,
.popover.popover-top::before,
.popover.popover-bottom::before {
  border-right-color: #e5e5e5; }

.popover-title {
  font-size: 15px;
  border-bottom: 1px solid #e5e5e5;
  padding: 5px; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff !important;
  position: relative;
  text-align: center;
  background-color: #013A68 !important;
  border-radius: 0px; }

.tooltip-inner:after {
  position: absolute;
  content: '';
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: #000000;
  /* black */
  border-width: 0 5px 5px; }

.display-single_element button[data-toggle="tooltip"] {
  margin-bottom: 15px;
  display: block; }
  @media (min-width: 768px) {
    .display-single_element button[data-toggle="tooltip"] {
      margin-bottom: 0px;
      display: inline-block; } }

/* ================================================
--> Elements tabs style
================================================== */
.icon_tabs {
  margin-top: 25px;
  border: none;
  display: block; }
  @media (min-width: 768px) {
    .icon_tabs {
      justify-content: center;
      display: flex; } }
  .icon_tabs .nav-item {
    border: none;
    display: block;
    text-align: left !important;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .icon_tabs .nav-item {
        margin-bottom: 0px;
        text-align: center !important; } }
    @media (min-width: 992px) {
      .icon_tabs .nav-item {
        display: inline-block;
        margin: 0 5px; } }
    .icon_tabs .nav-item a.nav-link {
      -webkit-box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
      box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
      border: none;
      border-radius: 0px;
      background: #fff;
      position: relative;
      color: #222222;
      white-space: nowrap;
      padding: 25px; }
      @media (min-width: 768px) {
        .icon_tabs .nav-item a.nav-link {
          padding: 20px 0px; } }
      @media (min-width: 992px) {
        .icon_tabs .nav-item a.nav-link {
          padding: 25px 0px; } }
      @media (min-width: 1200px) {
        .icon_tabs .nav-item a.nav-link {
          padding: 40px 0px; } }
      .icon_tabs .nav-item a.nav-link:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        border-bottom: 4px solid #265a88; }
      .icon_tabs .nav-item a.nav-link i {
        font-size: 26px;
        color: #848484;
        float: left;
        line-height: 20px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease; }
        @media (min-width: 768px) {
          .icon_tabs .nav-item a.nav-link i {
            float: unset;
            font-size: 30px;
            padding-bottom: 14px; } }
        @media (min-width: 992px) {
          .icon_tabs .nav-item a.nav-link i {
            float: unset;
            font-size: 40px;
            padding-bottom: 20px; } }
      .icon_tabs .nav-item a.nav-link h3 {
        text-transform: capitalize;
        display: inline-block;
        margin-left: 15px;
        font-size: 18px; }
        @media (min-width: 768px) {
          .icon_tabs .nav-item a.nav-link h3 {
            display: block;
            margin-left: 0px;
            font-size: 15px; } }
        @media (min-width: 992px) {
          .icon_tabs .nav-item a.nav-link h3 {
            font-size: 18px; } }
      .icon_tabs .nav-item a.nav-link:hover:before, .icon_tabs .nav-item a.nav-link.active:before {
        opacity: 1; }
      .icon_tabs .nav-item a.nav-link:hover .fa, .icon_tabs .nav-item a.nav-link.active .fa {
        color: #265a88; }

.nav-tabs.bar-tabs {
  flex-direction: row;
  justify-content: center; }
  .nav-tabs.bar-tabs .nav-item {
    margin-bottom: 0px; }
  .nav-tabs.bar-tabs .nav-link {
    border-radius: 0px;
    position: relative;
    display: inline-block;
    padding: 10px 25px;
    padding-bottom: 15px !important;
    background-color: transparent !important;
    margin-right: 0px;
    border: none !important; }
    .nav-tabs.bar-tabs .nav-link.active h5 {
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      color: #265a88; }
    .nav-tabs.bar-tabs .nav-link.active:before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      border-bottom: 3px solid #265a88;
      width: 100%; }

.tab-content .active {
  padding: 25px 0; }

@media (min-width: 768px) {
  .nav-tabs .nav-item {
    margin-bottom: -1px; } }

.tab-content.tab-bordered .active {
  border: 1px solid #e5e5e5;
  border-top: 0px solid transparent;
  padding: 25px; }

.nav-unbordered.nav-tabs {
  border-bottom: 0px; }
  .nav-unbordered.nav-tabs .nav-item {
    padding: 10px 25px 10px 0; }
    .nav-unbordered.nav-tabs .nav-item .nav-link {
      border: 0px;
      padding: 0px;
      font-size: 18px;
      background-color: transparent;
      text-transform: capitalize;
      position: relative;
      display: inline-block; }
    .nav-unbordered.nav-tabs .nav-item .nav-link.active {
      color: #7bd1df;
      background-color: transparent; }
      .nav-unbordered.nav-tabs .nav-item .nav-link.active:before {
        content: '';
        position: absolute;
        width: 100%;
        border-bottom: 2px solid #7bd1df;
        bottom: -8px; }

.nav.nav-tabs {
  flex-direction: column; }
  @media (min-width: 768px) {
    .nav.nav-tabs {
      flex-direction: unset; } }

.nav-stacked {
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.nav-tabs.nav-stacked {
  margin-right: 0;
  border-bottom: 0;
  border-right: none; }
  @media (min-width: 768px) {
    .nav-tabs.nav-stacked {
      border-right: 1px solid #e5e5e5;
      padding-right: 0; } }

.nav-tabs.nav-stacked .nav-item {
  margin: 0 !important;
  float: none !important;
  display: block !important; }
  .nav-tabs.nav-stacked .nav-item .nav-link {
    padding: 10px 15px;
    margin-right: 0px;
    border-right: 0px; }
    .nav-tabs.nav-stacked .nav-item .nav-link i {
      padding-right: 10px; }
    .nav-tabs.nav-stacked .nav-item .nav-link.active {
      border-top-color: #e5e5e5;
      border-top-width: 1px;
      bottom: 0; }
      @media (min-width: 768px) {
        .nav-tabs.nav-stacked .nav-item .nav-link.active {
          margin-right: -1px;
          border-right-color: #fff !important; } }

/* ================================================
--> Elements video style
================================================== */
.promo-video {
  position: relative;
  width: 100%;
  float: none;
  overflow: hidden;
  max-height: 400px;
  padding-top: 100%; }
  .promo-video:before {
    background-color: rgba(10, 35, 59, 0); }
  @media (min-width: 768px) {
    .promo-video {
      max-height: 500px;
      width: 100%;
      float: left;
      position: relative;
      margin: 0px;
      padding-top: 80%; } }
  @media (min-width: 992px) {
    .promo-video {
      padding-top: 50%; } }
  .promo-video .video-button {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }
    .promo-video .video-button i {
      position: relative;
      height: 80px;
      width: 80px;
      line-height: 80px;
      font-size: 35px;
      border-radius: 50%;
      display: inline-block;
      color: #ffffff;
      padding-left: 10px;
      text-align: center;
      border: 2px solid #ffffff;
      -webkit-transition: transform 0.3s ease;
      -moz-transition: transform 0.3s ease;
      -ms-transition: transform 0.3s ease;
      -o-transition: transform 0.3s ease;
      transition: transform 0.3s ease; }
    .promo-video .video-button:hover i {
      -moz-transform: scale(1.1);
      -o-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform: scale(1.1); }

.video-box a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .video-box a iframe {
    width: 100%;
    height: 100%;
    border: none;
    position: relative; }
  .video-box a:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(10, 35, 59, 0.4); }
  .video-box a img {
    position: relative;
    -webkit-transition: transform 0.7s ease;
    -moz-transition: transform 0.7s ease;
    -ms-transition: transform 0.7s ease;
    -o-transition: transform 0.7s ease;
    transition: transform 0.7s ease; }
  .video-box a:hover img {
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }

.box-video {
  position: relative;
  max-width: 100%;
  margin: 0 auto 20px;
  cursor: pointer;
  overflow: hidden; }
  .box-video .bg-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 2; }
  .box-video .video-container {
    position: relative;
    margin: 0;
    z-index: 1; }
  .box-video .btn-play {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
    display: inline-block;
    width: 80px;
    height: 80px;
    background: #ffffff;
    border-radius: 50%;
    text-indent: -999em;
    cursor: pointer;
    z-index: 2;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out; }
    .box-video .btn-play:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      height: 0;
      width: 0;
      margin: -12px 0 0 -6px;
      border: solid transparent;
      border-left-color: #265a88;
      border-width: 12px 20px;
      -webkit-transition: all .3s ease-out;
      transition: all .3s ease-out; }
  .box-video:hover .btn-play {
    transform: scale(1.1); }
  .box-video.open .bg-video {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all .6s .8s;
    transition: all .6s .8s; }
  .box-video.open .video-container {
    opacity: 1;
    -webkit-transition: all .6s .8s;
    transition: all .6s .8s; }

.fluid-width-video-wrapper {
  width: 100%;
  position: relative;
  padding: 0; }
  .fluid-width-video-wrapper iframe, .fluid-width-video-wrapper object, .fluid-width-video-wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/* ================================================
--> Background Element style
================================================== */
.seperator {
  display: inline-block;
  position: relative; }
  .seperator svg.seperator-icon {
    width: 34px;
    height: 39px;
    fill: #265a88; }
  .seperator:before {
    position: absolute;
    content: '';
    top: 19px;
    left: -110px;
    width: 100px;
    border-bottom: 1px solid #265a88; }
  .seperator:after {
    position: absolute;
    content: '';
    top: 19px;
    right: -110px;
    width: 100px;
    border-bottom: 1px solid #265a88; }

p.devider {
  position: relative;
  border-top: 1px solid #e5e5e5;
  margin: 20px 0; }
  p.devider.devider_medium {
    max-width: 50%;
    margin-left: auto;
    margin-right: auto; }
  p.devider.devider_dashed {
    border-top: 1px dashed #e5e5e5; }
  p.devider.devider_narrow {
    max-width: 10%;
    margin-left: auto;
    margin-right: auto; }
  p.devider.devider_thik {
    border-width: 2px; }
  p.devider.devider_primary {
    border-color: #265a88; }

.blockquote {
  position: relative;
  padding: 28px;
  margin-bottom: 20px;
  border: 1px solid #e5e5e5;
  border-left: 3px solid #265a88;
  font-size: 15px;
  line-height: 28px;
  font-style: italic; }
  .blockquote.text-right {
    border-right: 3px solid #265a88;
    border-left: 1px solid #e5e5e5; }
  .blockquote.quote2 {
    border-left: 1px solid #e5e5e5; }

.blockquote-bubble {
  position: relative;
  background-color: #ffffff; }
  .blockquote-bubble .blockquote-bubble-content:after {
    top: 100%;
    border: 1px solid #e5e5e5;
    content: " ";
    height: 20px;
    width: 20px;
    position: absolute;
    pointer-events: none;
    background: #ffffff;
    left: 30px;
    margin-top: -9px;
    transform: rotate(45deg);
    border-left: 0px;
    border-top: 0px; }
  .blockquote-bubble.text-right .blockquote-bubble-content:after {
    right: 30px;
    left: auto; }

.blockquote-footer {
  margin-bottom: 20px;
  position: relative;
  display: flex; }
  .blockquote-footer img {
    width: 60px;
    height: 60px;
    background: #f5f5f5;
    float: left; }
  .blockquote-footer .info {
    display: inline-block;
    padding-left: 10px;
    float: left;
    margin-top: 8px; }
  .blockquote-footer .name {
    font-size: 15px;
    color: #222222;
    display: inline-block; }
  .blockquote-footer .designation {
    font-size: 15px;
    color: #848484;
    display: inline-block; }
  .blockquote-footer:before {
    content: "\2014 \00A0";
    visibility: hidden; }
  .blockquote-footer.text-right {
    flex-direction: row-reverse; }
    .blockquote-footer.text-right img {
      float: right; }
    .blockquote-footer.text-right .info {
      float: right;
      padding-left: 0px;
      padding-right: 10px; }
  .blockquote-footer.footer2 {
    margin-top: 20px;
    margin-left: 40px; }

.arrow-down {
  border-left: 20px solid transparent;
  border-right: 0 solid transparent;
  border-top: 20px solid #e5e5e5;
  bottom: -20px;
  height: 0;
  left: 25px;
  position: absolute;
  width: 0; }

.testimonial_holder {
  padding: 50px 20px; }
  .testimonial_holder .bg-image:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(31, 40, 46, 0.9); }

.bg-image {
  position: relative;
  background-size: cover;
  background-position: center; }
  .bg-image .quote2 {
    border-left: 1px solid #e5e5e5;
    border-color: #ffffff; }
  .bg-image .blockquote-speech {
    color: #222222; }
  .bg-image .blockquote-footer .name {
    font-size: 15px;
    color: #265a88;
    display: inline-block; }
  .bg-image .blockquote-footer .designation {
    font-size: 15px;
    color: #ffffff;
    font-style: italic;
    opacity: 0.7;
    display: inline-block; }
  .bg-image .arrow-down {
    border-left: 20px solid transparent;
    border-right: 0 solid transparent;
    border-top: 20px solid #ffffff;
    bottom: -20px;
    height: 0;
    left: 25px;
    position: absolute;
    width: 0; }

.team-card .card_img {
  overflow: hidden;
  margin: 0 auto; }
  .team-card .card_img.img-circle {
    width: 240px;
    height: 240px; }

.team-card a.overlay_icon {
  width: 45px;
  height: 45px;
  background-color: transparent;
  border: 2px solid #ffffff; }
  .team-card a.overlay_icon i.fa {
    font-size: 15px;
    line-height: 40px;
    color: #ffffff; }
  .team-card a.overlay_icon:hover {
    background-color: #ffffff;
    border: 2px solid #ffffff; }
    .team-card a.overlay_icon:hover i.fa {
      color: #265a88; }

p.typo {
  padding-top: 20px;
  padding-bottom: 30px; }

mark {
  background: transparent;
  color: #f55c5d !important; }

.styled_list {
  margin-top: 15px;
  position: relative;
  display: inline-block; }
  @media (min-width: 768px) {
    .styled_list {
      margin-top: 15px; } }
  .styled_list li {
    font-size: 14px;
    line-height: 30px; }
    @media (min-width: 768px) {
      .styled_list li {
        font-size: 14px;
        float: left;
        width: 50%; } }
    .styled_list li i {
      margin-right: 10px;
      font-size: 12px; }
    .styled_list li a {
      color: #848484; }
      @media (min-width: 768px) {
        .styled_list li a {
          font-size: 12px; } }
      @media (min-width: 992px) {
        .styled_list li a {
          font-size: 14px; } }

ol.styled_list {
  margin-left: 15px; }
  ol.styled_list li {
    padding-left: 10px; }

.icon-sm {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 22px;
  display: inline-block; }

.dropcaps p {
  display: inline-block; }
  .dropcaps p .firstcharacter {
    color: #848484;
    float: left;
    font-size: 22px;
    width: 40px;
    height: 40px;
    background-color: #f5f5f5;
    text-align: center;
    line-height: 40px;
    margin-top: 9px;
    margin-right: 10px; }

.map-canvas {
  height: 400px;
  width: 100%; }
  .map-canvas #map {
    height: 100%;
    width: 100%; }

.mapArea #map-canvas {
  height: 400px;
  width: 100%; }

/*==============================================
--> 5. HOME
================================================*/
/*==============================================
--> 5.1 SLIDER SECTION
================================================*/
#banner-slider .bg-image-holder {
  height: 200px; }
  @media (min-width: 768px) {
    #banner-slider .bg-image-holder {
      height: 400px; } }
  @media (min-width: 992px) {
    #banner-slider .bg-image-holder {
      height: 550px; } }
  @media (min-width: 1200px) {
    #banner-slider .bg-image-holder {
      height: 692px; } }

.slider-caption {
  position: relative;
  height: 100%; }
  .slider-caption .caption-content {
    position: absolute;
    top: 50%;
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
    .slider-caption .caption-content h1 {
      font-size: 20px;
      color: #222222; }
      @media (min-width: 768px) {
        .slider-caption .caption-content h1 {
          font-size: 30px;
          margin-bottom: 20px; } }
      @media (min-width: 992px) {
        .slider-caption .caption-content h1 {
          font-size: 40px;
          margin-bottom: 25px; } }
      @media (min-width: 1200px) {
        .slider-caption .caption-content h1 {
          font-size: 50px;
          margin-bottom: 30px; } }
    .slider-caption .caption-content p {
      font-size: 12px;
      color: #222222;
      margin: 0 0 10px;
      font-weight: 600; }
      @media (min-width: 768px) {
        .slider-caption .caption-content p {
          font-size: 20px;
          margin: 0 0 30px; } }
      @media (min-width: 992px) {
        .slider-caption .caption-content p {
          font-size: 20px;
          margin: 0 0 40px; } }
      @media (min-width: 1200px) {
        .slider-caption .caption-content p {
          font-size: 20px;
          margin: 0 0 50px; } }
    .slider-caption .caption-content.caption-right {
      right: 15px;
      text-align: right; }
    .slider-caption .caption-content .video-link {
      color: #ffffff;
      font-size: 14px;
      text-transform: uppercase; }
      .slider-caption .caption-content .video-link span {
        position: relative;
        display: inline-block; }
    .slider-caption .caption-content i.play-icon {
      position: relative;
      height: 80px;
      width: 80px;
      line-height: 80px;
      font-size: 25px;
      border-radius: 50%;
      display: inline-block;
      color: #265a88;
      padding-left: 10px;
      text-align: center;
      margin-right: 10px;
      background: #ffffff;
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
    .slider-caption .caption-content a.btn {
      height: 30px;
      font-size: 9px;
      line-height: 30px;
      padding: 0 18px;
      font-weight: 700; }
      @media (min-width: 768px) {
        .slider-caption .caption-content a.btn {
          height: 50px;
          font-size: 14px;
          line-height: 50px;
          padding: 0 40px; } }

.slick-dotted.slick-slider {
  margin-bottom: 0px; }

.slick-dots li button {
  border: 2px solid #265a88;
  opacity: 1;
  background: #265a88;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.slick-dots li.slick-active button {
  border: none;
  opacity: 1;
  border-radius: 10px;
  padding-top: 40px; }
  .slick-dots li.slick-active button:before {
    content: ''; }

.btn {
  position: relative;
  z-index: 2; }
  .btn:before {
    position: absolute;
    -webkit-transition: all 0.3s cubic-bezier(0, 0, 0.1, 1);
    -moz-transition: all 0.3s cubic-bezier(0, 0, 0.1, 1);
    -ms-transition: all 0.3s cubic-bezier(0, 0, 0.1, 1);
    -o-transition: all 0.3s cubic-bezier(0, 0, 0.1, 1);
    transition: all 0.3s cubic-bezier(0, 0, 0.1, 1);
    content: '';
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    z-index: -1;
    background-color: rgba(255, 255, 255, 0.1); }
  .btn:hover:before {
    width: 100%; }

/*==============================================
--> 5.2 FEATURE SECTION
================================================*/
.home-feature .container {
  padding: 0px; }

.home-feature .feature_single {
  position: relative;
  background-color: transparent;
  padding: 30px 15px; }
  @media (min-width: 768px) {
    .home-feature .feature_single {
      padding: 70px 15px; } }
  .home-feature .feature_single .icon {
    font-size: 50px;
    color: #265a88;
    position: relative; }
  .home-feature .feature_single h4 {
    color: #ffffff;
    padding: 25px 0px;
    position: relative; }
    @media (min-width: 768px) {
      .home-feature .feature_single h4 {
        font-size: 18px;
        padding: 20px 0px; } }
    @media (min-width: 992px) {
      .home-feature .feature_single h4 {
        font-size: 20px;
        padding: 25px 0px; } }
  .home-feature .feature_single p {
    color: #ffffff;
    opacity: 0.7;
    padding: 0 0px; }
    @media (min-width: 768px) {
      .home-feature .feature_single p {
        font-size: 13px;
        line-height: 24px; } }
    @media (min-width: 992px) {
      .home-feature .feature_single p {
        font-size: 15px;
        line-height: 26px; } }

.more-feature {
  padding-top: 80px;
  padding-bottom: 80px; }
  @media (min-width: 768px) {
    .more-feature {
      padding-top: 100px;
      padding-bottom: 100px; } }

.more-feature-left .more-features {
  padding-top: 40px; }

.more-feature-left .feature-icon {
  display: inline-block;
  width: 75px;
  height: 75px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  line-height: 75px;
  text-align: center;
  position: relative;
  margin-bottom: 20px;
  font-size: 24px;
  color: #265a88; }
  @media (min-width: 768px) {
    .more-feature-left .feature-icon {
      position: absolute;
      margin-bottom: 0; } }

.more-feature-left .feature-details {
  display: inline-block; }
  @media (min-width: 768px) {
    .more-feature-left .feature-details {
      padding-left: 100px; } }
  .more-feature-left .feature-details h4 {
    margin-bottom: 15px;
    line-height: 1; }
    @media (min-width: 768px) {
      .more-feature-left .feature-details h4 {
        font-size: 18px;
        margin-bottom: 10px; } }
    @media (min-width: 992px) {
      .more-feature-left .feature-details h4 {
        font-size: 20px;
        margin-bottom: 15px; } }
  .more-feature-left .feature-details p {
    color: #848484;
    opacity: 0.7; }
    @media (min-width: 768px) {
      .more-feature-left .feature-details p {
        font-size: 13px;
        line-height: 24px; } }
    @media (min-width: 992px) {
      .more-feature-left .feature-details p {
        font-size: 15px;
        line-height: 26px; } }

.data-chart {
  padding-left: 30px;
  margin-top: 100px; }
  .data-chart h4 {
    margin-bottom: 30px; }

/*==============================================
--> 5.3 COUNTER UP SECTION
================================================*/
.countup-section {
  padding-top: 60px;
  padding-bottom: 60px; }
  @media (min-width: 992px) {
    .countup-section {
      padding-top: 100px;
      padding-bottom: 100px; } }
  .countup-section.bg-image:before {
    background-color: rgba(10, 35, 59, 0.8); }
  .countup-section .counter, .countup-section span.count {
    font-family: 'Montserrat', sans-serif;
    font-size: 40px;
    padding-bottom: 50px;
    position: relative;
    z-index: 555;
    color: #265a88;
    display: inline-block; }
    @media (min-width: 768px) {
      .countup-section .counter, .countup-section span.count {
        font-size: 40px; } }
    @media (min-width: 992px) {
      .countup-section .counter, .countup-section span.count {
        font-size: 50px; } }
  .countup-section .counter:before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 25px;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.5);
    width: 50px; }
  .countup-section .single_block {
    margin: 20px 0; }
    @media (min-width: 768px) {
      .countup-section .single_block {
        margin: 0; } }
  .countup-section h5 {
    color: #ffffff;
    opacity: 0.7; }

/*==============================================
--> 5.4 PORTFOLIO SECTION
================================================*/
.home-portfolio {
  padding-top: 80px;
  padding-bottom: 80px; }
  @media (min-width: 992px) {
    .home-portfolio {
      padding-top: 100px;
      padding-bottom: 100px; } }
  .home-portfolio .sectionTitle {
    margin-bottom: 20px; }
  .home-portfolio .card {
    margin-top: 30px; }
  .home-portfolio .btn-container {
    text-align: center;
    margin-top: 50px; }
  .home-portfolio .hover-overlay.effect-scale {
    opacity: 1;
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1); }
    @media (min-width: 768px) {
      .home-portfolio .hover-overlay.effect-scale {
        opacity: 0;
        -moz-transform: scale(0);
        -o-transform: scale(0);
        -ms-transform: scale(0);
        -webkit-transform: scale(0);
        transform: scale(0); } }
    .home-portfolio .hover-overlay.effect-scale:before {
      opacity: 1;
      -moz-transform: scale(1);
      -o-transform: scale(1);
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1); }
      @media (min-width: 768px) {
        .home-portfolio .hover-overlay.effect-scale:before {
          opacity: 0;
          -moz-transform: scale(0);
          -o-transform: scale(0);
          -ms-transform: scale(0);
          -webkit-transform: scale(0);
          transform: scale(0); } }
    .home-portfolio .hover-overlay.effect-scale:before {
      background-color: rgba(10, 35, 59, 0.9); }
  .home-portfolio a.btn:hover {
    color: #ffffff; }

/*==============================================
--> 5.5 VIDEO + TESTIMONIAL SECTION
================================================*/
.home-testimonial .promo-video > img {
  max-width: 100%; }
  @media (min-width: 768px) {
    .home-testimonial .promo-video > img {
      max-width: unset; } }

.home-testimonial .video-button i {
  position: relative;
  height: 80px;
  width: 80px;
  line-height: 80px;
  font-size: 35px;
  border-radius: 50%;
  display: inline-block;
  color: #ffffff;
  padding-left: 10px;
  text-align: center;
  border: 2px solid #ffffff;
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -ms-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease; }

.home-testimonial .video-button:hover i {
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1); }

.home-testimonial .bg-image {
  height: 100%;
  text-align: center; }
  .home-testimonial .bg-image .card {
    background-color: transparent;
    padding: 50px 15px; }
    @media (min-width: 768px) {
      .home-testimonial .bg-image .card {
        padding: 40px 10px 0; } }
    @media (min-width: 992px) {
      .home-testimonial .bg-image .card {
        padding: 80px 40px; } }
    @media (min-width: 1200px) {
      .home-testimonial .bg-image .card {
        padding: 100px 40px; } }
  .home-testimonial .bg-image .card-block p {
    padding: 20px 0;
    color: #ffffff;
    opacity: 0.7; }
    @media (min-width: 768px) {
      .home-testimonial .bg-image .card-block p {
        font-size: 14px;
        padding: 15px 10px; } }
    @media (min-width: 992px) {
      .home-testimonial .bg-image .card-block p {
        font-size: 15px;
        padding: 20px 0px; } }
  .home-testimonial .bg-image .card-block h4 {
    font-size: 17px;
    margin-top: 10px;
    margin-bottom: 10px; }
  .home-testimonial .bg-image .card_img {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: inline-block; }
  .home-testimonial .bg-image.promo-video {
    padding-top: 100%; }
    @media (min-width: 992px) {
      .home-testimonial .bg-image.promo-video {
        padding-top: 0; } }
    .home-testimonial .bg-image.promo-video:before {
      background-color: rgba(10, 35, 59, 0); }
  .home-testimonial .bg-image:before {
    background-color: rgba(10, 35, 59, 0.95); }

/*==============================================
--> 5.6 OUR SERVICES
================================================*/
.home-services {
  padding-top: 80px;
  padding-bottom: 80px; }
  @media (min-width: 992px) {
    .home-services {
      padding-top: 100px;
      padding-bottom: 100px; } }
  .home-services .media-box {
    margin-top: 50px; }
    .home-services .media-box .media-icon {
      color: #265a88; }
    .home-services .media-box h4 {
      margin-bottom: 10px; }

.media-box {
  position: relative;
  overflow: hidden;
  max-width: 100; }
  .media-box .media-icon {
    position: absolute;
    font-size: 40px;
    display: inline-block; }
  .media-box .media-content {
    display: inline-block;
    padding-left: 70px; }

/*==============================================
--> 5.7 FORM
================================================*/
.home-form {
  padding-top: 80px; }
  @media (min-width: 992px) {
    .home-form {
      margin-top: 50px;
      padding-top: 100px; } }
  .home-form h2 {
    color: #ffffff;
    margin-bottom: 50px; }
  .home-form form {
    margin-bottom: 30px; }
    @media (min-width: 992px) {
      .home-form form {
        margin-bottom: 0px; } }
    .home-form form .btn {
      letter-spacing: 1px; }
  .home-form input {
    background-color: transparent;
    font-size: 14px;
    color: #ffffff; }
    .home-form input:hover, .home-form input:active, .home-form input:focus {
      background-color: transparent;
      color: #ffffff; }
  .home-form .form-control::-webkit-input-placeholder {
    color: #ffffff;
    opacity: 0.7; }
  .home-form .form-control:-moz-placeholder {
    color: #ffffff;
    opacity: 0.7; }
  .home-form .form-control::-moz-placeholder {
    color: #ffffff;
    opacity: 0.7; }
  .home-form .form-control:-ms-input-placeholder {
    color: #ffffff;
    opacity: 0.7; }
  .home-form .selectOptions {
    margin-bottom: 30px; }
    .home-form .selectOptions .sbHolder > a {
      color: #ffffff;
      opacity: 0.7; }
  .home-form .promo-img {
    display: none; }
    @media (min-width: 992px) {
      .home-form .promo-img {
        display: block;
        height: 100%;
        margin-top: -150px; }
        .home-form .promo-img img {
          max-width: 500px; } }

/*======== MODAL FORM ========*/
.boxed-menu .topbar a.btn {
  padding: 0 !important;
  width: 158px; }

#morphing-content {
  min-height: 600px; }
  #morphing-content .home-form {
    margin-top: 0;
    padding-top: 0; }
    #morphing-content .home-form h2 {
      font-size: 50px;
      font-family: 'Open Sans', sans-serif; }
    #morphing-content .home-form input.form-control, #morphing-content .home-form textarea.form-control, #morphing-content .home-form select.form-control {
      border: 1px solid #ffffff;
      border-color: rgba(255, 255, 255, 0.6); }
      #morphing-content .home-form input.form-control::-webkit-input-placeholder, #morphing-content .home-form textarea.form-control::-webkit-input-placeholder, #morphing-content .home-form select.form-control::-webkit-input-placeholder {
        color: #ffffff;
        opacity: 1;
        -webkit-transition: opacity 0.3s ease;
        -moz-transition: opacity 0.3s ease;
        -ms-transition: opacity 0.3s ease;
        -o-transition: opacity 0.3s ease;
        transition: opacity 0.3s ease; }
      #morphing-content .home-form input.form-control:-moz-placeholder, #morphing-content .home-form textarea.form-control:-moz-placeholder, #morphing-content .home-form select.form-control:-moz-placeholder {
        color: #ffffff;
        opacity: 1;
        -webkit-transition: opacity 0.3s ease;
        -moz-transition: opacity 0.3s ease;
        -ms-transition: opacity 0.3s ease;
        -o-transition: opacity 0.3s ease;
        transition: opacity 0.3s ease; }
      #morphing-content .home-form input.form-control::-moz-placeholder, #morphing-content .home-form textarea.form-control::-moz-placeholder, #morphing-content .home-form select.form-control::-moz-placeholder {
        color: #ffffff;
        opacity: 1;
        -webkit-transition: opacity 0.3s ease;
        -moz-transition: opacity 0.3s ease;
        -ms-transition: opacity 0.3s ease;
        -o-transition: opacity 0.3s ease;
        transition: opacity 0.3s ease; }
      #morphing-content .home-form input.form-control:-ms-input-placeholder, #morphing-content .home-form textarea.form-control:-ms-input-placeholder, #morphing-content .home-form select.form-control:-ms-input-placeholder {
        color: #ffffff;
        opacity: 1;
        -webkit-transition: opacity 0.3s ease;
        -moz-transition: opacity 0.3s ease;
        -ms-transition: opacity 0.3s ease;
        -o-transition: opacity 0.3s ease;
        transition: opacity 0.3s ease; }
      #morphing-content .home-form input.form-control.focus::-webkit-input-placeholder, #morphing-content .home-form input.form-control:focus::-webkit-input-placeholder, #morphing-content .home-form input.form-control.active::-webkit-input-placeholder, #morphing-content .home-form input.form-control:active::-webkit-input-placeholder, #morphing-content .home-form textarea.form-control.focus::-webkit-input-placeholder, #morphing-content .home-form textarea.form-control:focus::-webkit-input-placeholder, #morphing-content .home-form textarea.form-control.active::-webkit-input-placeholder, #morphing-content .home-form textarea.form-control:active::-webkit-input-placeholder, #morphing-content .home-form select.form-control.focus::-webkit-input-placeholder, #morphing-content .home-form select.form-control:focus::-webkit-input-placeholder, #morphing-content .home-form select.form-control.active::-webkit-input-placeholder, #morphing-content .home-form select.form-control:active::-webkit-input-placeholder {
        color: #ffffff;
        opacity: 0.6; }
      #morphing-content .home-form input.form-control.focus:-moz-placeholder, #morphing-content .home-form input.form-control:focus:-moz-placeholder, #morphing-content .home-form input.form-control.active:-moz-placeholder, #morphing-content .home-form input.form-control:active:-moz-placeholder, #morphing-content .home-form textarea.form-control.focus:-moz-placeholder, #morphing-content .home-form textarea.form-control:focus:-moz-placeholder, #morphing-content .home-form textarea.form-control.active:-moz-placeholder, #morphing-content .home-form textarea.form-control:active:-moz-placeholder, #morphing-content .home-form select.form-control.focus:-moz-placeholder, #morphing-content .home-form select.form-control:focus:-moz-placeholder, #morphing-content .home-form select.form-control.active:-moz-placeholder, #morphing-content .home-form select.form-control:active:-moz-placeholder {
        color: #ffffff;
        opacity: 0.6; }
      #morphing-content .home-form input.form-control.focus::-moz-placeholder, #morphing-content .home-form input.form-control:focus::-moz-placeholder, #morphing-content .home-form input.form-control.active::-moz-placeholder, #morphing-content .home-form input.form-control:active::-moz-placeholder, #morphing-content .home-form textarea.form-control.focus::-moz-placeholder, #morphing-content .home-form textarea.form-control:focus::-moz-placeholder, #morphing-content .home-form textarea.form-control.active::-moz-placeholder, #morphing-content .home-form textarea.form-control:active::-moz-placeholder, #morphing-content .home-form select.form-control.focus::-moz-placeholder, #morphing-content .home-form select.form-control:focus::-moz-placeholder, #morphing-content .home-form select.form-control.active::-moz-placeholder, #morphing-content .home-form select.form-control:active::-moz-placeholder {
        color: #ffffff;
        opacity: 0.6; }
      #morphing-content .home-form input.form-control.focus:-ms-input-placeholder, #morphing-content .home-form input.form-control:focus:-ms-input-placeholder, #morphing-content .home-form input.form-control.active:-ms-input-placeholder, #morphing-content .home-form input.form-control:active:-ms-input-placeholder, #morphing-content .home-form textarea.form-control.focus:-ms-input-placeholder, #morphing-content .home-form textarea.form-control:focus:-ms-input-placeholder, #morphing-content .home-form textarea.form-control.active:-ms-input-placeholder, #morphing-content .home-form textarea.form-control:active:-ms-input-placeholder, #morphing-content .home-form select.form-control.focus:-ms-input-placeholder, #morphing-content .home-form select.form-control:focus:-ms-input-placeholder, #morphing-content .home-form select.form-control.active:-ms-input-placeholder, #morphing-content .home-form select.form-control:active:-ms-input-placeholder {
        color: #ffffff;
        opacity: 0.6; }
    #morphing-content .home-form .sbHolder {
      border: 1px solid rgba(255, 255, 255, 0.6); }
      #morphing-content .home-form .sbHolder > a {
        color: #ffffff;
        opacity: 1; }
        #morphing-content .home-form .sbHolder > a:after {
          right: 20px; }
    #morphing-content .home-form .btn {
      margin-top: 30px;
      font-size: 16px;
      font-family: 'Montserrat', sans-serif;
      height: 58px;
      line-height: 58px; }

/*==============================================
--> 5.8 TEAM
================================================*/
.home-team {
  padding-top: 80px;
  padding-bottom: 80px; }
  @media (min-width: 992px) {
    .home-team {
      padding-top: 100px;
      padding-bottom: 100px; } }
  .home-team .card-style2 {
    margin-top: 50px; }
    .home-team .card-style2 .card-block {
      padding: 15px 30px; }
    .home-team .card-style2 span {
      padding-left: 0px;
      font-style: italic; }

/*==============================================
--> 5.9 BRANDS
================================================*/
.brand_carousel .slick-slider {
  padding: 0px; }

/*==============================================
--> 5.10 BLOG
================================================*/
.home-blog {
  padding-top: 80px;
  padding-bottom: 80px; }
  @media (min-width: 992px) {
    .home-blog {
      padding-top: 100px;
      padding-bottom: 100px; } }
  .home-blog .card {
    margin-top: 50px; }
  @media (min-width: 768px) {
    .home-blog .card.card-style3 .card-block {
      padding: 15px; }
      .home-blog .card.card-style3 .card-block .card-title {
        font-size: 18px; }
      .home-blog .card.card-style3 .card-block:before {
        bottom: 8px; } }
  @media (min-width: 992px) {
    .home-blog .card.card-style3 .card-block {
      padding: 30px; }
      .home-blog .card.card-style3 .card-block .card-title {
        font-size: 22px; }
      .home-blog .card.card-style3 .card-block:before {
        bottom: 15px; } }

/*==============================================
--> 5.10 NEWSLETTER
================================================*/
.newsletter {
  padding: 50px 0; }
  .newsletter h4 {
    font-size: 24px;
    line-height: 1.2; }
    @media (min-width: 768px) {
      .newsletter h4 {
        margin-bottom: 30px; } }
    @media (min-width: 992px) {
      .newsletter h4 {
        margin-bottom: 0px; } }
  .newsletter form {
    margin-top: 30px; }
    @media (min-width: 768px) {
      .newsletter form {
        margin-top: 0px; } }
    @media (min-width: 992px) {
      .newsletter form {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; } }
    .newsletter form .form-group {
      margin-bottom: 30px; }
      @media (min-width: 992px) {
        .newsletter form .form-group {
          width: 38%;
          margin-bottom: 0px; } }
    .newsletter form input {
      background-color: #ffffff;
      font-size: 14px;
      color: #222222;
      margin-top: 0px;
      margin-bottom: 0px;
      box-shadow: 2px 3px 9px rgba(44, 40, 86, 0.1); }
      .newsletter form input::-webkit-input-placeholder {
        color: #848484; }
      .newsletter form input:-moz-placeholder {
        color: #848484; }
      .newsletter form input::-moz-placeholder {
        color: #848484; }
      .newsletter form input:-ms-input-placeholder {
        color: #848484; }
      .newsletter form input:hover, .newsletter form input:active, .newsletter form input:focus {
        background-color: #ffffff; }

footer {
  padding-top: 80px; }
  @media (min-width: 992px) {
    footer {
      padding-top: 100px; } }

/*======== Footer Classic version ========*/
.footer .footer-text a .footer-logo {
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .footer .footer-text a .footer-logo {
      margin-bottom: 30px; } }

.footer .footer-text p {
  color: #ffffff;
  font-size: 14px; }
  @media (min-width: 768px) {
    .footer .footer-text p {
      font-size: 13px;
      line-height: 24px; } }
  @media (min-width: 992px) {
    .footer .footer-text p {
      font-size: 14px;
      line-height: 26px; } }

.footer h3 {
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 15px; }
  @media (min-width: 768px) {
    .footer h3 {
      margin-top: 0px; } }

.footer .social-icons {
  margin-top: 20px;
  margin-left: -10px; }
  .footer .social-icons .btn-social-o {
    background-color: transparent !important;
    color: #ffffff;
    font-size: 14px; }
    @media (min-width: 768px) {
      .footer .social-icons .btn-social-o {
        width: 30px;
        height: 30px; } }
    @media (min-width: 992px) {
      .footer .social-icons .btn-social-o {
        width: 30px;
        height: 30px; } }
    .footer .social-icons .btn-social-o:hover {
      color: #265a88; }

.footer .instagram ul {
  padding: 0 10px;
  margin-top: 20px; }

.footer .instagram li {
  width: auto;
  overflow: hidden;
  padding: 10px 5px 0; }
  .footer .instagram li img {
    width: 100%;
    height: 100%; }

.links {
  padding-left: 0; }
  @media (min-width: 992px) {
    .links {
      padding-left: 10px; } }
  .links ul {
    margin-top: 25px; }
  .links li a {
    display: block;
    position: relative;
    color: #ffffff;
    font-size: 14px;
    margin-top: 20px;
    padding-left: 15px; }
    @media (min-width: 768px) {
      .links li a {
        font-size: 13px;
        margin-top: 15px; } }
    @media (min-width: 992px) {
      .links li a {
        font-size: 14px;
        margin-top: 20px; } }
    .links li a:before {
      position: absolute;
      content: "\f101";
      font-family: 'FontAwesome';
      left: 0;
      -webkit-transition: color 0.3s ease;
      -moz-transition: color 0.3s ease;
      -ms-transition: color 0.3s ease;
      -o-transition: color 0.3s ease;
      transition: color 0.3s ease; }
    .links li a:hover {
      color: #265a88;
      opacity: 1 !important; }
      .links li a:hover:before {
        color: #265a88; }

.location ul {
  margin-top: 25px; }
  .location ul li {
    color: #ffffff;
    margin-top: 15px;
    font-size: 14px;
    padding-left: 25px; }
    @media (min-width: 768px) {
      .location ul li {
        font-size: 13px; } }
    @media (min-width: 992px) {
      .location ul li {
        font-size: 14px; } }
    .location ul li:first-child {
      padding-left: 0px; }
    .location ul li a {
      color: #ffffff; }
      .location ul li a:hover {
        color: #265a88;
        opacity: 1 !important; }
    .location ul li i {
      display: inline-block;
      position: absolute;
      left: 15px;
      margin-top: 4px; }

.copyright {
  margin-top: 50px; }
  .copyright hr {
    background-color: #ffffff;
    opacity: 0.2;
    margin-top: 0px; }
  .copyright p {
    padding-bottom: 20px;
    color: #ffffff; }
    .copyright p a:hover {
      color: #265a88 !important; }

.newsletter {
  padding-left: 0; }
  @media (min-width: 992px) {
    .newsletter {
      padding-left: 15px; } }
  .newsletter p {
    margin-top: 22px;
    color: #ffffff; }
  .newsletter input {
    background-color: transparent;
    font-size: 14px;
    color: #222222;
    margin-top: 30px;
    margin-bottom: 15px; }
    .newsletter input:hover, .newsletter input:active, .newsletter input:focus {
      background-color: transparent; }

input.form-control::-webkit-input-placeholder, textarea.form-control::-webkit-input-placeholder, select.form-control::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1; }

input.form-control:-moz-placeholder, textarea.form-control:-moz-placeholder, select.form-control:-moz-placeholder {
  color: #ffffff;
  opacity: 1; }

input.form-control::-moz-placeholder, textarea.form-control::-moz-placeholder, select.form-control::-moz-placeholder {
  color: #ffffff;
  opacity: 1; }

input.form-control:-ms-input-placeholder, textarea.form-control:-ms-input-placeholder, select.form-control:-ms-input-placeholder {
  color: #ffffff;
  opacity: 1; }

/*======== Footer Corporate version ========*/
.footer-corporate {
  padding-top: 0;
  padding-bottom: 0; }
  .footer-corporate .footer-text p {
    opacity: 0.7; }
  .footer-corporate .newsletter {
    padding-top: 80px;
    padding-bottom: 80px; }
    @media (min-width: 768px) {
      .footer-corporate .newsletter {
        padding-top: 100px;
        padding-bottom: 100px; } }
  .footer-corporate .copyright {
    margin-top: 0; }
    .footer-corporate .copyright p {
      float: left;
      padding: 10px 0 30px; }
    .footer-corporate .copyright hr {
      background-color: #004387;
      opacity: 1;
      margin-top: 0; }
  .footer-corporate .social-icons {
    padding-top: 10px;
    margin-top: 0;
    margin-right: -10px;
    display: inline-block;
    float: right;
    margin-left: 0; }

.footer-consultant .copyright p {
  float: left;
  padding: 10px 0 30px; }

.footer-consultant .copyright hr {
  background-color: #004387;
  opacity: 1;
  margin-top: 0; }

.footer-consultant .social-icons {
  padding-top: 10px;
  margin-top: 0;
  margin-right: -10px;
  display: inline-block;
  float: right;
  margin-left: 0; }

.footer-consultant .location ul {
  margin-top: 0; }

/*====================================
ABOUT US PAGE STYLE
======================================*/
.page-about-us .bredcrumb, .page-contactus .bredcrumb {
  padding: 0px; }

.boxed-menu .bredcrumb h1, .boxed-menu .bredcrumb h2 {
  padding-top: 0px; }
  @media (min-width: 768px) {
    .boxed-menu .bredcrumb h1, .boxed-menu .bredcrumb h2 {
      padding-top: 45px; } }

.about-details {
  padding-top: 80px;
  padding-bottom: 80px; }
  @media (min-width: 992px) {
    .about-details {
      padding-top: 100px;
      padding-bottom: 100px; } }
  .about-details h1 {
    margin-bottom: 10px; }
  .about-details p span {
    display: inline-block;
    padding-top: 30px;
    line-height: 2;
    padding-bottom: 25px;
    color: #222222;
    font-size: 16px; }
  .about-details .data-chart {
    margin-top: 50px; }

.contactus {
  padding-top: 80px; }
  @media (min-width: 992px) {
    .contactus {
      padding-top: 100px; } }

.page-contactus .sectionTitle {
  margin-bottom: 20px; }

.page-contactus .contact_sidebar .media-box {
  padding: 40px 30px;
  margin-bottom: 20px; }

.page-contactus .contact_sidebar .media-icon {
  width: 30px;
  font-size: 30px;
  text-align: center;
  color: #265a88; }

.page-contactus .contact_sidebar .media-content {
  padding-left: 45px; }
  .page-contactus .contact_sidebar .media-content h4 {
    margin-bottom: 10px; }

.contactus {
  margin-bottom: 80px; }
  .contactus form {
    margin-bottom: 50px; }
    .contactus form input.form-control::-webkit-input-placeholder {
      color: #b3b3b3; }
    .contactus form input.form-control:-moz-placeholder {
      color: #b3b3b3; }
    .contactus form input.form-control::-moz-placeholder {
      color: #b3b3b3; }
    .contactus form input.form-control:-ms-input-placeholder {
      color: #b3b3b3; }

#googleMap {
  height: 460px;
  width: 100%; }

.page-error .bredcrumb {
  padding: 0px; }

.page-error .error {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px; }
  @media (min-width: 992px) {
    .page-error .error {
      padding-top: 150px;
      padding-bottom: 150px; } }
  .page-error .error form {
    position: relative; }
    .page-error .error form .btn-search {
      top: 9px; }
      @media (min-width: 768px) {
        .page-error .error form .btn-search {
          top: 16px; } }
  .page-error .error h1 {
    color: #265a88;
    font-size: 100px;
    font-weight: 600; }
  .page-error .error p {
    color: #222222;
    font-size: 30px;
    padding: 50px 0; }
  .page-error .error input {
    margin-bottom: 50px; }

.page-comingsoon {
  padding: 100px 0; }
  .page-comingsoon .form_subscribe {
    position: relative; }
    .page-comingsoon .form_subscribe i {
      color: #265a88; }
    .page-comingsoon .form_subscribe input {
      -webkit-box-shadow: 1.721px 2.457px 9px rgba(44, 40, 86, 0.1);
      -moz-box-shadow: 1.721px 2.457px 9px rgba(44, 40, 86, 0.1);
      box-shadow: 1.721px 2.457px 9px rgba(44, 40, 86, 0.1);
      border: none; }
      .page-comingsoon .form_subscribe input::-webkit-input-placeholder {
        color: #848484; }
      .page-comingsoon .form_subscribe input:-moz-placeholder {
        color: #848484; }
      .page-comingsoon .form_subscribe input::-moz-placeholder {
        color: #848484; }
      .page-comingsoon .form_subscribe input:-ms-input-placeholder {
        color: #848484; }
  .page-comingsoon .comingsoon h1 {
    margin-top: 100px;
    margin-bottom: 15px; }
  .page-comingsoon .count-down {
    padding-top: 60px;
    padding-bottom: 60px; }
    .page-comingsoon .count-down .table-cell {
      position: relative;
      height: 150px; }
    .page-comingsoon .count-down .tab-val {
      width: 150px;
      font-size: 50px;
      height: 150px;
      line-height: 100px;
      background-color: #265a88 !important;
      border-color: #265a88;
      color: #ffffff; }
    .page-comingsoon .count-down .tab-metr {
      margin-top: -65px;
      font-size: 16px;
      color: #ffffff !important; }
  .page-comingsoon .form_subscribe {
    margin: 0 80px 90px; }

/*====================================
SERVICES PAGE STYLE
======================================*/
.page-services .bredcrumb, .page-service-details .bredcrumb {
  padding: 0; }

.services .service-testimonial .promo-video {
  height: 100%;
  background-position: center;
  background-size: cover; }

.services .service-testimonial .bg-image {
  height: auto; }

@media (min-width: 768px) {
  .services .service-testimonial .bg-image .card {
    padding: 25px 5px; } }

@media (min-width: 992px) {
  .services .service-testimonial .bg-image .card {
    padding: 15px 40px; } }

@media (min-width: 1200px) {
  .services .service-testimonial .bg-image .card {
    padding: 100px 100px; } }

.services2 {
  padding-top: 80px;
  padding-bottom: 40px; }

@media (min-width: 992px) {
  .services2 {
    padding-top: 100px;
    padding-bottom: 60px; } }

.services2.more-feature {
  padding-bottom: 80px; }

@media (min-width: 992px) {
  .services2.more-feature {
    padding-bottom: 100px; } }

.services2 .media-box {
  margin-bottom: 40px; }

.services2 .media-box .media-icon {
  color: #4ac8ed; }

.services2 .media-box h4 {
  margin-bottom: 10px; }

.services2 .more-feature-left .more-features {
  position: relative; }

.services2 .more-feature-left .more-features .feature-details {
  padding-left: 0px; }

.services2 .more-feature-left .more-features .feature-details h3 {
  margin-bottom: 15px; }

.services2 .more-feature-left .more-features .overlay-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  min-height: 100%;
  padding: 30px 30px;
  color: #848484;
  background: #ffffff;
  border: 2px solid #265a88;
  z-index: 1;
  opacity: 0; }

.services2 .more-feature-left .more-features .overlay-box h4 {
  margin-bottom: 10px; }

.services2 .more-feature-left .more-features:hover .overlay-box {
  opacity: 1;
  -webkit-animation: flipInY;
  animation: flipInY;
  -webkit-animation-duration: 1200ms;
  animation-duration: 1200ms;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1; }

.pricing-area {
  padding-top: 80px;
  padding-bottom: 80px; }

@media (min-width: 992px) {
  .pricing-area {
    padding-top: 100px;
    padding-bottom: 100px; } }

.pricing-area .pricing {
  margin-top: 50px; }

.pricing-area .pricing:hover .btn {
  background-color: #265a88;
  border-color: #265a88;
  color: #ffffff; }

.service_single-area {
  padding-top: 80px;
  padding-bottom: 80px; }

@media (min-width: 992px) {
  .service_single-area {
    padding-top: 100px;
    padding-bottom: 100px; } }

.service_single-area .service_details-desc h2 {
  margin-top: 40px; }

.service_single-area .service_details-desc p {
  margin-top: 30px; }

.service_single-area .service_details-desc ul.styled_list {
  margin-top: 30px; }

.service_single-area .service_details-desc ul.styled_list i {
  color: #265a88; }

.service_single-area .service_details-desc h4 {
  margin-top: 40px; }

.sidebar .list-group {
  margin-bottom: 40px; }

@media (min-width: 768px) {
  .sidebar .list-group {
    margin-bottom: 0; } }

.sidebar .list-group .list-group-item:first-child {
  margin-top: 0px; }

.sidebar .list-group .list-group-item a {
  font-size: 14px;
  font-weight: 600;
  position: relative;
  color: #222222;
  padding-left: 20px; }

.sidebar .list-group .list-group-item a:before {
  position: absolute;
  content: '\f0da';
  font-family: 'FontAwesome';
  left: 0; }

.sidebar .list-group .list-group-item.active a {
  color: #ffffff; }

/*====================================
PROJECTS PAGE STYLE
======================================*/
.page-projects .bredcrumb, .page-project-details .bredcrumb {
  padding: 0px; }

.project-details {
  padding-top: 80px;
  padding-bottom: 80px; }
  @media (min-width: 992px) {
    .project-details {
      padding-top: 100px;
      padding-bottom: 100px; } }
  .project-details .bg-image {
    height: 100%; }
    .project-details .bg-image:before {
      background-color: rgba(10, 35, 59, 0.85); }
  .project-details h2 {
    margin-top: 40px; }
  .project-details p {
    margin-top: 30px; }
  .project-details .col-md-4 > img {
    margin-top: 30px; }

.project_specification {
  padding: 40px 30px;
  color: #ffffff;
  position: relative; }
  .project_specification h2 {
    margin-bottom: 30px;
    margin-top: 0px; }
  .project_specification .row {
    margin-top: 15px; }

.page-team .bredcrumb, .page-member-details .bredcrumb {
  padding: 0; }

.member-details {
  padding-top: 80px;
  padding-bottom: 80px; }
  @media (min-width: 992px) {
    .member-details {
      padding-top: 100px;
      padding-bottom: 100px; } }

.member_designation {
  margin-bottom: 30px; }
  .member_designation h2 {
    margin-bottom: 5px;
    margin-top: 25px; }
    @media (min-width: 768px) {
      .member_designation h2 {
        margin-top: 0; } }
  .member_designation span {
    font-style: italic; }

.member_desc li {
  display: block;
  float: unset;
  width: 100%; }
  .member_desc li i {
    color: #265a88;
    font-size: 14px; }

.member_desc h4 {
  margin-top: 40px; }

.member_desc p {
  margin-top: 25px; }

.media-box {
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .media-box {
      margin-bottom: 0; } }

.member_contact {
  padding: 40px;
  position: relative;
  margin-top: 40px; }
  .member_contact .media-icon {
    font-size: 32px;
    color: #dae0e6;
    position: relative;
    width: 30px;
    text-align: center;
    float: left;
    margin-right: 15px; }
  .member_contact .media-content {
    padding-left: 0px;
    float: left; }
    .member_contact .media-content h5 {
      font-size: 15px; }
    .member_contact .media-content h5, .member_contact .media-content a {
      color: #dae0e6; }
  @media (min-width: 992px) {
    .member_contact .social-icons {
      text-align: right; } }
  .member_contact .social-icons .btn-social {
    width: 40px;
    height: 40px;
    line-height: 40px; }
  .member_contact .social-icons .btn {
    background-color: transparent;
    border: 1px solid;
    border-color: #999999;
    color: #dae0e6; }
    .member_contact .social-icons .btn:hover {
      background-color: #265a88;
      border-color: #265a88;
      opacity: 1; }

.blog-grid-2 .bredcrumb, .blog-grid-3 .bredcrumb, .blog-classic .bredcrumb, .blog-single-post .bredcrumb {
  padding: 0px; }

.blog-grid {
  padding-top: 80px;
  padding-bottom: 80px; }
  @media (min-width: 992px) {
    .blog-grid {
      padding-top: 100px;
      padding-bottom: 100px; } }
  .blog-grid .card {
    margin-bottom: 30px; }

.blog-sidebar {
  padding-left: 15px; }
  .blog-sidebar h4 {
    margin-top: 40px; }
  .blog-sidebar .list-group {
    margin-top: 30px; }
  .blog-sidebar .media-box {
    margin-top: 30px;
    border-bottom: 1px solid;
    border-color: #e5e5e5;
    padding-bottom: 20px; }
    .blog-sidebar .media-box .media-icon {
      overflow: hidden;
      height: 65px;
      width: 65px;
      border-radius: 50%; }
    .blog-sidebar .media-box .media-content {
      padding-left: 80px; }
      .blog-sidebar .media-box .media-content h6 {
        font-size: 15px;
        margin-bottom: 3px; }
  .blog-sidebar .tags {
    margin-top: 20px; }
    .blog-sidebar .tags .btn {
      margin-top: 10px;
      margin-right: 10px;
      color: #848484;
      text-transform: capitalize; }
      .blog-sidebar .tags .btn:hover {
        color: #ffffff;
        background-color: #265a88;
        border-color: #265a88; }

.blog-grid-3 .sidebar {
  display: none; }

.blog .btn-search {
  right: 40px; }

.pagination {
  margin-top: 40px; }

.blog-classic .video-button i {
  position: relative;
  height: 80px;
  width: 80px;
  line-height: 80px;
  font-size: 35px;
  border-radius: 50%;
  display: inline-block;
  color: #ffffff;
  padding-left: 10px;
  text-align: center;
  border: 2px solid #ffffff;
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -ms-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease; }

.blog-classic .video-button:hover i {
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1); }

.blog-classic .card.card-style2 .card-block {
  padding-top: 25px; }
  .blog-classic .card.card-style2 .card-block:before {
    display: none; }
  .blog-classic .card.card-style2 .card-block h4 {
    position: relative; }
    .blog-classic .card.card-style2 .card-block h4:before {
      position: absolute;
      content: '';
      width: 50px;
      bottom: -10px;
      height: 2px;
      background-color: #265a88; }
  .blog-classic .card.card-style2 .card-block p {
    margin-top: 30px;
    margin-bottom: 30px; }
  .blog-classic .card.card-style2 .card-block .btn {
    background-color: transparent; }
    .blog-classic .card.card-style2 .card-block .btn:hover {
      background: #265a88;
      color: #ffffff;
      border-color: #265a88; }

.blog-fullwidth .pagination {
  margin-bottom: 20px; }

.blog-fullwidth .blog-sidebar .media-box {
  margin-top: 20px; }
  .blog-fullwidth .blog-sidebar .media-box .media-content h6 {
    margin-top: 10px;
    margin-bottom: 6px; }

.blog-single-post .card.card-style3 {
  margin-bottom: 80px; }
  @media (min-width: 768px) {
    .blog-single-post .card.card-style3 {
      margin-bottom: 100px; } }
  .blog-single-post .card.card-style3 .card-block {
    width: 93%;
    padding-bottom: 30px;
    border: 1px solid #e5e5e5; }
    .blog-single-post .card.card-style3 .card-block:before {
      display: none; }
    .blog-single-post .card.card-style3 .card-block h2 {
      position: relative;
      color: #222222; }
      .blog-single-post .card.card-style3 .card-block h2:before {
        position: absolute;
        content: '';
        width: 50px;
        bottom: -12px;
        height: 2px;
        background-color: #e5e5e5; }
    .blog-single-post .card.card-style3 .card-block p {
      margin-top: 30px; }
    .blog-single-post .card.card-style3 .card-block blockquote {
      padding: 30px 40px;
      border-color: transparent;
      border-left-color: #265a88;
      margin-top: 30px;
      margin-bottom: 30px; }
      .blog-single-post .card.card-style3 .card-block blockquote p {
        margin-top: 0; }

.blog-single-post form input.form-control::-webkit-input-placeholder, .blog-single-post form textarea.form-control::-webkit-input-placeholder {
  color: #b3b3b3; }

.blog-single-post form input.form-control:-moz-placeholder, .blog-single-post form textarea.form-control:-moz-placeholder {
  color: #b3b3b3; }

.blog-single-post form input.form-control::-moz-placeholder, .blog-single-post form textarea.form-control::-moz-placeholder {
  color: #b3b3b3; }

.blog-single-post form input.form-control:-ms-input-placeholder, .blog-single-post form textarea.form-control:-ms-input-placeholder {
  color: #b3b3b3; }

.social-share {
  color: #222222;
  margin-top: 30px; }
  .social-share ul.social-icons {
    display: inline-block;
    margin-left: 10px; }
    .social-share ul.social-icons li {
      display: inline-block;
      margin: 0 5px; }
      .social-share ul.social-icons li a.btn-social {
        font-size: 13px;
        line-height: 35px;
        color: #848484;
        border: 1px solid #e5e5e5; }
        .social-share ul.social-icons li a.btn-social:hover {
          background: #265a88;
          border-color: #265a88;
          color: #ffffff;
          box-shadow: none; }

/*============= Comment area style =============*/
.comment {
  padding-top: 25px; }

.reply {
  padding-top: 15px; }

.comment_img, .reply_img {
  overflow: hidden;
  display: inline-block;
  float: left; }
  @media (min-width: 768px) {
    .comment_img, .reply_img {
      width: 80px;
      height: 80px; } }

.reply {
  padding-left: 80px; }
  @media (min-width: 768px) {
    .reply {
      padding-left: 100px; } }

.comment_title {
  padding-left: 80px; }
  @media (min-width: 768px) {
    .comment_title {
      padding-left: 100px; } }

.reply_title {
  padding-left: 60px; }
  @media (min-width: 768px) {
    .reply_title {
      padding-left: 80px; } }

.comment_title i, .reply_title i {
  margin-right: 5px; }

.comment_title h5, .reply_title h5 {
  padding-bottom: 10px; }

.comment_title p, .reply_title p {
  padding: 15px 0 10px;
  color: #848484; }

.comment_title .btn, .reply_title .btn {
  color: #265a88;
  font-size: 14px;
  padding: 0px;
  font-weight: 700;
  height: auto; }
  .comment_title .btn:hover, .reply_title .btn:hover {
    box-shadow: none;
    background-color: transparent;
    border: 0px solid #265a88; }

.blog-single-post form.comment_box {
  margin-top: 30px; }
  .blog-single-post form.comment_box h3 {
    margin-bottom: 30px; }

body.default {
  background-image: url("/img/patterns/pattern1.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center; }

body.pattern-01 {
  background-image: url("/img/patterns/pattern2.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center; }

body.pattern-02 {
  background-image: url("/img/patterns/pattern3.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center; }

body.pattern-03 {
  background-image: url("/img/patterns/pattern4.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center; }

body.pattern-04 {
  background-image: url("/img/patterns/pattern5.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center; }

body.pattern-06 {
  background-image: url("/img/patterns/006.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center; }

body.pattern-07 {
  background-image: url("/img/patterns/007.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center; }

body.pattern-05 {
  background-image: url("/img/patterns/005.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center; }

.insta-gallery {
  margin: 10px auto; }

.insta-gallery > .insta-gallery__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around; }
  .insta-gallery > .insta-gallery__list li {
    list-style: none;
    border: 1px #efefef solid;
    margin: 10px; }

.text-shadow {
  text-shadow: 3px 3px 2px black; }

.title--underline {
  border-bottom: 1px solid #e9e9e9; }

.title--center {
  margin-bottom: 30px;
  text-align: left; }

.title--decoration-bottom-center {
  padding-bottom: 20px; }

.title--decoration-bottom-center:after {
  content: ' ';
  display: block;
  background: #265a88;
  position: absolute;
  height: 5px;
  width: 30px;
  bottom: -1px;
  left: 50%;
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

.title {
  margin-bottom: 30px;
  position: relative; }

.section-title {
  margin-bottom: 0 !important;
  padding-top: 20px !important; }

#invisible {
  display: none; }

.dateTd {
  width: auto; }
  @media (min-width: 992px) {
    .dateTd {
      width: 10%; } }
  .dateTd .date {
    display: none;
    color: #fff;
    background-color: #859E8C;
    width: 55px;
    height: 58px;
    border-radius: 3px;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    margin: auto; }
    @media (min-width: 768px) {
      .dateTd .date {
        display: block; } }
    .dateTd .date span {
      display: block;
      font-size: 14px; }

.checkinPortal ul.nav-tabs.nav-pills .nav-item .nav-link.active {
  border-color: #265a88;
  background-color: #265a88;
  color: #ffffff; }

.checkinPortal ul.nav-tabs.nav-pills .nav-item .nav-link:hover {
  color: #265a88; }

.checkinPortal ul.nav-tabs .nav-item .nav-link.active {
  border-top-color: #265a88;
  border-top-width: 2px;
  background-color: #ffffff; }

.checkinPortal ul.nav-tabs.nav-pills .nav-item .nav-link.active:hover {
  color: #ffffff; }

/* map styling */
.createaclickablemap-container {
  position: relative;
  width: 100%;
  height: 0px;
  padding-bottom: 60%; }

.createaclickablemap {
  border: none;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%; }

ul.map li {
  float: left;
  margin: 0 25px 0 0px;
  width: 120px;
  border: 0px solid;
  height: 45px;
  padding-left: 6px; }

ul.map {
  list-style: square url(https://createaclickablemap.com/li-arrow.png) !important; }

ul.map li a {
  margin: 0;
  padding: 0;
  text-decoration: underline;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: #4591b1; }

ul.map li a:hover {
  text-decoration: none;
  color: #214b6e; }

.linkBack {
  display: block;
  width: 130px;
  position: relative;
  bottom: 0px;
  left: 660px;
  font-family: Arial,Helvetica,sans-serif;
  font-size: 10px;
  font-style: italic;
  color: #666666; }

.linkBack a {
  color: #666666; }

.linkBack a:hover {
  color: #666666;
  text-decoration: none; }

/*================================================
--> Element Heading
==================================================*/
[class*="heading-"] {
  position: relative; }

.heading:before, .heading:after {
  content: "";
  position: absolute;
  left: auto;
  right: 0;
  width: 100%;
  height: 0;
  border-top-width: 1px;
  border-color: inherit; }

.heading.heading-v1:before {
  top: 48%;
  border-top-style: solid; }

.heading.heading-v1:after {
  top: 58%;
  border-top-style: solid; }

.heading-v1-title {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  padding-right: 18px;
  background-color: inherit;
  z-index: 2; }

.text-center .heading-v1-title {
  padding-right: 18px;
  padding-left: 18px; }

.text-right .heading-v1-title {
  padding-right: 0;
  padding-left: 18px; }

/*================================================
--> Clickable Map
==================================================*/
.createaclickablemap-container {
  position: relative;
  width: 100%;
  height: 0px;
  padding-bottom: 60%; }

.createaclickablemap {
  border: none;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%; }

/*================================================
--> Element Divider
==================================================*/
.divider-dotted {
  border-top-style: dotted; }

.divider-dashed {
  border-top-style: dashed; }

.divider-solid-dbl {
  height: 4px;
  border-top: 1px solid;
  border-bottom: 1px solid; }

.divider-dotted-dbl {
  height: 4px;
  border-top: 1px dotted;
  border-bottom: 1px dotted; }

.divider-dashed-dbl {
  height: 4px;
  border-top: 1px dashed;
  border-bottom: 1px dashed; }

.divider-gradient-gray {
  background-image: linear-gradient(to right, transparent, #ccc, transparent);
  height: 1px;
  border: none; }

.w25 {
  width: 25% !important; }

.w50 {
  width: 50% !important; }

.w75 {
  width: 75% !important; }

.divider {
  position: relative;
  border-top-width: 1px; }

.border-solid {
  border-top-style: solid; }

.divider-center {
  text-align: center; }

.divider-center .divider-icon {
  left: auto;
  right: auto;
  margin-left: -20px; }

.divider-right {
  text-align: right; }

.divider-right .divider-icon {
  left: auto;
  margin-left: -40px; }

.divider-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  top: -20px;
  display: inline-block;
  line-height: 40px;
  font-size: 20px;
  color: #f5f5f5;
  text-align: center; }

.divider-gradient {
  height: 1px;
  border: none; }
